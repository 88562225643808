export class VoucherDetails {
  code: string;
  percentage: number;
  fixed_amount: number;

  public totalValue: number = 0;
  public updateValue(cartTotal: number) {
    this.totalValue = Math.round((this.fixed_amount + this.percentage * cartTotal) * 100) / 100;
  }

  public constructor(code, percentage, amount) {
    this.code = code;
    this.percentage = percentage;
    this.fixed_amount = amount;
  }
}
