import { Component, OnInit } from '@angular/core';
import { CartService } from 'src/app/services/cart.service';
import { CartProduct } from "../../../cart-product";
import { MatTableDataSource } from "@angular/material/table";

@Component({
    selector: 'app-pos-cart-list',
    templateUrl: './pos-cart-list.component.html',
    styleUrls: ['./pos-cart-list.component.scss']
})
export class PosCartListComponent implements OnInit {
    displayedColumns: string[] = this.getCartHeader();
    public productDataSource = new MatTableDataSource(this.cartService.cartProducts);

    constructor(public cartService: CartService) {
        this.cartService.productListChangedEvent.subscribe((prod) => {
            this.productDataSource.connect().next(this.cartService.cartProducts);
        });
    }

    ngOnInit(): void {
        this.cartService.cartProduct.subscribe(cartProduct => {
            if (cartProduct.quantity) {
                this.cartService.addProductToCart(cartProduct);
            }
        });
    }

  private getCartHeader(): string[] {
    if (!this.cartService.renderTemplatePDF) {
      return ['position', 'name', 'quantity', 'price', 'vatType', 'vatValue', 'total', 'delete'];
    }

    return ['position', 'name', 'quantity', 'price', 'vatType', 'vatValue', 'total'];
  }

    public updateQuantity(product: CartProduct, $event, action = null): void {
        let selectedQuantity = parseInt($event.target.value);
        if (action == 'down') {
            selectedQuantity = product.quantity - 1;
        }

        if (action == 'up') {
            selectedQuantity = product.quantity + 1;
        }
        product.quantity = selectedQuantity < 1 ? 1 : selectedQuantity;
        this.cartService.refreshTotals();
        this.cartService.productListChangedEvent.emit();

        if (selectedQuantity < 1 || isNaN(selectedQuantity)) {
            selectedQuantity = 1;
        }

        product.quantity = selectedQuantity < 1 ? 1 : selectedQuantity;
        $event.target.value = product.quantity;

        this.cartService.refreshTotals();
    }

    public deleteProductRow(product): void {
        this.cartService.removeProductFromCart(product);
    }
}
