import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root',
})
export class InterceptorService implements HttpInterceptor {
  constructor(private authService: AuthenticationService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (this.authService.token) {
      request = request.clone({
        headers: request.headers
          .append('Authorization', 'Bearer ' + this.authService.token)
          .append('Accept', 'application/json'),
      });
      return next.handle(request).pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status == 401) {
            this.authService.token = null;
            this.authService.agent = null;
            this.authService.loggedInStateChanged.emit(false);
            if (request.url.indexOf(this.authService.endpointGetLogged) == -1) {
              this.authService.redirectToHomePage();
            }
            return throwError('Not logged in');
          }
          return throwError(error);
        })
      );
    }
    return next.handle(request);
  }
}
