<h2>Shop Details</h2>
<mat-dialog-content class="fullWidthWrapper">
  <div class="alert alert-danger" *ngIf="visibleErrors.emptyName">Please enter the shop name</div>
  <div class="alert alert-danger" *ngIf="visibleErrors.emptyLocation">Please enter the shop location</div>
  <div class="row">
    <div class="col-xs-12 col-lg-6">
      <mat-form-field appearance="fill">
        <mat-label>Name</mat-label>
        <input #shopNameField [(ngModel)]="shop.name" matInput type="text">
      </mat-form-field>
    </div>
    <div class="col-xs-12 col-lg-6">
      <mat-form-field appearance="fill">
        <mat-label>Location</mat-label>
        <input [(ngModel)]="shop.location" matInput type="text">
      </mat-form-field>
    </div>
  </div>
  <div>
    <mat-form-field appearance="fill">
      <mat-label>Receipt Header</mat-label>
      <textarea [(ngModel)]="shop.receipt_header" rows="10" matInput></textarea>
    </mat-form-field>
  </div>
  <div>
    <mat-form-field appearance="fill">
      <mat-label>Receipt Footer</mat-label>
      <textarea [(ngModel)]="shop.receipt_footer" rows="10" matInput></textarea>
    </mat-form-field>
  </div>

  <div class="alert alert-info" style="font-family: monospace">
    The following placeholders are available for the receipt header and footer:<br>
    %date% = Current Date<br>
    %time% = Current Time<br>
    %shop_name% = Shop Name<br>
    %agent_name% = Agent Name<br>
    %agent_id% = Agent ID<br>
    %customer_name% = Customer Name<br>
    %customer_email% = Customer Email<br>
    %customer_id% = Customer ID<br>
  </div>
</mat-dialog-content>

<mat-dialog-actions style="margin-bottom: 10px;">
    <button mat-raised-button color="primary" (click)="saveShop()">Save</button>
    <button mat-raised-button [mat-dialog-close]="true">Cancel</button>
</mat-dialog-actions>
