<h2>Station Details</h2>
<mat-dialog-content class="fullWidthWrapper">
    <div class="alert alert-danger" *ngIf="visibleErrors.emptyName">Please enter the station name</div>
    <div class="alert alert-danger" *ngIf="visibleErrors.emptyCode">Please enter the station code</div>
    <div class="alert alert-danger" *ngIf="visibleErrors.emptyShop">Please select a shop</div>
    <div class="row">
        <div class="col-xs-12 col-lg-5">
            <mat-form-field appearance="fill">
                <mat-label>Name</mat-label>
                <input #stationNameField [(ngModel)]="station.name" matInput type="text">
            </mat-form-field>
        </div>
        <div class="col-xs-12 col-lg-7">
            <mat-form-field appearance="fill">
                <mat-label>Code</mat-label>
                <input [(ngModel)]="station.code" matInput type="text">
            </mat-form-field>
        </div>
    </div>
    <div>
        <mat-form-field appearance="fill">
            <mat-label>Shop</mat-label>
            <mat-select [(ngModel)]="selectedShop" (selectionChange)="updateShop(selectedShop)" [compareWith]="shopCompareFn">
                <mat-option *ngFor="let shop of shops" [value]="{ id_shop: shop.id_shop, shop_name: shop.name }">
                    {{ shop.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

</mat-dialog-content>

<mat-dialog-actions style="margin-bottom: 10px;">
    <button mat-raised-button color="primary" (click)="saveStation()">Save</button>
    <button mat-raised-button [mat-dialog-close]="true">Cancel</button>
</mat-dialog-actions>
