<h2>Configuration Details</h2>
<mat-dialog-content class="fullWidthWrapper mb-2">
  <div style="width: 340px">
    <div class="alert alert-danger" *ngIf="visibleErrors.emptyName">Please enter the configuration name</div>
    <div class="alert alert-danger" *ngIf="visibleErrors.emptyValue">Please enter the configuration value</div>
    <mat-form-field appearance="fill">
      <mat-label>Name</mat-label>
      <input #configurationNameField [disabled]="disabledName" [(ngModel)]="configuration.name" matInput type="text">
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Value</mat-label>
      <textarea [(ngModel)]="configuration.value" matInput></textarea>
    </mat-form-field>
    <mat-checkbox [(ngModel)]="configuration.frontend">Frontend</mat-checkbox>
  </div>
</mat-dialog-content>
<mat-dialog-actions style="margin-bottom: 10px;">
  <button mat-raised-button color="primary" (click)="saveConfiguration()">Save</button>
  <button mat-raised-button [mat-dialog-close]="true">Cancel</button>
</mat-dialog-actions>
