import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CartService } from 'src/app/services/cart.service';
import { CustomerService } from 'src/app/services/customer.service';
import { OrderService } from 'src/app/services/order.service';
import { PaymentService } from 'src/app/services/payment.service';

@Component({
  selector: 'app-pos-print-order-preview',
  templateUrl: './pos-print-order-preview.component.html',
  styleUrls: ['./pos-print-order-preview.component.scss']
})
export class PosPrintOrderPreviewComponent implements OnInit {
  constructor(
    public dialog: MatDialog,
    public cartService: CartService,
    private customerService: CustomerService,
    private orderService: OrderService,
    private paymentService: PaymentService
  ) {}

  ngOnInit(): void {
  }

  generateQuote() {
    this.cartService.generateQuote();
  }

  public get isHidden(): boolean {
    return this.customerService.isAgentLoggedIn && this.cartService.cartProducts.length < 1;
  }

  newOrder() {
    this.cartService.emptyCart();
    this.customerService.resetLinkedCustomer();
    this.orderService.initOrderData();
    this.paymentService.initPaymentData();
  }
}
