<div class="payment_methods_success_message" *ngIf="this.paymentService.isPaidWithSuccess()">
  <div class="alert alert-success" role="alert" style="text-align: center;">
    The payment was made successfully!
  </div>
</div>
<div class="payment_methods_success_message" *ngIf="!existsProductsInCart">
  <div class="alert alert-danger" role="alert" style="text-align: center;">
    Please add products to cart.
  </div>
</div>
<div class="payment_methods">
  <button mat-raised-button color="primary" (click)="startPaymentProcess(this.paymentService.PAY_WITH_CARD_PAYMENT)"><mat-icon>credit_card</mat-icon>Pay with Card</button>
  <button mat-raised-button color="primary" (click)="startPaymentProcess(this.paymentService.PAY_WITH_CASH_PAYMENT)"><mat-icon>money</mat-icon>Pay Cash</button>
  <button mat-raised-button color="accent" (click)="startPaymentProcess(this.paymentService.SPLIT_PAYMENT)"><mat-icon> call_split</mat-icon>Split Card/Cash</button>
</div>
