export class Shop {
    id_shop: number = 0;
    name: string = '';
    location: string = '';
    receipt_header: string = '';
    receipt_footer: string = '';

    public cloneFrom(oldShop: Shop) {
        this.id_shop = oldShop.id_shop;
        this.name = oldShop.name;
        this.location = oldShop.location;
        this.receipt_header = oldShop.receipt_header;
        this.receipt_footer = oldShop.receipt_footer;
    }
}