import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { MatDialog } from '@angular/material/dialog';
import {environment} from "../../../environments/environment"
import {LaravelPaginationLink} from "../../laravel-pagination-link";
import {ReceiptPrinter} from "../../receipt-printer";
import {ReceiptPrinterDialogComponent} from "./receipt-printer-dialog/receipt-printer-dialog.component";


@Component({
  selector: 'app-receipt-printers',
  templateUrl: './receipt-printers.component.html',
  styleUrls: ['./receipt-printers.component.scss']
})
export class ReceiptPrintersComponent implements OnInit {

  paginationLinks: LaravelPaginationLink[] = [];
  currentPage;
  totalResults;
  totalPages;
  currentLink = environment.APP_ENVIRONMENT + environment.RECEIPT_PRINTERS_ENDPOINT;
  rows: ReceiptPrinter[] = [];
  displayedColumns: string[] = ['id', 'url', 'logical_name', 'paper_size', 'description', 'shop', 'actions'];

  constructor(
    private authenticationService: AuthenticationService,
    public dialog: MatDialog,
    private http: HttpClient
  ) { }

  ngOnInit(): void {
    this.reloadTable();
  }

  createPrinterFromJson(json: any): ReceiptPrinter
  {
    let result = new  ReceiptPrinter();
    result.cloneFrom(json);
    return result;
  }

  reloadTable() {
    this.http.get<any>(this.currentLink).subscribe(response => {
        this.rows = response.data.map(this.createPrinterFromJson);
        this.currentPage = response.current_page;
        this.paginationLinks = response.links;
        this.totalPages = response.last_page;
        this.totalResults = response.total;
      },
      (err) => {
        console.dir(err);
      });
  }

  editPrinter(printer: ReceiptPrinter, callback: CallableFunction = () => {}) {
    const dialogRef = this.dialog.open(ReceiptPrinterDialogComponent, {
        hasBackdrop: true,
        data: printer
      }
    );
    dialogRef.afterClosed().subscribe(result => {
      callback(result)
    })
  }

  changePage(link) {
    this.currentLink = link;
    this.reloadTable();
  }

  createPrinter() {
    let printer = new  ReceiptPrinter();
    this.editPrinter(printer, (result) => {
      this.reloadTable();
    })
  }

  deletePrinter(printer: ReceiptPrinter) {
    this.http.post<any>(environment.APP_ENVIRONMENT+environment.RECEIPT_PRINTERS_ENDPOINT+"/delete/"+printer.id_receipt_printer, {}).subscribe(response => {
        if(!response.success)
          console.dir(response.error);
        else {
          this.reloadTable();
        }
      },
      (err) => {
        console.dir(err);
      });
  }
}
