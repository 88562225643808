import { HttpClient } from '@angular/common/http';
import {EventEmitter, Injectable} from '@angular/core';
import { Customer } from '../customer';
import { environment } from '../../environments/environment';
import { BehaviorSubject, EMPTY } from 'rxjs';
import { BillingAddress } from '../billing-address';
import { AuthenticationService } from './authentication.service';
import { switchMap, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class CustomerService {

  public customerChangedEvent: EventEmitter<Customer> = new EventEmitter<Customer>();
  public finishedLoading = false;
  linkedCustomer: Customer = this.createEmptyCustomer();

  constructor(private http: HttpClient, private authenticationService: AuthenticationService) {
    this.customerChangedEvent.subscribe((customer: Customer) => {
      if(!customer.ID) {
        this.initAgentCustomer();
      }
    })
    this.loadCustomerFromLocalStorage();
  }

  private initAgentCustomer() {
    let customerEmail = this.authenticationService.agent.username;

    //TBD: if we know each agent has a customer, we may want to link them together in the database (add id_customer to the agent) instead of searching for the email.
    this.searchCustomer(customerEmail).pipe(
        tap(searchedCustomer => {
          if(searchedCustomer.result.items.length > 0)
            this.linkedCustomer = searchedCustomer.result.items[0];
          else
            this.createAgentCustomer()
        })
    ).subscribe(result => {});
  }

  public createAgentCustomer() {
    let customer = new Customer();
    customer.Email = this.authenticationService.agent.username;
    customer.FirstName = this.authenticationService.agent.firstname;
    customer.LastName = this.authenticationService.agent.lastname;
    this.saveCustomer(customer).subscribe(result => {
      //TODO: need more info on how exactly I can identify an error here. It looks like Laravel either returns success false and an error on the first level, or passes the response from Prestashop, which may contain success true on the first level and an error on the second, or no error and just an array with a single customer ID deeply nested. We should probably standardize these return messages, but that is beyond the scope of my current task.

      const customer_id = result.id_customer[0] //hope for the best that it doesn't have an error
      localStorage.setItem('customer_id', customer_id);
      this.loadCustomerFromLocalStorage();
    });
  }

  private async loadCustomerFromLocalStorage() {
    let customer = this.createEmptyCustomer();
    const id = localStorage.getItem('customer_id');
    if(id) {
      let response = await this.http.get<any>(environment.APP_ENVIRONMENT + environment.CUSTOMER_ENDPOINT + 'get/' + id).toPromise();
      if (response.success) {
        customer = response.result.items[0];
      }
    }
    //if(!this.linkedCustomer)
    this.linkedCustomer = customer;
    this.finishedLoading = true;
    this.customerChangedEvent.emit(this.linkedCustomer);
  }

  private saveCustomerToLocalStorage() {
    if(!this.linkedCustomer.ID)
      return;
    localStorage.setItem('customer_id', this.linkedCustomer.ID);
  }


  public get isAgentLoggedIn(): boolean{
    //TODO: We may need to revisit this logic. The agent username may not match the default customer email
    return this.authenticationService.agent && this.linkedCustomer && this.authenticationService.agent.username == this.linkedCustomer.Email;
  }

  public resetLinkedCustomer() {
    this.linkedCustomer = this.createEmptyCustomer();
    localStorage.removeItem('customer_id');
    this.customerChangedEvent.emit(this.linkedCustomer);
  }


  public createEmptyCustomer(): Customer {
    const customer = new Customer();
    customer.BillingAddress = new BillingAddress();
    return customer;
  }

  public changeLinkedCustomer(customer: Customer) {
    this.linkedCustomer = customer;
    this.saveCustomerToLocalStorage();
    this.customerChangedEvent.emit(this.linkedCustomer);
  }


  //I am not sure returning an observable is the best choice here. At least we should probably handle some errors here, to avoid repeating the response parsing code in multiple places
  public saveCustomer(customer: Customer) {
    return this.http.post<any>(environment.APP_ENVIRONMENT + environment.CUSTOMER_ENDPOINT + environment.CUSTOMER_SAVE_ENDPOINT, customer);
  }

  public getCountries() {
    return this.http.get(environment.APP_ENVIRONMENT + environment.COUNTRIES_ENDPOINT);
  }

  public searchCustomer(searchKeyword) {
    const headers = {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': '*',
      'Access-Control-Allow-Headers': '*'
    };
    return this.http.get<any>(environment.APP_ENVIRONMENT + environment.CUSTOMER_ENDPOINT + environment.CUSTOMER_SEARCH_ENDPOINT + searchKeyword, {headers});
  }

}
