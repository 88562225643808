import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(
    private http: HttpClient
    ) { }

  searchProducts(language: string, keyword: string) {
    const headers = {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': '*',
        'Access-Control-Allow-Headers': '*'
    };
    return this.http.get<any>(this.getProductApiEndpoint(language, keyword), { headers });
  }

  getProductApiEndpoint(language: string, keyword: string) {
    return environment.APP_ENVIRONMENT  + environment.PRODUCT_ENDPOINT + language + '/' + keyword;
  }
}
