import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { PosComponent } from './pages/pos/pos.component';
import { PosCartListComponent } from './pages/pos-components/pos-cart-list/pos-cart-list.component';
import { PosNewCustomerComponent } from './pages/pos-components/pos-new-customer/pos-new-customer.component';
import { PosNewCustomerDialogComponent } from './pages/pos-components/pos-new-customer-dialog/pos-new-customer-dialog.component';
import { PosPaymentsComponent } from './pages/pos-components/pos-payments/pos-payments.component';
import { PosPriceCalculationComponent } from './pages/pos-components/pos-price-calculation/pos-price-calculation.component';
import { PosProductSearchComponent } from './pages/pos-components/pos-product-search/pos-product-search.component';
import { PosSearchCustomerComponent } from './pages/pos-components/pos-search-customer/pos-search-customer.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { MatDialogModule, MatDialogConfig, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule} from '@angular/material/button-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatCardModule } from '@angular/material/card';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatNativeDateModule  } from '@angular/material/core';
import { ReportsComponent } from './pages/reports/reports.component';
import { ShopsComponent } from './pages/shops/shops.component';
import { TerminalsComponent } from './pages/terminals/terminals.component';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule} from '@angular/material/checkbox';
import { LoginComponent } from './core/login/login.component';
import { PosCardPayDialogComponent } from './pages/pos-components/pos-card-pay-dialog/pos-card-pay-dialog.component';
import { PosCashPayDialogComponent } from './pages/pos-components/pos-cash-pay-dialog/pos-cash-pay-dialog.component';
import { MatOptionModule} from '@angular/material/core';
import { InterceptorService } from './services/interceptor.service';
import { PosPriceFormatComponent } from './pages/pos-components/pos-price-format/pos-price-format.component';
import { PosPartialPayDialogComponent } from './pages/pos-components/pos-partial-pay-dialog/pos-partial-pay-dialog.component';
import { PosDiscountDialogComponent } from './pages/pos-components/pos-discount-dialog/pos-discount-dialog.component';
import { ShopDialogComponent } from './pages/shops/shop-dialog/shop-dialog.component';
import { TerminalDialogComponent } from './pages/terminals/terminal-dialog/terminal-dialog.component';
import { PosVoucherDialogComponent } from './pages/pos-components/pos-voucher-dialog/pos-voucher-dialog.component';
import { PosErrorDialogComponent } from './pages/pos-components/pos-error-dialog/pos-error-dialog.component';
import { PosLinkedCustomerToOrderComponent } from './pages/pos-components/pos-linked-customer-to-order/pos-linked-customer-to-order.component';
import { PosHeaderComponent } from './pages/pos-components/pos-header/pos-header.component';
import { PosPrintOrderPreviewComponent } from './pages/pos-components/pos-print-order-preview/pos-print-order-preview.component';
import { PosPrintOrderQuoteComponent } from './pages/pos-components/pos-print-order-quote/pos-print-order-quote.component';
import { PosResetCartPopupComponent } from './pages/pos-components/pos-reset-cart-popup/pos-reset-cart-popup.component';
import { PosReportPrintComponent } from './pages/pos-components/pos-report-print/pos-report-print.component';
import { PosEndOfDayPopupComponent } from './pages/pos-components/pos-end-of-day-popup/pos-end-of-day-popup.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { ConfigurationComponent } from './pages/configuration/configuration.component';
import { ConfigurationDialogComponent } from './pages/configuration/configuration-dialog/configuration-dialog.component';
import {AgentsComponent} from "./pages/agents/agents.component";
import {AgentDialogComponent} from "./pages/agents/agent-dialog/agent-dialog.component";
import {StationsComponent} from "./pages/stations/stations.component";
import {StationDialogComponent} from "./pages/stations/station-dialog/station-dialog.component";
import {SelectStationComponent} from "./pages/stations/select-station/select-station.component";
import {SelectStationDialogComponent} from "./pages/stations/select-station-dialog/select-station-dialog.component";
import {ReceiptPrintersComponent} from "./pages/receipt-printers/receipt-printers.component";
import {
  ReceiptPrinterDialogComponent
} from "./pages/receipt-printers/receipt-printer-dialog/receipt-printer-dialog.component";
import {PrinterTestComponent} from "./pages/printer-test/printer-test.component";

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    PosComponent,
    PosCartListComponent,
    PosNewCustomerComponent,
    PosNewCustomerDialogComponent,
    PosPaymentsComponent,
    PosPriceCalculationComponent,
    PosProductSearchComponent,
    PosSearchCustomerComponent,
    ReportsComponent,
    ShopsComponent,
    TerminalsComponent,
      AgentsComponent,
      StationsComponent,
    ReceiptPrintersComponent,
    PosCardPayDialogComponent,
    LoginComponent,
    PosCashPayDialogComponent,
    PosPriceFormatComponent,
    PosPartialPayDialogComponent,
    PosDiscountDialogComponent,
    ShopDialogComponent,
    TerminalDialogComponent,
      AgentDialogComponent,
      StationDialogComponent,
      SelectStationComponent,
      SelectStationDialogComponent,
    ReceiptPrinterDialogComponent,
    PosVoucherDialogComponent,
    PosErrorDialogComponent,
    PosLinkedCustomerToOrderComponent,
    PosPrintOrderPreviewComponent,
    PosLinkedCustomerToOrderComponent,
    PosHeaderComponent,
    PosPrintOrderQuoteComponent,
    PosResetCartPopupComponent,
    PosReportPrintComponent,
    PosEndOfDayPopupComponent,
    SettingsComponent,
      PrinterTestComponent,
    PosEndOfDayPopupComponent,
    ConfigurationComponent,
    ConfigurationDialogComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatSliderModule,
    MatCardModule,
    NgbModule,
    MatMenuModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatTableModule,
    MatDialogModule,
    FormsModule,
    MatSlideToggleModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    MatOptionModule,
    MatDatepickerModule,
    MatPaginatorModule,
    MatNativeDateModule,
    MatButtonToggleModule
  ],
  bootstrap: [AppComponent],
  providers: [
    {provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {hasBackdrop: false}},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true,
    },
  ],
  exports: [
    PosPrintOrderPreviewComponent
  ]
})
export class AppModule {}
