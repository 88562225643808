<div class="col-12">

  <div class="title" style="margin-bottom: 70px">
    <h2>Order {{orderReference}}</h2>
  </div>
  <table class="MB30">
    <ng-container matColumnDef="agent">
      <th class="contentColum_distance">
        <h3>Date: {{currentDate}}</h3>
        <h3>Store: {{storeName}}</h3>
        <h3>Agent: {{agent.firstname}} {{this.agent.lastname}}</h3>
      </th>
    </ng-container>
    <ng-container matColumnDef="agent"  *ngIf="customer && customer.ID">
      <th>
        <h3>Customer: {{customer.FirstName}} {{customer.LastName}}</h3>
        <h3>E-mail: {{customer.Email}}</h3>
        <h3>ID: {{customer.ID}}</h3>
      </th>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let element; columns: displayedColumns;"></tr>
  </table>
  <mat-card class="mb-3">
    <app-pos-cart-list></app-pos-cart-list>
    <app-pos-price-calculation></app-pos-price-calculation>
  </mat-card>
</div>
