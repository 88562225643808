import { HttpClient } from '@angular/common/http';
import {Component, EventEmitter, Output} from '@angular/core';
import {environment} from "../../../../environments/environment";
import {ConfigurationService} from "../../../services/configuration.service";


@Component({
    selector: 'app-select-station',
    templateUrl: './select-station.component.html',
    styleUrls: ['./select-station.component.scss']
})
export class SelectStationComponent {

    @Output() stationChanged = new EventEmitter()

    stations: [] = [];
    selectedStation: any;

    constructor(
        private http: HttpClient,
        private configurationService: ConfigurationService
    ) {
        this.loadStations();
        this.selectedStation = {id_station: configurationService.station.id_station ?? 0};
    }

    public loadStations() {
        this.http.get<any>(environment.APP_ENVIRONMENT + environment.STATIONS_ENDPOINT + "/list").subscribe(response => {
                if (!response.success)
                    console.dir(response.error);
                else {
                    this.stations = response.stations;
                }
            },
            (err) => {
                console.dir(err);
            });
    }

    updateStation(station) {
        this.configurationService.setStation(station.id_station);
        this.stationChanged.emit();
    }

    stationCompareFn(station1: any, station2: any) {
        return station1 && station2 && station1.id_station === station2.id_station;
    }



}
