import { Component, OnInit, Inject } from '@angular/core';
import { CustomerService } from '../../../services/customer.service';
import { Customer } from 'src/app/customer';
import { BillingAddress } from 'src/app/billing-address';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {ConfigurationService} from "../../../services/configuration.service";


@Component({
  selector: 'app-pos-new-customer-dialog',
  templateUrl: './pos-new-customer-dialog.component.html',
  styleUrls: ['./pos-new-customer-dialog.component.scss']
})
export class PosNewCustomerDialogComponent implements OnInit {
  public customer: Customer;
  errorMessageLabel: HTMLElement;
  countries: any;
  checked = false;
  fullRegistration = false;
  customerObjectQuickValidator: FormGroup;
  customerObjectFullValidator: FormGroup;
  regexExpForEmail = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  regexExpForPostCode = new RegExp(/^[a-zA-Z0-9 .,]{4,}$/);
  regexExpForStreetNumber = new RegExp(/^[a-zA-Z0-9 .,]+$/);
  regexExpForSpecialCharacterStrings = new RegExp(/^[a-zA-Z\u00c0-\u024f\u1e00-\u1eff .,-]+$/);
  selectedCountry: number;
  isEdit = false;
  addressFieldsRequired: boolean = false;


  constructor(public currentDialogRef: MatDialog,
              private customerService: CustomerService,
              public dialog: MatDialogRef<PosNewCustomerDialogComponent>,
              private configurationService: ConfigurationService,
              @Inject(MAT_DIALOG_DATA) public data) {
    this.customerService.getCountries()
      .subscribe(response => {
        const responseContent: any = response;
        this.countries = responseContent.result.items;
        this.selectedCountryName();
      });
  }

  ngOnInit(): void {
    this.customerService.customerChangedEvent.subscribe(customer => this.customer = customer);
    if (this.isNewCustomerButtonClicked()) {
      this.customer = this.customerService.createEmptyCustomer();
    }

    this.customerObjectQuickValidator = new FormGroup({
      email: new FormControl(this.customer.Email, [
        Validators.required,
        Validators.pattern(this.regexExpForEmail)]),
    });

    this.initCustomerObjectFullValidator();

    if (this.customer.ID) {
      this.isEdit = !this.isNewCustomerButtonClicked();
      this.toggleClicked();
    } else {
      this.customerService.createEmptyCustomer();
    }
  }

  public clearContent_OnClick(content) {
    content.value = '';
    this.validateCustomer();
  }

  selectedCountryName(): void {
    const configCountry = this.configurationService.getCountry();
    this.selectedCountry = (configCountry ? parseInt(configCountry) : this.countries[0].id_country);
    
    for (const [index, country] of this.countries.entries()) {
      if (!this.customer.BillingAddress.Country) {
        this.customer.BillingAddress.Country = country.iso_code;
        break;
      }

      if (country.iso_code === this.customer.BillingAddress.Country) {
        this.selectedCountry = this.countries[index].id_country;
        break;
      }
    }
    
    return;
  }

  selectedCountryOnChange(value: number): void {
    this.customer.BillingAddress.Country = this.countries[value].iso_code;
  }

  private refreshValidatorEvent(isFullActive: boolean): void{
    if (!isFullActive){
      this.customerObjectQuickValidator.get('email').setValue(this.customer.Email);
    }
    else {
      this.customerObjectFullValidator.get('email').setValue(this.customer.Email);
      if (this.addressFieldsRequired) {
        this.customerObjectFullValidator.get('address.postCode').setValue(this.customer.BillingAddress.PostCode);
        this.customerObjectFullValidator.get('address.street').setValue(this.customer.BillingAddress.Street);
        this.customerObjectFullValidator.get('address.streetNumber').setValue(this.customer.BillingAddress.StreetNumber);
        this.customerObjectFullValidator.get('address.city').setValue(this.customer.BillingAddress.City);
      }
    }
  }

  validateCustomer() {
    this.addressFieldsRequired = this.areAddressFieldsRequired();
    this.initCustomerObjectFullValidator();

    this.updateErrorMessageLabel(null); // to reset the error label # this.errorMessageLabel
    this.refreshValidatorEvent(this.fullRegistration);
  }

  initCustomerObjectFullValidator() {
    if (this.addressFieldsRequired) {
      this.customerObjectFullValidator = new FormGroup({
        email: new FormControl(this.customer.Email, [
          Validators.required,
          Validators.pattern(this.regexExpForEmail)]),
        address: new FormGroup({
          postCode: new FormControl(this.customer.BillingAddress.PostCode, [
            Validators.required,
            Validators.pattern(this.regexExpForPostCode)]),
          street: new FormControl(this.customer.BillingAddress.Street, [
            Validators.required,
            Validators.pattern(this.regexExpForSpecialCharacterStrings)]),
          streetNumber: new FormControl(this.customer.BillingAddress.StreetNumber, [
            Validators.required,
            Validators.pattern(this.regexExpForStreetNumber)]),
          city: new FormControl(this.customer.BillingAddress.City, [
            Validators.required,
            Validators.pattern(this.regexExpForSpecialCharacterStrings)]),
        })
      });
    } else {
      this.customerObjectFullValidator = new FormGroup({
        email: new FormControl(this.customer.Email, [
          Validators.required,
          Validators.pattern(this.regexExpForEmail)]),
      });
    }
  }

  areAddressFieldsRequired() {
    let billingAddress = this.customer.BillingAddress;
    return (billingAddress.PostCode != "" && billingAddress.PostCode != undefined) || 
      (billingAddress.Street != "" && billingAddress.Street != undefined) || 
      (billingAddress.StreetNumber != "" && billingAddress.StreetNumber != undefined) || 
      (billingAddress.City != "" && billingAddress.City != undefined)
  }

  requestNewPassword(event?: Event){
    this.customer.RequestNewPassword = !this.checked;
  }

  private updateDialogSize(isFullRegistration?: boolean): void{
    if (isFullRegistration){
      this.dialog.updateSize('800px', '90%');
    }
    else {
      this.dialog.updateSize('430px', 'auto');
    }

  }

  toggleClicked() {
    this.fullRegistration = !this.fullRegistration;
    this.updateDialogSize(this.fullRegistration);
    this.validateCustomer();
  }

  generalErrorMessage:string = '';

  updateErrorMessageLabel(message, labelID = "") {
    if (labelID == "") {
      labelID = 'errorMessageLabel';
    }
    if(labelID == 'errorMessageLabel') {
      this.generalErrorMessage = message ?? '';
      return;
    }
    this.errorMessageLabel = document.getElementById(labelID);
    if (message) {
      this.errorMessageLabel.innerHTML = message;
    }
    else{
      this.errorMessageLabel.innerHTML = '';
    }
  }

  closeDialog_OnClick() {
    this.currentDialogRef.closeAll();
    this.customerService.changeLinkedCustomer(this.customer);
  }

  saveCustomer_OnClick() {
    if (this.validateFields()) {
      try {
        this.customerService.saveCustomer(this.customer)
          .subscribe((response) => {
            if (response.success) {
              if (!(response.result.hasOwnProperty('error'))) {
                this.customerService.searchCustomer(this.customer.Email).subscribe((customer) => {
                  this.customer = customer.result.items[0];
                  this.customerService.changeLinkedCustomer(customer.result.items[0]);
                });
  
                this.currentDialogRef.closeAll();
              } else {
                this.updateErrorMessageLabel(response.result.error);
              }
            } else {
              this.updateErrorMessageLabel(response.result.error);
            }
          });
      } catch (err) {
        this.updateErrorMessageLabel(err.result.error);
      } 
    }
  }

  isNewCustomerButtonClicked(): boolean {
    return this.data && this.data.isNewCustomerDialog;
  }

  validateFields() {

    // TBD: How should we handle these? The general idea was to
    // add multiple labels in HTML for each possible error,
    // and activate them with a flag in TS. However, I think
    // here we are expected to only have one label for each field.
    // We are also receiving some of the errors from the backend,
    // so we can't hardcode everything into the UI. We might want to
    // look into how hard it is to actually translate constants in TS
    // versus elements in HTML.
    let emailInvalid = this.customerObjectFullValidator.get('email').status == 'INVALID';
    let emailLabelMessage = emailInvalid ? 'Email address is invalid.' : '';
    this.updateErrorMessageLabel(emailLabelMessage, 'invalidEmailError');

    if (!this.fullRegistration) {
      return !emailInvalid;
    }

    let streetInvalid = this.addressFieldsRequired && this.customerObjectFullValidator.get('address.street').status == 'INVALID';
    let streetLabelMessage = streetInvalid ? 'Street is invalid.' : '';
    this.updateErrorMessageLabel(streetLabelMessage, 'invalidStreetError');

    let streetNumberInvalid = this.addressFieldsRequired && this.customerObjectFullValidator.get('address.streetNumber').status == 'INVALID';
    let streetNumberLabelMessage = streetNumberInvalid ? 'Street number is invalid.' : '';
    this.updateErrorMessageLabel(streetNumberLabelMessage, 'invalidStreetNumber');
    
    let postCodeInvalid = this.addressFieldsRequired && this.customerObjectFullValidator.get('address.postCode').status == 'INVALID';
    let postCodeLabelMessage = postCodeInvalid ? 'Post code is invalid.' : '';
    this.updateErrorMessageLabel(postCodeLabelMessage, 'invalidPostCode');

    let cityInvalid = this.addressFieldsRequired && this.customerObjectFullValidator.get('address.city').status == 'INVALID';
    let cityLabelMessage = cityInvalid ? 'City is invalid.' : '';
    this.updateErrorMessageLabel(cityLabelMessage, 'invalidCity');

    return !emailInvalid && !streetInvalid && !streetNumberInvalid && !postCodeInvalid && !cityInvalid;
  }
}
