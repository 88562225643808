<app-pos-header></app-pos-header>
<mat-card>
  <mat-card-header>
    <mat-card-title>Edit Configuration</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <table mat-table [dataSource]="rows" class="">
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Name </th>
        <td mat-cell *matCellDef="let row"> {{row.name}} </td>
      </ng-container>
      <ng-container matColumnDef="value">
        <th mat-header-cell *matHeaderCellDef> Value </th>
        <td mat-cell *matCellDef="let row"> {{row.value}} </td>
      </ng-container>
      <ng-container matColumnDef="frontend">
        <th mat-header-cell *matHeaderCellDef> Frontend </th>
        <td mat-cell *matCellDef="let row"><mat-checkbox disabled="true" [checked]="row.frontend"></mat-checkbox> </td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="crud-actions-column text-right pr-40"> Actions </th>
        <td mat-cell *matCellDef="let row" class="crud-actions-column">
          <button mat-button color="secondary" (click)="editConfiguration(row)"><mat-icon class="edit-icon">edit</mat-icon> Edit</button>
          <button mat-button color="secondary" (click)="deleteConfiguration(row)"><mat-icon class="delete-icon">delete</mat-icon> Delete</button>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </mat-card-content>
</mat-card>
<button mat-fab color="primary" class="fab-main" aria-label="Add" (click)="createConfiguration()">
  <mat-icon>add</mat-icon>
</button>
