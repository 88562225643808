export class ReceiptPrinter {
    id_receipt_printer: number = 0;
    url: string = 'http://127.0.0.1:18080/WebPrintSDK/'; // full url. Default: http://127.0.0.1:18080/WebPrintSDK/
    logical_name: string = ''; // some kind of identifier, default seems to be Printer1
    paper_size: string = ''; // 2in, 3in or 4in
    description: string = ''; // free text field for user reference
    id_shop: number = 0;
    shop_name: string = '';

    public cloneFrom(oldPrinter: ReceiptPrinter) {
        this.id_receipt_printer = oldPrinter.id_receipt_printer;
        this.url = oldPrinter.url;
        this.logical_name = oldPrinter.logical_name;
        this.paper_size = oldPrinter.paper_size;
        this.description = oldPrinter.description;
        this.id_shop = oldPrinter.id_shop;
        this.shop_name = oldPrinter.shop_name;
    }
}
