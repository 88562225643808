<app-pos-header title="Printer Test"></app-pos-header>

<mat-card>
    <mat-card-content>
        <div class="col-xs-12 col-sm-8 col-md-6 col-lg-4 col-xl-3">

            <div class="alert alert-info" style="font-family: monospace">
                To configure the receipt printer:<br><br>
                <span class="text-secondary">URL</span>: The printer address. If the printer is connected to the local machine, keep the default value. Otherwise, change the IP part to match the printer host.<br>
                <span class="text-secondary">Logical Name</span>: The printer's logical name.<br>
                <span class="text-secondary">Paper Size</span>: The width of the paper roll currently loaded into the printer.
            </div>

            <mat-form-field appearance="fill">
                <mat-label>URL</mat-label>
                <input [(ngModel)]="printer.url" matInput type="text">
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label>Logical Name</mat-label>
                <input [(ngModel)]="printer.logical_name" matInput type="text">
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label>Paper Size</mat-label>
                <mat-select [(ngModel)]="printer.paper_size">
                    <mat-option value="2in">2 inch</mat-option>
                    <mat-option value="3in">3 inch</mat-option>
                    <mat-option value="4in">4 inch</mat-option>
                </mat-select>
            </mat-form-field>
            <p>Printer Response: {{printerResponse}}</p>
        </div>

    </mat-card-content>
    <mat-card-actions>
        <button mat-raised-button color="primary" (click)="runPrint()" style="min-width: 200px; margin-left: 15px;margin-bottom: 15px;">Run Print</button>
    </mat-card-actions>
</mat-card>
