import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ProductService } from 'src/app/services/product.service';
import { MatDialog } from '@angular/material/dialog';
import { CartService } from 'src/app/services/cart.service';

@Component({
  selector: 'app-pos-product-search',
  templateUrl: './pos-product-search.component.html',
  styleUrls: ['./pos-product-search.component.scss']
})
export class PosProductSearchComponent implements OnInit {

  myControl = new FormControl();
  autoSuggestProducts = [];
  resultsLimited = false;
  selectedProduct = [];
  autoSuggestKeyword = '';
  productSearchSubscriber;
  productSearchStarted = false;

  constructor(
     private ProductServiceInstance: ProductService,
     public CartServiceInstance: CartService,
     public dialog: MatDialog
  ) { }

  ngOnInit(): void {
  }

  productsSearch(name: any) {
    this.autoSuggestKeyword = name.target.value;
    if (this.autoSuggestKeyword.length > 2) {
      if (this.productSearchStarted) {
        this.productSearchSubscriber.unsubscribe();
        this.productSearchStarted = false;
      }

      this.productSearchSubscriber = this.ProductServiceInstance.searchProducts('en', this.autoSuggestKeyword).subscribe(productSearchResult => {
        this.productSearchStarted = true;
        this.resultsLimited = productSearchResult.has_more;
        const productsdata = productSearchResult.products;

        if (productsdata.length > 0) {
          this.autoSuggestProducts = productsdata;
        }

        // note: if only one product is found by reference or barcode, we select it in order to be added in cart
        if (productsdata.length === 1 && (this.autoSuggestKeyword == productsdata[0].Barcode || this.autoSuggestKeyword == productsdata[0].Reference)) {
          this.processSelectedAutoSuggestProduct(productsdata[0]);
          this.resetAutoSuggestProducts();
        }
      });
    }
  }

  processSelectedAutoSuggestProduct(product) {
    this.CartServiceInstance.addProductToCart(product);
    this.resetAutoSuggestProducts();
  }

  resetAutoSuggestProducts() {
    this.autoSuggestProducts.length = 0;
    this.autoSuggestKeyword = '';
  }

  updateCartServiceProductQuantity($event, action = null) {
    var selectedQuantity = parseInt($event.target.value);

    if (action != null) {
      selectedQuantity = this.CartServiceInstance.searchInputSelectedProductQuantity;
    }

    if (action == 'down') {
      selectedQuantity -= 1;
    }

    if (action == 'up') {
      selectedQuantity += 1;
    }

    if (selectedQuantity < 1 || isNaN(selectedQuantity)) {
      selectedQuantity = 1;
    }

    this.CartServiceInstance.searchInputSelectedProductQuantity = selectedQuantity;
    $event.target.value = selectedQuantity;
  }

  public get getProductQuantity() {
    return this.CartServiceInstance.searchInputSelectedProductQuantity;
  }
}
