<app-pos-header title="Agents Management"></app-pos-header>

<mat-card>
    <mat-card-header>
        <mat-card-subtitle>Total rows: {{totalResults}}. Total pages: {{totalPages}}</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
        <table mat-table [dataSource]="rows" class="">


            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef> ID </th>
                <td mat-cell *matCellDef="let row"> {{row.id_agent}} </td>
            </ng-container>

            <ng-container matColumnDef="username">
                <th mat-header-cell *matHeaderCellDef> Username </th>
                <td mat-cell *matCellDef="let row"> {{row.username}} </td>
            </ng-container>

            <ng-container matColumnDef="firstname">
                <th mat-header-cell *matHeaderCellDef> First Name </th>
                <td mat-cell *matCellDef="let row"> {{row.firstname}} </td>
            </ng-container>

            <ng-container matColumnDef="lastname">
                <th mat-header-cell *matHeaderCellDef> Last Name </th>
                <td mat-cell *matCellDef="let row"> {{row.lastname}} </td>
            </ng-container>

          <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef> Type </th>
            <td mat-cell *matCellDef="let row"> {{(row.type == 1 ? 'Admin' : 'Agent')}} </td>
          </ng-container>

            <ng-container matColumnDef="shop">
                <th mat-header-cell *matHeaderCellDef> Shop </th>
                <td mat-cell *matCellDef="let row"> {{row.shop_name}} </td>
            </ng-container>



            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef class="crud-actions-column text-right pr-40"> Actions </th>
                <td mat-cell *matCellDef="let row" class="crud-actions-column">
                    <button mat-button color="secondary" (click)="editAgent(row)"><mat-icon class="edit-icon">edit</mat-icon> Edit</button>
                    <button mat-button color="secondary" (click)="deleteAgent(row)"><mat-icon class="delete-icon">delete</mat-icon> Delete</button>
                </td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </mat-card-content>
    <mat-card-actions align="end">
        <button mat-button [color]="link.active ? 'primary' : null" *ngFor="let link of paginationLinks" [disabled]="!link.url" (click)="changePage(link.url)" [innerHtml]="link.label"></button>
    </mat-card-actions>
</mat-card>

<button mat-fab color="primary" class="fab-main" aria-label="Add" (click)="createAgent()">
    <mat-icon>add</mat-icon>
</button>



