<div *ngIf="selectedCustomer && selectedCustomer.ID">
  <mat-card class="linked-customer">
    <mat-card-subtitle>signed in as</mat-card-subtitle>
    <mat-card-subtitle>ID: {{customer.ID}}</mat-card-subtitle>
    <mat-card-content>
      <p *ngIf="customer.FirstName && customer.LastName">Name: {{customer.FirstName + ' ' + customer.LastName}}</p>
      <p>Email: {{customer.Email}}</p>
      <p *ngIf="customer.CompanyName">Company name: {{customer.CompanyName}}</p>
    </mat-card-content>
    <mat-card-actions *ngIf="displayActionButtons()">
      <button mat-button aria-label="Edit Customer" (click)="editCustomer($event)"> <mat-icon> edit </mat-icon> <span>Edit customer</span> </button>
      <button mat-button aria-label="Unlink Customer" (click)="unlinkCustomer($event)">
        <mat-icon>exit_to_app</mat-icon> Logout user
      </button>
    </mat-card-actions>
  </mat-card>
</div>
