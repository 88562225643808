import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { CartService } from './cart.service';
import { CustomerService } from './customer.service';
import { PaymentService } from './payment.service';

@Injectable({
    providedIn: 'root'
})
export class OrderService {

    private orderID: number;
    private orderReference: string;

    constructor(
        private http: HttpClient,
        private customerService: CustomerService,
        private cartService: CartService,
        private paymentService: PaymentService
    ) {
        this.initOrderData();
        this.cartService.cartResetEvent.subscribe(() => {
            this.initOrderData();
        })
    }

    public getOrderData() {
        let customer = this.customerService.linkedCustomer;
        let cartDiscount = this.cartService.discount;
        return {
            CustomerID: parseInt(customer.ID),
            DeliveryAddressID: customer.BillingAddress.ID,
            InvoiceAddressID: customer.BillingAddress.ID,
            CurrencyID: customer.CurrencyID,
            Items: this.getOrderProducts(),
            Discount: {
                type: cartDiscount.type == 0 ? 'percentage' : 'fixedAmount',
                total: cartDiscount.value
            },
            Vouchers: this.cartService.vouchers,
            PaymentType: this.paymentService.paymentType
        };
    }

    public getOrderProducts() {
        let cartProducts = this.cartService.cartProducts;
        let orderProducts = [];
        if (cartProducts.length > 0) {
            cartProducts.forEach(cartProduct => {
                orderProducts.push({
                    Quantity: cartProduct.quantity,
                    ProductCode: cartProduct.Reference
                });
            });
        }

        return orderProducts;
    }

    public requestOrderSave(orderData) {
        let createOrderApiEndpoint = environment.APP_ENVIRONMENT + environment.ORDER_SAVE_ENDPOINT;
        return this.http.post<any>(createOrderApiEndpoint, orderData);
    }

    public requestOrderDelete() {
        let deleteOrderApiEndpoint = environment.APP_ENVIRONMENT + environment.ORDER_DELETE_ENDPOINT;
        return this.http.get<any>(deleteOrderApiEndpoint + '/' + this.getOrderID());
    }

    public requestOrderConfirmationEmail() {
        const orderConfirmationEmailApiEndpoint = environment.APP_ENVIRONMENT + environment.SEND_ORDER_CONFIRMATION_EMAIL_ENDPOINT;
        const orderConfirmationData = {
            orderID: this.getOrderID(),
            order: this.getOrderData(),
        };

        return this.http.post<any>(orderConfirmationEmailApiEndpoint, orderConfirmationData);
    }

    public initOrderData() {
        this.setOrderID(0);
        this.setOrderReference(null);
    }

    public getOrderID(): number {
        return this.orderID;
    }

    public setOrderID(orderID: number): void {
        this.orderID = orderID;
    }

    public getOrderReference(): string {
        return this.orderReference ? this.orderReference : this.getOrderReferenceFromLocalStorage();
    }

    setOrderReference(orderReference: string): void {
        this.orderReference = orderReference;
        if (orderReference != null) {
            this.setOrderReferenceInLocalStorage(orderReference);
        }
    }

    public setOrderReferenceInLocalStorage(orderReference: string): void {
        window.localStorage.setItem('orderReference', orderReference);
    }

    public getOrderReferenceFromLocalStorage(): string {
        return window.localStorage.getItem('orderReference');
    }

    public removeOrderReferenceFromLocalStorage(): void {
        window.localStorage.removeItem('orderReference');
    }

    public emptyOrderData() {
        this.initOrderData();
        this.removeOrderReferenceFromLocalStorage();
    }
}
