<h1 mat-dialog-title>Total to pay: <app-pos-price-format [price]="data.totalToPay"></app-pos-price-format></h1>

<div mat-dialog-content>
    <div class="row">
      <div class="col-6">
        <mat-form-field appearance="fill" [ngClass]="{'mat-form-field-invalid': inputCardHigherAmountAdded}">
          <mat-label>Card:</mat-label>
          <input matInput [(ngModel)]="data.totalToPayUsingCard" (input)="updateTotalToPayUsingCard($event)">
        </mat-form-field>
        <button mat-raised-button color="primary" class="mb-3" (click)="onNoClick('card')" *ngIf="data.totalToPayUsingCard > 0"><mat-icon>credit_card</mat-icon>Pay with Card</button>
      </div>
      <div class="col-6">
        <mat-form-field appearance="fill" [ngClass]="{'mat-form-field-invalid': inputCardHigherAmountAdded}">
          <mat-label>Cash:</mat-label>
          <input matInput [(ngModel)]="data.totalToPayUsingCash" (input)="updateTotalToPayUsingCash($event)" [maxlength]="inputFieldMaxLength">
        </mat-form-field>
        <button mat-raised-button color="primary" class="mb-3" (click)="onNoClick('cash')" *ngIf="data.totalToPayUsingCash > 0"><mat-icon>money</mat-icon>Pay Cash</button>
      </div>

    </div>
    <p>Total left to pay: <app-pos-price-format [price]="data.totalLeftToPay"></app-pos-price-format></p>
</div>

<div mat-dialog-actions class="mb-2 justify-content-space-between">
  <button mat-button mat-raised-button (click)="closeDialog()">No Thanks</button>
  <button mat-button mat-raised-button color="primary" cdkFocusInitial (click)="onNoClick()">Ok</button>
</div>
