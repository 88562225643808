<form>
  <div class="prod_search_bar">
    <div class="prod_search_bar__left prod_search_qty_field">
      <mat-form-field class="product-quantity" appearance="fill">
        <mat-icon (click)="updateCartServiceProductQuantity($event, 'down')" matPrefix>remove</mat-icon>
        <mat-label>Quantity</mat-label>
        <input type="text" inputmode="numeric" pattern="[0-9]" min="1" matInput value="{{this.CartServiceInstance.searchInputSelectedProductQuantity}}" (input)="updateCartServiceProductQuantity($event)" />
        <mat-icon (click)="updateCartServiceProductQuantity($event, 'up')" matSuffix>add</mat-icon>
      </mat-form-field>
    </div>
    <div class="prod_search_bar__right">
      <mat-form-field class="product-search" appearance="fill">
        <mat-label>Product search</mat-label>
        <input matInput type="text" [value]="autoSuggestKeyword" [matAutocomplete]="productsearchautocomplete" (input)="productsSearch($event)" />
        <button matSuffix mat-icon-button aria-label="Clear" (click)="resetAutoSuggestProducts()">
          <mat-icon>close</mat-icon>
        </button>
        <mat-autocomplete #productsearchautocomplete="matAutocomplete">
          <div *ngIf="resultsLimited" style="color: red; text-align: center; padding-bottom: 10px; padding-top: 10px">There are too many results, please refine the search keywords to see the relevant products</div>
          <mat-option *ngFor="let product of autoSuggestProducts" (click)="processSelectedAutoSuggestProduct(product)">
            <span><img src="{{ product.Image }}" onerror="this.src='/assets/placeholder.png';" style="width: 100px; height: 100px;"/></span>
            {{ product.Name.en }} - {{ product.Reference }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
  </div>
</form>
