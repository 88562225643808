import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CustomerService } from 'src/app/services/customer.service';
import { PaymentService } from 'src/app/services/payment.service';
import { ToolsService } from 'src/app/services/tools.service';
import { ValidationService } from 'src/app/services/validation.service';
import { payment_method_type } from 'src/environments/environment';

export interface DialogData {
    totalToPay: number;
    totalToPayFirstOption: number;
    totalToPaySecondOption: number;
    totalToPayThirdOption: number;
    amountReceived: number;
    change: number;
}

@Component({
    selector: 'app-pos-cash-pay-dialog',
    templateUrl: './pos-cash-pay-dialog.component.html',
    styleUrls: ['./pos-cash-pay-dialog.component.scss']
})
export class PosCashPayDialogComponent {

    paidWithSuccess = false;
    lowerAmountSelected = false;

    constructor(
        public dialogRef: MatDialogRef<PosCashPayDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        private paymentService: PaymentService,
        private customerService: CustomerService,
        private validationService: ValidationService,
        private toolsService: ToolsService
    ) {
        if (!this.paymentService.isPartialPayment()) {
            this.updateLowerAmountSelected();
            this.paymentService.paymentType = payment_method_type.CASH_PAYMENT_TYPE;
        }
    }

    onNoClick(): void {
        this.dialogRef.close({
            paidWithSuccess: this.paidWithSuccess
        });
    }

    updateAmounts(amount, $event = null): void {
        this.data.amountReceived = amount;

        // if amount is 0 and we add a number after that 0, we ignore that 0 (eg: 01 will become 1)
        if ($event !== null && $event.target.value[0] == 0 && !$event.target.value.includes('.')) {
            $event.target.value = $event.data;
        }

        if ($event !== null) {
            this.data.amountReceived = this.validationService.isAmountToPayInvalid($event.target.value) ? 0 : $event.target.value;
            $event.target.value = this.data.amountReceived;
        }

        this.updateChange();

        if (!this.paymentService.isPartialPayment()) {
            this.updateLowerAmountSelected();
        }
    }

    public updateChange(): void {
        let change = this.data.amountReceived - this.data.totalToPay;
        if (change < 0) {
            change = 0;
        }

        this.data.change = this.toolsService.formatNumber(change);
    }

    public updateLowerAmountSelected(): void {
        this.lowerAmountSelected = this.data.amountReceived < this.data.totalToPay;
    }

    public createPayment(): void {
        let paymentData = {
            Amount: this.data.totalToPay,
            PaymentMethod: "CashPay",
            TransactionID: "",
            CustomerId: parseInt(this.customerService.linkedCustomer.ID),
        };

        if (paymentData.Amount > 0) {
            this.paymentService.createPayment(paymentData).subscribe(paymentResult => {
                this.paidWithSuccess = paymentResult.success;
                this.onNoClick();
            },
            (error) => {
                this.paidWithSuccess = false;
            });
        }
    }

    public get inputFieldMaxLength(): number {
        return this.validationService.getInputFieldMaxLength();
    }
}
