import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DiscountDetails, DiscountType } from "../../../../discount-details";

@Component({
    selector: 'app-pos-discount-dialog',
    templateUrl: './pos-discount-dialog.component.html',
    styleUrls: ['./pos-discount-dialog.component.scss'],
})
export class PosDiscountDialogComponent implements OnInit, AfterViewInit {
    @ViewChild('discountValueField') discountValueField: ElementRef;
    discount: DiscountDetails = new DiscountDetails();
    public maxValue = 0;
    public visibleErrors = {
        invalidValue: false,
        invalidPercentage: false,
        exceededMax: false,

        anyError: function () {
            return this.invalidValue
                || this.invalidPercentage
                || this.exceededMax
        }
    }

    constructor(
        public dialogRef: MatDialogRef<PosDiscountDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public originalDiscount: DiscountDetails
    ) {
        this.discount.type = originalDiscount.type;
        this.discount.value = originalDiscount.value;
    }

    ngOnInit(): void {
    }

    public saveDiscount() {
        this.visibleErrors.invalidValue = this.discount.value < 0;
        this.visibleErrors.invalidPercentage = this.discount.type === DiscountType.Percentage && this.discount.value > 100;
        this.visibleErrors.exceededMax = this.discount.type === DiscountType.FixedAmount && this.discount.value > this.maxValue;
        if (this.visibleErrors.anyError())
            return;

        this.originalDiscount.type = this.discount.type;
        this.originalDiscount.value = this.discount.value;
        this.dialogRef.close();
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.discountValueField.nativeElement.focus();
            if (this.discount.value === 0) {
                this.discountValueField.nativeElement.select();
            }
        });
    }
}
