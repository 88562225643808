import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { MatDialog } from '@angular/material/dialog';
import {environment} from "../../../environments/environment"
import {LaravelPaginationLink} from "../../laravel-pagination-link";
import {Agent} from "../../agent";
import {AgentDialogComponent} from "./agent-dialog/agent-dialog.component";
import {CustomerService} from "../../services/customer.service";


@Component({
  selector: 'app-agents',
  templateUrl: './agents.component.html',
  styleUrls: ['./agents.component.scss']
})
export class AgentsComponent implements OnInit {

  paginationLinks: LaravelPaginationLink[] = [];
  currentPage;
  totalResults;
  totalPages;
  currentLink = environment.APP_ENVIRONMENT + environment.AGENTS_ENDPOINT;
  rows: Agent[] = [];
  displayedColumns: string[] = ['id', 'username', 'firstname', 'lastname', 'type', 'shop', 'actions'];


  constructor(
    private authenticationService: AuthenticationService,
    public dialog: MatDialog,
    private http: HttpClient,
    private customerService: CustomerService
  ) { }

  ngOnInit(): void {
      this.reloadTable();
  }

  createAgentFromJson(json: any): Agent
  {
    let result = new  Agent();
    result.cloneFrom(json);
    return result;
  }

  reloadTable() {
    this.http.get<any>(this.currentLink).subscribe(response => {
        this.rows = response.data.map((json) => {return this.createAgentFromJson(json)});
        this.currentPage = response.current_page;
        this.paginationLinks = response.links;
        this.totalPages = response.last_page;
        this.totalResults = response.total;
      },
      (err) => {
        console.dir(err);
      });
  }

  editAgent(agent: Agent, callback: CallableFunction = () => {}) {
    const dialogRef = this.dialog.open(AgentDialogComponent, {
        hasBackdrop: true,
        data: agent
      }
    );
    dialogRef.afterClosed().subscribe(result => {
      callback(result)
    })
  }

  changePage(link) {
    this.currentLink = link;
    this.reloadTable();
  }

  createAgent() {
    let agent = new  Agent();
    this.editAgent(agent, (result) => {
      this.reloadTable();
    })
  }

  deleteAgent(agent: Agent) {
    this.http.post<any>(environment.APP_ENVIRONMENT+environment.AGENTS_ENDPOINT+"/delete/"+agent.id_agent, {}).subscribe(response => {
        if(!response.success)
          console.dir(response.error);
        else {
          this.reloadTable();
        }
      },
      (err) => {
        console.dir(err);
      });
  }
}
