import {AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Configuration} from "../../../configuration";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
@Component({
  selector: 'app-configuration-dialog',
  templateUrl: './configuration-dialog.component.html',
  styleUrls: ['./configuration-dialog.component.scss'],
})
export class ConfigurationDialogComponent implements OnInit, AfterViewInit {
  @ViewChild('configurationNameField') configurationNameField: ElementRef;
  public visibleErrors = {
    emptyName: false,
    emptyValue: false,

    anyError: function() {
      return this.emptyName
          || this.emptyValue
    }
  }
  disabledName: boolean = false;
  configuration: Configuration = new Configuration();
  // constructor() { }
  constructor(public dialogRef: MatDialogRef<ConfigurationDialogComponent>, @Inject(MAT_DIALOG_DATA) public originalConfiguration: Configuration, private http: HttpClient) {
    this.configuration.cloneFrom(originalConfiguration);
    if(this.configuration.name)
      this.disabledName = true;
  }
  ngOnInit(): void {
  }

  public saveConfiguration() {
    this.visibleErrors.emptyName = !this.configuration.name;
    this.visibleErrors.emptyValue = !this.configuration.value;
    if(this.visibleErrors.anyError())
      return;

    this.http.post<any>(environment.APP_ENVIRONMENT+environment.CONFIGURATION_ENDPOINT+"/save", this.configuration).subscribe(response => {
        if(!response.success)
          console.dir(response.error);
        else {
          this.originalConfiguration.cloneFrom(this.configuration);
          this.dialogRef.close();
        }
      },
      (err) => {
        console.dir(err);
      });
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.configurationNameField.nativeElement.focus();
    });
  }
}
