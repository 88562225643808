import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ToolsService {

    public formatNumber(value: number): number {
        return Math.round(value * 100) / 100;
    }
}
