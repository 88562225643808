<div class="client_logo MB30">
  <img class="" src="{{ logoUrl }}" />
</div>
<h1 mat-dialog-title>Login</h1>
<mat-dialog-content class="fullWidthWrapper" (keydown.enter)="login()">
    <div class="alert alert-danger" *ngIf="error_message !== ''">{{error_message}}</div>
    <div class="row">
        <div class="col-12">
            <mat-form-field appearance="fill">
                <mat-label>User</mat-label>
                <input matInput [(ngModel)]="data.user">
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label>Password</mat-label>
                <input matInput [type]="'password'" [(ngModel)]="data.password">
            </mat-form-field>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-raised-button color="primary" class=" btn-block mb-1" (click)="login()">Login</button>
</mat-dialog-actions>
