<div class="row">
  <div class="col-8 new-customer-heading">
    <h1 mat-dialog-title>
      <span *ngIf="!isEdit">New Customer</span>
      <span *ngIf="isEdit">Edit</span>
    </h1>
    <div mat-dialog-content class="edit-customer-email">
      <div *ngIf="isEdit">{{customer.Email}}</div>
    </div>
  </div>
  <div class="col-4">
    <div class="registration-type">
      <mat-slide-toggle [style.visibility]="isEdit ? 'hidden' : 'visible'" (click)="toggleClicked()"> Full registration </mat-slide-toggle> <br><br>
    </div>
  </div>
</div>


<div class="registration-block">

  <div style="width: 100%;">
    <h2 class="mb-1">Personal:</h2>
  </div>
  <div class="custom_category-container">
    <div class="fulllWidthField">
      <mat-form-field appearance="fill" style="margin: 0;">
        <mat-label>Email address</mat-label>
        <input #emailInput matInput id="email" name="email" class="form-control" type="text" [(ngModel)]="customer.Email" (ngModelChange)="validateCustomer()" required>
        <button *ngIf="customer.Email" matSuffix mat-icon-button aria-label="Clear" (click)="customer.Email=''">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
      <label #invalidEmailError id="invalidEmailError" style="color: red" class="mb-4"></label>
    </div>
  </div>

  <ng-container *ngIf="fullRegistration">
      <div class="custom_category-container custom_category-container--3cols">
      <div class="fulllWidthField">
        <mat-form-field class="example-form-field" appearance="fill">
          <mat-label>First name</mat-label>
          <input #firstNameInput matInput id="firstName" name="firstName" class="form-control" type="text" [(ngModel)]="customer.FirstName" (ngModelChange)="validateCustomer()">
          <button *ngIf="customer.FirstName" matSuffix mat-icon-button aria-label="Clear" (click)="customer.FirstName=''">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </div>
      <div class="fulllWidthField">
        <mat-form-field class="example-form-field" appearance="fill">
          <mat-label>Last name</mat-label>
          <input #lastNameInput matInput id="lastName" name="lastName" class="form-control" type="text" [(ngModel)]="customer.LastName" (ngModelChange)="validateCustomer()">
          <button *ngIf="customer.LastName" matSuffix mat-icon-button aria-label="Clear" (click)="customer.LastName=''">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </div>
      <div class="fulllWidthField">
        <mat-form-field class="example-form-field" appearance="fill">
          <mat-label>Phone</mat-label>
          <input #phoneNoInput matInput id="phoneNo" name="phoneNo" class="form-control" type="text" [(ngModel)]="customer.Phone">
          <button *ngIf="customer.Phone" matSuffix mat-icon-button aria-label="Clear" (click)="customer.Phone=''">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </div>
    </div>
    <div style="width: 100%;">
      <h2 class="mb-1">Address:</h2>
    </div>
    <div class="custom_category-container  custom_category-container--2cols">
      <div class="fulllWidthField">
        <mat-form-field class="example-form-field" appearance="fill">
          <mat-label>Street</mat-label>
          <input matInput #streetInput id="street" name="street" class="form-control" type="text" [(ngModel)]="customer.BillingAddress.Street" (ngModelChange)="validateCustomer()" [required]="addressFieldsRequired">
          <button *ngIf="customer.BillingAddress.Street" matSuffix mat-icon-button aria-label="Clear" (click)="customer.BillingAddress.Street=''; validateCustomer();">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
        <label #invalidStreetError id="invalidStreetError" style="color: red" class="mb-4"></label>
      </div>
      <div class="fulllWidthField">
        <mat-form-field class="example-form-field" appearance="fill">
          <mat-label>Street number</mat-label>
          <input matInput #streetNoInput id="street_number" name="streetNumber" class="form-control" type="text" [(ngModel)]="customer.BillingAddress.StreetNumber" (ngModelChange)="validateCustomer()" [required]="addressFieldsRequired">
          <button *ngIf="customer.BillingAddress.StreetNumber" matSuffix mat-icon-button aria-label="Clear" (click)="customer.BillingAddress.StreetNumber=''; validateCustomer();">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
        <label #invalidStreetNumber id="invalidStreetNumber" style="color: red" class="mb-4"></label>
      </div>
    </div>
    <div class="custom_category-container  custom_category-container--3cols">
      <div class="fulllWidthField">
        <mat-form-field class="example-form-field" appearance="fill">
          <mat-label>Post code</mat-label>
          <input matInput #postCodeInput id="post_code" name="postCode" class="form-control" type="text" [(ngModel)]="customer.BillingAddress.PostCode" (ngModelChange)="validateCustomer()" [required]="addressFieldsRequired">
          <button *ngIf="customer.BillingAddress.PostCode" matSuffix mat-icon-button aria-label="Clear" (click)="customer.BillingAddress.PostCode=''; validateCustomer();">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
        <label #invalidPostCode id="invalidPostCode" style="color: red" class="mb-4"></label>
      </div>
      <div class="fulllWidthField">
        <mat-form-field class="example-form-field" appearance="fill">
          <mat-label>City:</mat-label>
          <input matInput #cityInput id="city" name="city" class="form-control" type="text" [(ngModel)]="customer.BillingAddress.City" (ngModelChange)="validateCustomer()" [required]="addressFieldsRequired">
          <button *ngIf="customer.BillingAddress.City" matSuffix mat-icon-button aria-label="Clear" (click)="customer.BillingAddress.City=''; validateCustomer();">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
        <label #invalidCity id="invalidCity" style="color: red" class="mb-4"></label>
      </div>
      <div class="fulllWidthField">
        <mat-form-field appearance="fill">
          <mat-label>Choose a country</mat-label>
          <select matNativeControl #countrySelector name="country-select" (change)="selectedCountryOnChange(countrySelector.options.selectedIndex)">
            <option [value]="country.id_country" *ngFor="let country of countries" [selected]="selectedCountry == country.id_country">
              {{country.name}}
            </option>
          </select>
        </mat-form-field>
      </div>
    </div>


    <div style="width: 100%;">
      <h2 class="mb-1">Business:</h2>
    </div>
    <div class="custom_category-container custom_category-container--2cols">
      <div class="fulllWidthField">
        <mat-form-field class="example-form-field" appearance="fill">
          <mat-label>Company name</mat-label>
          <input matInput #companyInput type="text" [(ngModel)]="customer.CompanyName">
          <button *ngIf="customer.CompanyName" matSuffix mat-icon-button aria-label="Clear" (click)="customer.CompanyName=''">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </div>
      <div class="fulllWidthField">
        <mat-form-field class="example-form-field" appearance="fill">
          <mat-label>VAT</mat-label>
          <input matInput #vatInput type="text" [(ngModel)]="customer.VatNumber">
          <button *ngIf="customer.VatNumber" matSuffix mat-icon-button aria-label="Clear" (click)="customer.VatNumber=''">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </div>
    </div>

  </ng-container><!-- end if full registration -->
  <div>
    <mat-checkbox [(ngModel)]="checked" (click)="requestNewPassword($event)" value="" color="primary"> Request new password </mat-checkbox>
  </div>
  <div>
    <div class="alert alert-danger" *ngIf="generalErrorMessage.length > 0" id="errorMessageLabel">{{generalErrorMessage}}</div>
  </div>
  <div mat-dialog-actions>
    <button mat-raised-button color="primary" cdkFocusInitial id="signUpNow_Btn" type="submit" (click)="saveCustomer_OnClick() ">Save customer</button>
    <button mat-raised-button mat-dialog-close (click)="closeDialog_OnClick()">Cancel</button>
  </div>
</div>
