export enum AgentType {
  Agent = 0,
  Admin = 1
}

export class Agent {
  id_agent: number = 0;
  username: string = '';
  firstname: string = '';
  lastname: string = '';
  id_shop: number = 0;
  shop_name: string = '';
  type: AgentType;


  public cloneFrom(oldAgent: Agent) {
    this.id_agent = oldAgent.id_agent;
    this.username = oldAgent.username;
    this.firstname = oldAgent.firstname;
    this.lastname = oldAgent.lastname;
    this.id_shop = oldAgent.id_shop;
    this.shop_name = oldAgent.shop_name;
    this.type = oldAgent.type;
  }
}
