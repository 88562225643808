<h2>Agent Details</h2>
<mat-dialog-content class="fullWidthWrapper">
    <div class="alert alert-danger" *ngIf="visibleErrors.emptyUsername">Please enter the agent username</div>
    <div class="alert alert-danger" *ngIf="visibleErrors.emptyFirstName">Please enter the first name</div>
    <div class="alert alert-danger" *ngIf="visibleErrors.emptyLastName">Please enter the last name</div>
    <div class="alert alert-danger" *ngIf="visibleErrors.emptyShop">Please select a shop</div>
    <div class="alert alert-danger" *ngIf="visibleErrors.emptyPassword">Please enter a password</div>
    <div class="row">
        <div class="col-xs-12 col-lg-6">
            <mat-form-field appearance="fill">
                <mat-label>First Name</mat-label>
                <input [(ngModel)]="agent.firstname" matInput type="text">
            </mat-form-field>
        </div>
        <div class="col-xs-12 col-lg-6">
            <mat-form-field appearance="fill">
                <mat-label>Last Name</mat-label>
                <input [(ngModel)]="agent.lastname" matInput type="text">
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <mat-form-field appearance="fill">
                <mat-label>Username</mat-label>
                <input #agentUsernameField [(ngModel)]="agent.username" matInput type="text">
            </mat-form-field>
        </div>
    </div>
  <div class="row" *ngIf="agent.id_agent != authenticationService.agent.id_agent">
    <div class="col-12">
      <mat-form-field appearance="fill">
        <mat-label>Type</mat-label>
        <mat-select [(ngModel)]="agent.type">
          <mat-option [value]="0">Agent</mat-option>
          <mat-option [value]="1">Admin</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
    <div class="row">
        <div class="col-12">
            <mat-form-field appearance="fill">
                <mat-label>Password</mat-label>
                <input [(ngModel)]="newPassword" matInput type="password" autocomplete="new-password">
            </mat-form-field>
        </div>
    </div>
    <div>
        <mat-form-field appearance="fill">
            <mat-label>Shop</mat-label>
            <mat-select [(ngModel)]="selectedShop" (selectionChange)="updateShop(selectedShop)" [compareWith]="shopCompareFn">
                <mat-option *ngFor="let shop of shops" [value]="{ id_shop: shop.id_shop, shop_name: shop.name }">
                    {{ shop.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

</mat-dialog-content>

<mat-dialog-actions style="margin-bottom: 10px;">
    <button mat-raised-button color="primary" (click)="saveAgent()">Save</button>
    <button mat-raised-button [mat-dialog-close]="true">Cancel</button>
</mat-dialog-actions>
