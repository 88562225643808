import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class VoucherService {

    constructor(private http: HttpClient) { }

    public requestVoucher(voucherCode: string) {
        return this.http.get<any>(environment.APP_ENVIRONMENT + 'voucher/' + voucherCode);
    }
}
