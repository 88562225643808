import {AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {Station} from "../../../station";


@Component({
    selector: 'app-select-station-dialog',
    templateUrl: './select-station-dialog.component.html',
    styleUrls: ['./select-station-dialog.component.scss'],
})
export class SelectStationDialogComponent {
    constructor(public dialogRef: MatDialogRef<SelectStationDialogComponent>) {
    }

    stationChanged() {
        this.dialogRef.close();
    }
}
