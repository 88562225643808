import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {Configuration} from "../configuration";
import {Station} from "../station";
import {Shop} from "../shop";
import {MatDialog} from "@angular/material/dialog";
import {SelectStationDialogComponent} from "../pages/stations/select-station-dialog/select-station-dialog.component";
import {PopupService} from "./popup.service";
import {Terminal} from "../terminal";
import {ReceiptPrinter} from "../receipt-printer";

@Injectable({
    providedIn: 'root',
})
export class ConfigurationService {

    private configurationDictionary = {};
    private webshopConfigurations;

  constructor(private http: HttpClient, public dialog: MatDialog, private popupService: PopupService) {
      //Watch out for async calls! I'm not changing the entire logic if it works, but these configurations may not be loaded in time for when they are actually used.
    this.loadWebshopConfigurations().then();
  }

    public station: Station = new Station();
    public shop: Shop = new Shop();
    public defaultTerminal: Terminal = new Terminal();

    public defaultPrinter: ReceiptPrinter = new ReceiptPrinter();

    private stationLoaded: boolean = false;
    private terminalLoaded: boolean = false;
    private printerLoaded: boolean = false;

    public setStation(station_id) {
        localStorage.setItem('id_station', station_id);
        return this.refreshStationData(true);
    }

    public setTerminal(terminal_code) {
        localStorage.setItem('terminal_code', terminal_code);
        return this.refreshTerminalData(true);
    }

    public setPrinter(id_receipt_printer) {
        localStorage.setItem('id_receipt_printer', id_receipt_printer);
        return this.refreshPrinterData(true);
    }

    public setCountry(country_id) {
        localStorage.setItem('id_country', country_id);
    }

    public getCountry() {
        return localStorage.getItem('id_country');
    }




    public getConfig(name, defaultVal = null) {
        let result = this.configurationDictionary[name] ?? defaultVal;
        // This is an exception to allow setting 'false' config flags, as 0 will evaluate as such.
        // Any other value will be returned as string, so make sure to use casting if needed.
        if(result === '0')
            result = 0;
        return result;
    }

    public async refreshStationData(force: boolean = false) {
        if(force)
            this.stationLoaded = false;
        if(this.stationLoaded)
            return {success: true};
        let id_station = localStorage.getItem('id_station');
        while (!id_station) {
            await this.displaySelectStation();
            id_station = localStorage.getItem('id_station');
        }
        let response = await this.http.get<any>(environment.APP_ENVIRONMENT + environment.STATIONS_ENDPOINT + '/get-current/' + id_station).toPromise();
        if(!response.success)
            return {
                success: false,
                error: response.error ?? 'BACKEND ERROR'
            };
        this.station.cloneFrom(response.station);
        this.shop.cloneFrom(response.shop);
        this.stationLoaded = true;
        return {success: true};
    }

    public async refreshTerminalData(force: boolean = false) {
        if(force)
            this.terminalLoaded = false;
        if(this.terminalLoaded)
            return {success: true};
        let terminal_code = localStorage.getItem('terminal_code');
        if(!terminal_code)
            return {success: false, error: "Missing Terminal Code"};
        let response = await this.http.get<any>(environment.APP_ENVIRONMENT + environment.TERMINALS_ENDPOINT + '/view/' + terminal_code).toPromise();
        //TODO: we would probably want to return a success/error response here as well. I didn't want to change the backend code for this, so I left it as-is, but it's something to consider.
        this.defaultTerminal.cloneFrom(response);
        this.terminalLoaded = true;
    }

    public async refreshPrinterData(force: boolean = false) {
        if(force)
            this.printerLoaded = false;
        if(this.printerLoaded)
            return {success: true};
        let id_receipt_printer = localStorage.getItem('id_receipt_printer');
        if(!id_receipt_printer)
            return {success: false, error: "Missing Printer ID"};
        let response = await this.http.get<any>(environment.APP_ENVIRONMENT + environment.RECEIPT_PRINTERS_ENDPOINT + '/view/' + id_receipt_printer).toPromise();
        if(!response.success)
            return {
                success: false,
                error: response.error ?? 'BACKEND ERROR'
            };
        this.defaultPrinter.cloneFrom(response.printer);
        this.printerLoaded = true;
        return {success: true};
    }

    public async refreshConfiguration() {
        let response = await this.http.get<Configuration[]>(environment.APP_ENVIRONMENT + environment.CONFIGURATION_ENDPOINT + '/frontend').toPromise();
        this.configurationDictionary = {};
        for (const configuration of response) {
            this.configurationDictionary[configuration.name] = configuration.value;
        }
        await this.refreshTerminalData();
        await this.refreshPrinterData();
    }

  public async loadWebshopConfigurations() {
        let response = await this.http.get<any>(environment.APP_ENVIRONMENT + environment.WEBSHOP_CONFIGURATION_ENDPOINT).toPromise();
        if(response.success)
            this.webshopConfigurations = response.result;
        return response.success;
  }


    public getLogoUrl() {
        return this.webshopConfigurations && this.webshopConfigurations.logo ? this.webshopConfigurations.logo : "";
    }


    public displaySelectStation(disableClose: boolean = true) {
        return new Promise((resolve, reject) => {
            let dialogRef = this.dialog.open(SelectStationDialogComponent, {
                width: '250px',
                hasBackdrop: true,
                disableClose: disableClose,
            });
            dialogRef.afterClosed().subscribe(() => {
                    resolve();
                },
                error => {
                    reject(error);
                }
            );
        })
    }
}
