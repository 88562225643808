<form>
  <mat-form-field class="customer-search" appearance="fill">
    <mat-label>Search customer</mat-label>
    <input #cashierInput matInput placeholder="Customer search by: (scan) ID, first name, last name, e-mail.." type="text" [matAutocomplete]="customerSearchAutoComplete" (input)="customerSearch($event)" >
    <button  matSuffix mat-icon-button aria-label="Clear" (click)="resetAutoSuggestCustomers(cashierInput)" >
      <mat-icon>close</mat-icon>
    </button>
    <mat-autocomplete #customerSearchAutoComplete="matAutocomplete" class="customerSearch">
      <mat-option #option *ngFor="let customer of autoSuggestCustomers" [disabled]="optionStatus" (click)="selectedCustomerFromAutoSuggest(customer)">
        <button mat-button (mouseenter)="changeStatus(option, !optionStatus)" (mouseleave)="changeStatus(option, optionStatus)" color="accent" aria-label="Edit Customer" (click)="editCustomer($event, customer)"> <mat-icon> edit </mat-icon> </button>
        {{customer.ID}}: {{ customer.Email }} {{customer.FirstName + ' ' + customer.LastName}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>

</form>
