<h2>Add a discount</h2>
<mat-dialog-content>
    <div class="alert alert-danger" *ngIf="visibleErrors.invalidValue">Invalid discount value</div>
    <div class="alert alert-danger" *ngIf="visibleErrors.invalidPercentage">Invalid percentage</div>
    <div class="alert alert-danger" *ngIf="visibleErrors.exceededMax">Discount cannot be more than the subtotal</div>
    <div>
        <div>
            <label><input type="radio"
                          [(ngModel)]="discount.type"
                          [checked]="discount.type == 1"
                          [value]="1" />
                Fixed Amount</label><br>

        </div>

        <div>
            <label><input type="radio"
                          [(ngModel)]="discount.type"
                          [checked]="discount.type == 0"
                          [value]="0" />
                Percentage</label>
            <br>
        </div>
    </div>

    <p>
        <mat-form-field appearance="fill">
            <mat-label>Value</mat-label>
            <input (keydown.enter)="saveDiscount()" #discountValueField [(ngModel)]="discount.value" matInput type="number" step="0.01" min="0">
        </mat-form-field>
    </p>

</mat-dialog-content>
<mat-dialog-actions style="margin-bottom: 10px;">
  <button mat-raised-button color="primary" (click)="saveDiscount()">Add discount</button>
  <button mat-raised-button [mat-dialog-close]="true">Cancel</button>
</mat-dialog-actions>
