
import { Injectable } from '@angular/core';
import {PosErrorDialogComponent} from "../pages/pos-components/pos-error-dialog/pos-error-dialog.component";
import {MatDialog} from "@angular/material/dialog";

@Injectable({
  providedIn: 'root'
})
export class PopupService {

  constructor(public dialog: MatDialog) { }


  public showError(message: string, actions:any[] = []) {
    return new Promise((resolve) => {
      let dialogRef = this.dialog.open(PosErrorDialogComponent, {
        hasBackdrop: true,
        data: message,
        minHeight: 200,
        minWidth: 350
      });
      dialogRef.componentInstance.customActions = actions;
      dialogRef.afterClosed().subscribe(() => {
        resolve();
      })
    });
  }
}
