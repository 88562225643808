export enum DiscountType {
  Percentage = 0,
  FixedAmount = 1
}

export class DiscountDetails {
  type: DiscountType;
  value: number;

  public totalValue: number = 0;

  public updateValue(cartTotal: number) {
    if(this.type == DiscountType.FixedAmount)
      this.totalValue = this.value;
    else
      this.totalValue = Math.round(this.value * cartTotal) / 100;
  }
}
