import {Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {PosNewCustomerDialogComponent} from '../pos-new-customer-dialog/pos-new-customer-dialog.component';

/**
 * @title Dialog Animations
 */
@Component({
  selector: 'app-pos-new-customer',
  templateUrl: './pos-new-customer.component.html',
  styleUrls: ['./pos-new-customer.component.scss']
})
export class PosNewCustomerComponent implements OnInit {
  constructor(public signUpDialog: MatDialog) {
  }

  ngOnInit(): void {
  }

  openDialog(): void {
    this.signUpDialog.closeAll();
    this.signUpDialog.open(PosNewCustomerDialogComponent, {
      hasBackdrop: true,
      width: '430px',
      // height: '50%',
      data: {
        isNewCustomerDialog: true
      }
    });
  }
}
