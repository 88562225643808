<div class="totals_wrapper">
  <div class="totals_wrapper__actions"  *ngIf="!cartService.renderTemplatePDF">
      <app-pos-print-order-preview class="print-order-btns"></app-pos-print-order-preview>
  </div>
  <div class="totals_wrapper__actions__numbers">
    <div class="subtotal">
      <div class="subtotal__label">Subtotal</div>
      <div class="subtotal__value"> <app-pos-price-format [price]="cartService.subtotal"></app-pos-price-format></div>
    </div>
    <div class="subtotal" *ngFor="let vat of cartService.vatTotals">
      <div class="subtotal__label">
        <span>{{ vat.Name }}</span>
      </div>
      <div class="subtotal__value"><app-pos-price-format [price]="vat.Value"></app-pos-price-format></div>
    </div>
    <div *ngIf="cartService.vouchers.length > 0" class="subtotal">
      <div class="subtotal__label"><strong>Vouchers</strong></div>
      <div class="subtotal__value">
      </div>
    </div>
    <div class="subtotal" *ngFor="let voucher of cartService.vouchers; let voucherIndex = index">
      <div class="subtotal__label">
        <button *ngIf="!cartService.renderTemplatePDF" mat-icon-button (click)="deleteVoucher(voucherIndex)"><mat-icon>delete</mat-icon></button>
        <code>{{ voucher.code }}</code>  <span *ngIf="voucher.percentage > 0">( -{{ voucher.percentage * 100 }}% )</span>
      </div>
      <div class="subtotal__value"><span *ngIf="voucher.percentage > 0"></span>-<app-pos-price-format [price]="voucher.totalValue"></app-pos-price-format></div>
    </div>

    <div *ngIf="cartService.discount.value > 0" class="subtotal subtotal--discount">
      <div class="subtotal__label">
        <span>Discount</span>
        <button *ngIf="!cartService.renderTemplatePDF" mat-icon-button class="mat-warn" (click)="resetDiscount()"><mat-icon>delete</mat-icon></button>
      </div>
      <div class="subtotal__value">
        <span *ngIf="cartService.discount.type == 0">-{{ cartService.discount.value }}%, </span>-<app-pos-price-format [price]="cartService.discount.totalValue"></app-pos-price-format>
      </div>
    </div>

    <div class="total">
      <div class="total__label">Total</div>
      <div class="total__value"><app-pos-price-format [price]="cartService.total"></app-pos-price-format></div>
    </div>
  </div>


</div>
