<mat-card class="mb-3">
    <div class="row-app-header">
        <div class="row-app-header--col1">
            <img class="logo mouseOverLogo" src="{{ logoUrl }}" (click)="redirectToCart()" />
        </div>
        <div class="row-app-header--col2">
            <div class="row align-items-center">
                <div class="col-xs-12 col-md-4">
                    <mat-card-title style="white-space: nowrap">{{title}}</mat-card-title>
                    <mat-card-subtitle class="mb-0 app-header-subtitle hidden-sm-down">{{tagline}}</mat-card-subtitle>
                </div>
                <div class="col-xs-12 col-md-8 text-md-right header-info-elements hidden-sm-down">
                    <div class="text-secondary">{{ currentDate }} - <span class="ws-nowrap">{{ agentName }}</span></div>
                    <button mat-flat-button  (click)="configurationService.displaySelectStation(false)">{{configurationService.station.name}}</button>
                </div>
            </div>
        </div>
        <div class="row-app-header--col3">
            <button mat-button [matMenuTriggerFor]="menu">
                <mat-icon class="">menu</mat-icon>
                <span class="hidden-sm-down">Menu</span>
            </button>
          <mat-menu #menu="matMenu" xPosition="before" class="hammenu">
            <a mat-menu-item routerLinkActive="activeMenuLink" routerLink="/pos">POS</a>
            <a mat-menu-item routerLinkActive="activeMenuLink" routerLink="/reports">Reports</a>
            <a mat-menu-item class="border-bottom" routerLinkActive="activeMenuLink" routerLink="/settings">Settings</a>

            <a mat-menu-item *ngIf="isAdmin" routerLinkActive="activeMenuLink" routerLink="/agents">Agents</a>
            <a mat-menu-item *ngIf="isAdmin" routerLinkActive="activeMenuLink" routerLink="/configuration">Configuration</a>
            <a mat-menu-item *ngIf="isAdmin" routerLinkActive="activeMenuLink" routerLink="/printer-test">Printer Test</a>
            <a mat-menu-item *ngIf="isAdmin" routerLinkActive="activeMenuLink" routerLink="/receipt-printers">Receipt Printers</a>
            <a mat-menu-item *ngIf="isAdmin" routerLinkActive="activeMenuLink" routerLink="/shops">Shops</a>
            <a mat-menu-item *ngIf="isAdmin" routerLinkActive="activeMenuLink" routerLink="/stations">Stations</a>
            <a mat-menu-item *ngIf="isAdmin" routerLinkActive="activeMenuLink" routerLink="/terminals" class="border-bottom">Terminals</a>

            <a mat-menu-item (click)="logout()">Logout</a>
          </mat-menu>
        </div>
    </div>
  <div class="header-info-elements header-info-elements--mobile hidden-md-up">
    <div class="text-secondary">{{ currentDate }} - <span class="ws-nowrap">{{ agentName }}</span></div>
    <button mat-flat-button  (click)="configurationService.displaySelectStation(false)">{{configurationService.station.name}}</button>
  </div>
</mat-card>
