import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PosResetCartPopupComponent } from '../pages/pos-components/pos-reset-cart-popup/pos-reset-cart-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { CartProduct, VatTotal } from "../cart-product";
import { DiscountDetails, DiscountType } from "../../discount-details";
import { VoucherDetails } from "../../voucher-details";
import { ReceiptService } from "./receipt.service";
import { ToolsService } from './tools.service';

@Injectable({
    providedIn: 'root'
})
export class CartService {
    cartProductSource = new BehaviorSubject<any>([]);
    cartProduct = this.cartProductSource.asObservable();
    searchInputSelectedProductQuantity = 1;
    renderTemplatePDF = false;
    public discount: DiscountDetails = new DiscountDetails();
    cartProducts: CartProduct[] = [];
    vouchers: VoucherDetails[] = [];
    vatTotals: VatTotal[] = [];
    subtotal: number = 0;
    total: number = 0;

    public cartResetEvent: EventEmitter<void> = new EventEmitter<void>();
    public cartUpdateEvent: EventEmitter<void> = new EventEmitter<void>();
    public productListChangedEvent: EventEmitter<void> = new EventEmitter<void>();
    public discountUpdateEvent: EventEmitter<void> = new EventEmitter<void>();
    public voucherUpdateEvent: EventEmitter<void> = new EventEmitter<void>();

    constructor(private dialog: MatDialog, private receiptService: ReceiptService, private toolsService: ToolsService) {
        this.initCartUpdateEmitter();
        this.loadCartFromLocalStorage();
    }

    public initCartUpdateEmitter(): void {
        this.productListChangedEvent.subscribe(() => { this.cartUpdateEvent.emit(); });
        this.cartResetEvent.subscribe(() => { this.cartUpdateEvent.emit(); });
        this.productListChangedEvent.subscribe(() => { this.cartUpdateEvent.emit(); });
        this.discountUpdateEvent.subscribe(() => { this.cartUpdateEvent.emit(); });
        this.voucherUpdateEvent.subscribe(() => { this.cartUpdateEvent.emit(); });
    }

    private saveCartToLocalStorage() {
        localStorage.setItem('cartProducts', JSON.stringify(this.cartProducts));
        localStorage.setItem('cartDiscount', JSON.stringify({ type: this.discount.type, value: this.discount.value }));
        localStorage.setItem('cartVouchers', JSON.stringify(this.vouchers));
      localStorage.setItem('cartVatTotals', JSON.stringify(this.vatTotals));
    }

    private loadCartFromLocalStorage() {
        this.cartProducts.length = 0;
        this.vouchers.length = 0;
        this.discount.value = 0;
        this.discount.type = DiscountType.FixedAmount;
        const products = JSON.parse(localStorage.getItem('cartProducts') ?? '[]');
        for (const product of products) {
          const newProduct = new CartProduct(product.Image, product.Name, product.Currency, product.Reference, product.quantity, product.PriceWithTax, product.PriceWithoutTax);
          if(product.TaxInfo) {
            newProduct.TaxInfo.Name = product.TaxInfo.Name;
            newProduct.TaxInfo.Percentage = product.TaxInfo.Amount;
            newProduct.TaxInfo.Amount = product.TaxInfo.Amount;
          }
          this.cartProducts.push(newProduct);
        }

        const vouchers = JSON.parse(localStorage.getItem('cartVouchers') ?? '[]');
        for (const voucher of vouchers) {
            this.vouchers.push(new VoucherDetails(voucher.code, voucher.percentage, voucher.fixed_amount));
        }

    const vats = JSON.parse(localStorage.getItem('cartVatTotals') ?? '[]');
    for(const vat of vats) {
      this.vatTotals.push({Name: vat.Name, Value: vat.Value});
    }

    const discountString = localStorage.getItem('cartDiscount');
    if(discountString) {
      const discount = JSON.parse(discountString);
      this.discount.type = discount.type;
      this.discount.value = discount.value;
    }

        this.reindexProductList();
        this.refreshTotals();
        this.productListChangedEvent.emit();
    }

  public refreshTotals() {
    let tempSubTotal = 0;
    let tempVatTotals = {};
    for (const product of this.cartProducts) {
      product.totalprice = this.toolsService.formatNumber(product.PriceWithTax * product.quantity);
      product.calculatedVat = this.toolsService.formatNumber(product.TaxInfo.Amount * product.quantity);
      tempVatTotals[product.TaxInfo.Name] = (tempVatTotals[product.TaxInfo.Name] ?? 0) + product.calculatedVat;
      tempSubTotal += product.totalprice;
    }

    this.vatTotals.length = 0;
    for (const vatName in tempVatTotals) {
      this.vatTotals.push({Name: vatName, Value: tempVatTotals[vatName]});
    }

    let vouchersTotal = 0;
    for(const voucher of this.vouchers) {
      voucher.updateValue(tempSubTotal);
      vouchersTotal += voucher.totalValue;
    }

        this.discount.updateValue(tempSubTotal);

        let tempTotal = tempSubTotal - vouchersTotal - this.discount.totalValue;
        if (tempTotal < 0) {
            this.discount.value = 0;
            this.vouchers.length = 0;
            this.saveCartToLocalStorage();
            this.refreshTotals();
        }
        else {
            this.subtotal = tempSubTotal;
            this.total = tempTotal;
            this.saveCartToLocalStorage();
        }
    }

    public addProductToCart(product) {
        if (!product.Reference)
            return;

    let productInCart = this.cartProducts.find(p => p.Reference === product.Reference);
    if(productInCart)
      productInCart.quantity += this.searchInputSelectedProductQuantity;
    else {
      product.quantity = this.searchInputSelectedProductQuantity;
      product.PriceWithTax = this.toolsService.formatNumber(product.PriceWithTax);
      this.cartProducts.push(product);
    }

        this.reindexProductList();
        this.refreshTotals();
        this.resetSearchVariables();
        this.productListChangedEvent.emit();
    }

    public removeProductFromCart(product: CartProduct) {
        this.cartProducts.splice(this.cartProducts.indexOf(product), 1);
        this.reindexProductList();
        this.refreshTotals();
        this.productListChangedEvent.emit();
    }

    private reindexProductList(): void {
        this.cartProducts.forEach((cartProduct, index) => {
            this.cartProducts[index].position = index + 1;
        });
    }

    private resetSearchVariables() {
        this.searchInputSelectedProductQuantity = 1;
        this.cartProductSource.next([]);
    }

    public emptyCart() {
        this.cartProducts.length = 0;
        this.vouchers.length = 0;
        this.discount.value = 0;
        this.resetSearchVariables();
        this.refreshTotals();
        this.cartResetEvent.emit();
        this.productListChangedEvent.emit();
    }

    public generateQuote(displayResetCartPopup = false) {
        if (displayResetCartPopup) {
            this.dialog.open(PosResetCartPopupComponent, {
                width: '250px',
                maxWidth: '250px',
                hasBackdrop: true,
                disableClose: true,
                data: {
                    resetCallback: () => {
                        this.emptyCart();
                    }
                }
            });
        }

        window.open(window.location.origin + '/order-quote-preview');
    }

    public printReceipt() {
        this.receiptService.PrintReceipt(this.cartProducts, this.subtotal, this.vatTotals, this.vouchers, this.discount.totalValue, this.total)
    }

    public existsProductsInCart() {
        return this.cartProducts.length > 0;
    }
}

