
<div class="row mb-3">
  <div class="col-12 col-sm-12">
    <h2  *ngIf="!this.cartService.renderTemplatePDF">This is your shopping cart.</h2>
    <h2  *ngIf="this.cartService.renderTemplatePDF">Order Details.</h2>
  </div>
</div>

<!--// DeComment for filtering option -->
<!--<mat-form-field appearance="standard">-->
<!--  <mat-label>Filter</mat-label>-->
<!--  <input matInput (keyup)="applyFilter($event)" placeholder="Ex. ium" #input>-->
<!--</mat-form-field>-->

<table mat-table #cartView [dataSource]="productDataSource" class="MB30">

  <!-- Position Column -->
  <ng-container matColumnDef="position">
    <th mat-header-cell *matHeaderCellDef style="text-align: center"> No. </th>
    <td mat-cell *matCellDef="let element" style="text-align: center"> {{element.position}} </td>
  </ng-container>

  <!-- Name Column -->
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef style="text-align: left"> Product name </th>
    <td mat-cell *matCellDef="let element" style="text-align: left">
      <div class="img_and_name">
        <img mat-card-sm-image src="{{element.Image}}" onerror="this.src='/assets/placeholder.png';">
        <span>{{element.Name.en}}</span>
      </div>
    </td>

  </ng-container>

  <!--  quantity Column-->
  <ng-container matColumnDef="quantity">
    <th mat-header-cell *matHeaderCellDef style="text-align: center" > Quantity </th>
    <td mat-cell *matCellDef="let element" ng-show="chkselct" style="text-align: center">
      <div *ngIf="!cartService.renderTemplatePDF" class="qty_change_value">
        <button class="qty_change qty_change--minus" (click)="updateQuantity(element, $event, 'down')"><mat-icon>remove</mat-icon></button>
        <input style="width:60px; height: 30px" type="text" inputmode="numeric" pattern="[0-9]" cam-variable-name="quantity" min="1"
         cam-variable-type="Integer" value="{{element.quantity}}" (input)="updateQuantity(element, $event)"/>
        <button class="qty_change qty_change--plus" (click)="updateQuantity(element, $event, 'up')"><mat-icon>add</mat-icon></button>
      </div>
      <div *ngIf="cartService.renderTemplatePDF">
        <span> {{element.quantity}}</span>
      </div>
    </td>
  </ng-container>

  <!--   Price Column -->
  <ng-container matColumnDef="price">
    <th mat-header-cell *matHeaderCellDef style="text-align: center"> Price </th>
    <td mat-cell *matCellDef="let element" style="text-align: center">
      <app-pos-price-format [price]="element.PriceWithTax" [currencyIsoCode]="element.Currency"></app-pos-price-format>
    </td>
  </ng-container>

  <!--   VAT Type Column -->
  <ng-container matColumnDef="vatType">
    <th mat-header-cell *matHeaderCellDef style="text-align: center"> VAT Type </th>
    <td mat-cell *matCellDef="let element" style="text-align: center">
      <span>{{element.TaxInfo.Name}}</span>
    </td>
  </ng-container>

  <!--   VAT Value -->
  <ng-container matColumnDef="vatValue">
    <th mat-header-cell *matHeaderCellDef style="text-align: center"> VAT Value </th>
    <td mat-cell *matCellDef="let element" style="text-align: center">
      <app-pos-price-format [price]="element.TaxInfo.Amount" [currencyIsoCode]="element.Currency"></app-pos-price-format>
    </td>
  </ng-container>

  <!-- Total Price Column -->
  <ng-container matColumnDef="total">
    <th mat-header-cell *matHeaderCellDef style="text-align: center"> Total Price </th>
    <td mat-cell *matCellDef="let element" style="text-align: center">
      <app-pos-price-format [price]="element.totalprice" [currencyIsoCode]="element.Currency"></app-pos-price-format>
    </td>
  </ng-container>

  <ng-container matColumnDef="delete" *ngIf="!cartService.renderTemplatePDF">
    <th mat-header-cell *matHeaderCellDef style="text-align: center"> Delete </th>
    <td mat-cell *matCellDef="let element" style="text-align: center">
      <button mat-icon-button color="warn" aria-label="Delete Row" (click)="deleteProductRow(element)">
        <mat-icon>delete</mat-icon>
      </button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let element; columns: displayedColumns;"></tr>

  <!-- Row shown when there is no matching data. -->
  <!-- // DeComment for filtering option -->
  <!--  <tr class="mat-row" *matNoDataRow>-->
  <!--    <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>-->
  <!--  </tr>-->
</table>
