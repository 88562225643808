import {AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {Agent} from "../../../agent";
import {CustomerService} from "../../../services/customer.service";
import {PopupService} from "../../../services/popup.service";
import {AuthenticationService} from "../../../services/authentication.service";


@Component({
  selector: 'app-agent-dialog',
  templateUrl: './agent-dialog.component.html',
  styleUrls: ['./agent-dialog.component.scss'],
})
export class AgentDialogComponent implements OnInit, AfterViewInit {
  @ViewChild('agentUsernameField') agentUsernameField: ElementRef;

  public visibleErrors = {
    emptyUsername: false,
    emptyFirstName: false,
    emptyLastName: false,
    emptyShop: false,
    emptyPassword: false,

    anyError: function() {
      return this.emptyUsername
      || this.emptyFirstName
      || this.emptyLastName
      || this.emptyShop
      || this.emptyPassword
    }
  }

  agent: Agent = new Agent();
  shops: [] = [];

  public newPassword: string = '';

  constructor(public dialogRef: MatDialogRef<AgentDialogComponent>, @Inject(MAT_DIALOG_DATA) public originalAgent: Agent, private http: HttpClient, public customerService: CustomerService, private popupService: PopupService, public authenticationService: AuthenticationService) {
    this.agent.cloneFrom(originalAgent);
    this.selectedShop = {id_shop: this.originalAgent.id_shop, shop_name: this.originalAgent.shop_name}
    this.loadShops();
  }

  ngOnInit(): void {
  }


  public saveAgent() {

    this.visibleErrors.emptyUsername = !this.agent.username;
    this.visibleErrors.emptyFirstName = !this.agent.firstname;
    this.visibleErrors.emptyLastName = !this.agent.lastname;
    this.visibleErrors.emptyShop = !this.agent.id_shop;
    this.visibleErrors.emptyPassword = !this.agent.id_agent && !this.newPassword;
    if(this.visibleErrors.anyError())
      return;

    this.agent['password'] = this.newPassword;
    this.http.post<any>(environment.APP_ENVIRONMENT + environment.AGENTS_ENDPOINT + "/save", this.agent).subscribe(response => {
        if (!response.success)
          this.popupService.showError(response.error);
        else {
          this.originalAgent.cloneFrom(this.agent);
          this.dialogRef.close();
        }
      },
      (err) => {
        console.dir(err);
      });
  }

  public loadShops() {
    this.http.get<any>(environment.APP_ENVIRONMENT + environment.SHOPS_ENDPOINT + "/list").subscribe(response => {
        if (!response.success)
          this.popupService.showError(response.error);
        else {
          this.shops = response.shops;
        }
      },
      (err) => {
        console.dir(err);
      });
  }


  selectedShop: any;

  updateShop(selectedShop: any) {
    this.agent.id_shop = selectedShop.id_shop;
    this.agent.shop_name = selectedShop.shop_name;
  }


  shopCompareFn(shop1: any, shop2: any) {
    return shop1 && shop2 && shop1.id_shop === shop2.id_shop;
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.agentUsernameField.nativeElement.focus();
    });
  }

}
