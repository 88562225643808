export class Station {
    id_station: number = 0;
    name: string = '';
    code: string = '';
    id_shop: string = '';
    shop_name: string = '';

    public cloneFrom(oldStation: Station) {
        this.id_station = oldStation.id_station;
        this.name = oldStation.name;
        this.code = oldStation.code;
        this.id_shop = oldStation.id_shop;
        this.shop_name = oldStation.shop_name;
    }
}