import { AfterContentChecked, AfterContentInit, AfterViewInit, Component, OnInit } from '@angular/core';
import { CartService } from '../../../services/cart.service';
import { Customer } from '../../../customer';
import { CustomerService } from '../../../services/customer.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { OrderService } from 'src/app/services/order.service';

@Component({
    selector: 'app-pos-print-order-quote',
    templateUrl: './pos-print-order-quote.component.html',
    styleUrls: ['./pos-print-order-quote.component.scss']
})
export class PosPrintOrderQuoteComponent {
    orderReference: string;
    customer: Customer;
    agent;
    currentDate = new Date().toLocaleString();
    displayedColumns: string[] = ['agent', 'customer'];

    printConditions = {
        customerLoaded: false
    }

    constructor(
        private CartServiceInstance: CartService,
        public customerService: CustomerService,
        private authenticationService: AuthenticationService,
        private orderService: OrderService
    ) {
        this.CartServiceInstance.renderTemplatePDF = true;
        this.agent = this.authenticationService.agent;
        this.orderReference = this.orderService.getOrderReference();

        this.printConditions.customerLoaded = this.customerService.finishedLoading;
        this.customerService.customerChangedEvent.subscribe(customer => {
            this.customer = customer;
            this.printConditions.customerLoaded = true;
        });
        this.awaitPrintConditions();
    }


    public storeName = "Osplash";

    private awaitPrintConditions() {
        let ok = true;
        for(const condition in this.printConditions) {
            if(!this.printConditions[condition]) {
                ok = false;
                break;
            }
        }
        if(!ok)
            setTimeout(() => this.awaitPrintConditions(), 100);
        else {
            setTimeout(() => {
                window.print();
                window.close();
            }, 100);
        }
    }

}
