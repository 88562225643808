import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfigurationService } from 'src/app/services/configuration.service';
import {Router} from "@angular/router";

export interface LoginDialogData {
  user: string;
  password: string;
  loginCallback(username: string, password: string): Promise<any>;
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {

  // we keep this implementation for now, as we don't
  // really have predefined messages to put in HTML.
  // Login errors come from the backend.
  error_message = '';
  logoUrl: string = '';

  constructor(
    public dialogRef: MatDialogRef<LoginComponent>,
    @Inject(MAT_DIALOG_DATA) public data: LoginDialogData,
    private configurationService: ConfigurationService,
    private router: Router
  ) {
    // watch out: async call. The logo may not actually be loaded in time for other usages.
    this.loadLogo();
  }

  login(): void {
    this.error_message = '';

    if (this.data.user === '' || this.data.password === '') {
      this.error_message = 'User and password are required!';
    }

    if (this.error_message === '') {
      this.data.loginCallback(this.data.user, this.data.password).then((response) => {
        if (!response.result) {
          this.error_message = response.message;
        } else {
          this.dialogRef.close(response);
        }
      }, (requestError) => {
        this.error_message = requestError.error.message;
      })
    }
  }

  loadLogo() {
    this.logoUrl = this.configurationService.getLogoUrl();
    if (!this.logoUrl) {
      this.configurationService.loadWebshopConfigurations().then(() => {
        this.logoUrl = this.configurationService.getLogoUrl(); 
      })
      .catch((err) => {
          console.dir(err);
      });
    }
  }

  ngOnInit(): void {}
}
