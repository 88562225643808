<app-pos-header></app-pos-header>

<mat-card>
  <mat-card-header>
    <mat-card-title>Receipt Printers Management</mat-card-title>
    <mat-card-subtitle>Total rows: {{totalResults}}. Total pages: {{totalPages}}</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <table mat-table [dataSource]="rows" class="">


      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef> ID </th>
        <td mat-cell *matCellDef="let row"> {{row.id_receipt_printer}} </td>
      </ng-container>

      <ng-container matColumnDef="url">
        <th mat-header-cell *matHeaderCellDef> URL </th>
        <td mat-cell *matCellDef="let row"> {{row.url}} </td>
      </ng-container>

      <ng-container matColumnDef="logical_name">
        <th mat-header-cell *matHeaderCellDef> Logical Name </th>
        <td mat-cell *matCellDef="let row"> {{row.logical_name}} </td>
      </ng-container>

      <ng-container matColumnDef="paper_size">
        <th mat-header-cell *matHeaderCellDef> Paper Size </th>
        <td mat-cell *matCellDef="let row"> {{row.paper_size}} </td>
      </ng-container>

      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef> Description </th>
        <td mat-cell *matCellDef="let row"> {{row.description}} </td>
      </ng-container>

      <ng-container matColumnDef="shop">
        <th mat-header-cell *matHeaderCellDef> Shop </th>
        <td mat-cell *matCellDef="let row"> {{row.shop_name}} </td>
      </ng-container>


      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="crud-actions-column text-right pr-40"> Actions </th>
        <td mat-cell *matCellDef="let row" class="crud-actions-column">
          <button mat-button color="secondary" (click)="editPrinter(row)"><mat-icon class="edit-icon">edit</mat-icon> Edit</button>
          <button mat-button color="secondary" (click)="deletePrinter(row)"><mat-icon class="delete-icon">delete</mat-icon> Delete</button>
        </td>
      </ng-container>


      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </mat-card-content>
  <mat-card-actions align="end">
    <button mat-button [color]="link.active ? 'primary' : null" *ngFor="let link of paginationLinks" [disabled]="!link.url" (click)="changePage(link.url)" [innerHtml]="link.label"></button>
  </mat-card-actions>
</mat-card>

<button mat-fab color="primary" class="fab-main" aria-label="Add" (click)=" createPrinter()">
  <mat-icon>add</mat-icon>
</button>



