import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  notFilteredOrders;
  selectedFilterOption;
  filterByText;
  selectedReportType;
  selectedReportPeriod;

  constructor(private http: HttpClient) {
    this.initData();
  }

  getProductsApiEndpoint(id: string) {
    return environment.APP_ENVIRONMENT + environment.REPORTS_PRODUCTS_ENDPOINT + '/' + id;
  }

  getProducts(id: string) {
    return this.http.get<any>(this.getProductsApiEndpoint(id));
  }

  getReportsApiEndpoint(field: string, value: string, page: number = 1) {
    return environment.APP_ENVIRONMENT + environment.REPORTS_ENDPOINT + '/' + field + '/' + value + '/' + page;
  }

  getReports(field: string, value: string, page: number) {
    return this.http.get<any>(this.getReportsApiEndpoint(field, value, page));
  }

  getEndOfDayReport(reportData) {
    return this.http.post<any>(environment.APP_ENVIRONMENT + environment.END_OF_DAY_REPORT, JSON.stringify(reportData));
  }

  initData() {
    this.notFilteredOrders = [];
    this.selectedFilterOption = '';
    this.filterByText = '';
    this.selectedReportType = '';
    this.selectedReportPeriod = '';
    this.loadSelectedFilterOptionFromLocalStorage();
    this.loadFilterByTextFromLocalStorage();
    this.loadSelectedReportTypeFromLocalStorage();
    this.loadSelectedReportPeriodInLocalStorage();
  }

  emptyReportsData() {
    this.deleteFilterByTextFromLocalStorage();
    this.deleteSelectedFilterOptionFromLocalStorage();
    this.deleteSelectedReportTypeFromLocalStorage();
    this.initData();
  }

  updateSelectedFilterOptionInLocalStorage(selectedFilterOption: string) {
    window.localStorage.setItem('selectedFilterOption', selectedFilterOption);
  }

  loadSelectedFilterOptionFromLocalStorage() {
    this.selectedFilterOption = window.localStorage.getItem('selectedFilterOption');
  }

  deleteSelectedFilterOptionFromLocalStorage() {
    window.localStorage.removeItem('selectedFilterOption');
  }

  updateFilterByTextInLocalStorage(filterByText: string) {
    window.localStorage.setItem('filterByText', filterByText);
  }

  loadFilterByTextFromLocalStorage() {
    this.filterByText = window.localStorage.getItem('filterByText');
  }

  deleteFilterByTextFromLocalStorage() {
    window.localStorage.removeItem('filterByText');
  }

  updateSelectedReportTypeInLocalStorage(selectedReportType: string) {
    window.localStorage.setItem('selectedReportType', selectedReportType);
  }

  loadSelectedReportTypeFromLocalStorage() {
    this.selectedReportType = window.localStorage.getItem('selectedReportType');
  }

  deleteSelectedReportTypeFromLocalStorage() {
    window.localStorage.removeItem('selectedReportType');
  }

  updateSelectedReportPeriodInLocalStorage(selectedReportPeriod: string) {
    window.localStorage.setItem('selectedReportPeriod', selectedReportPeriod);
  }

  loadSelectedReportPeriodInLocalStorage() {
    this.selectedReportPeriod = window.localStorage.getItem('selectedReportPeriod');
  }

  deleteSelectedReportPeriodInLocalStorage() {
    window.localStorage.removeItem('selectedReportPeriod');
  }
}
