import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { adyen_payment_status, mollie_payment_status } from 'src/environments/environment.prod';
import { environment } from './../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class PaymentService {
    paymentData;
    paymentType: string = "";
    private totalToPay: number;
    private totalLeftToPay: number;
    private paidWithSuccess: boolean;

    MOLLIE_SERVICE_PAYMENT = "mollie";
    ADYEN_SERVICE_PAYMENT = "adyen";

    PAY_WITH_CARD_PAYMENT = "card";
    PAY_WITH_CASH_PAYMENT = "cash";
    SPLIT_PAYMENT = "split";

    constructor(private http: HttpClient) {
        this.initPaymentData();
    }

    public initPaymentData() {
        this.paymentType = "";
        this.setAsPartialPayment(false);
        this.setTotalToPay(0);
        this.setTotalLeftToPay(0);
        this.setIsPaidWithSuccess(false);
    }

    public setAsPartialPayment(isPartialPayment: boolean): void {
        localStorage.setItem('partialPayment', JSON.stringify(isPartialPayment));
    }

    public isPartialPayment(): boolean {
        const partialPayment = localStorage.getItem('partialPayment');
        return JSON.parse(partialPayment);
    }

    public setTotalToPay(totalToPay: number): void {
        this.totalToPay = totalToPay;
    }

    public getTotalToPay(): number {
        return this.totalToPay;
    }

    public setTotalLeftToPay(totalLeftToPay: number): void {
        this.totalLeftToPay = totalLeftToPay;
    }

    public getTotalLeftToPay(): number {
        return this.totalLeftToPay;
    }

    public setIsPaidWithSuccess(paidWithSuccess: boolean): void {
        this.paidWithSuccess = paidWithSuccess;
    }

    public isPaidWithSuccess(): boolean {
        return this.paidWithSuccess;
    }

    public getStationTerminals(stationCode: string, terminalType: string) {
        let stationTerminalsApiEndpoint = environment.APP_ENVIRONMENT + environment.STATION_ENDPOINT + stationCode + '/' + terminalType;
        return this.http.get<any>(stationTerminalsApiEndpoint);
    }

    public createAdyenPaymentTransaction(orderReference: string, amountToPay: number, terminalCode: string) {
        let apiEndpoint = environment.APP_ENVIRONMENT + environment.STATION_ENDPOINT + 'pay/';
        let ApiParameters = terminalCode + '/' + orderReference + '/' + amountToPay;
        return this.http.get<any>(apiEndpoint + ApiParameters);
    }

    public getAdyenPaymentInformation(infoUrl: string) {
        let paymentInformationApiEndpoint = environment.APP_ENVIRONMENT + infoUrl;
        return this.http.get<any>(paymentInformationApiEndpoint);
    }

    public createPayment(paymentData) {
        let paymentSaveEndpoint = environment.APP_ENVIRONMENT + environment.PAYMENT_SAVE_ENDPPINT;
        return this.http.post<any>(paymentSaveEndpoint, paymentData);
    }

    public createMolliePayment(payment) {
        let molliePaymentSaveEndpoint = environment.APP_ENVIRONMENT + environment.MOLLIE_PAYMENT_SAVE_ENDPOINT;
        return this.http.post<any>(molliePaymentSaveEndpoint, payment);
    }

    public cancelMolliePayment(transactionID: string) {
        let molliePaymentCancelEndpoint = environment.APP_ENVIRONMENT + environment.MOLLIE_PAYMENT_CANCEL_ENDPOINT + transactionID;
        return this.http.delete<any>(molliePaymentCancelEndpoint);
    }

    public getPayment(transactionID: string) {
        const paymentEndpoint = environment.APP_ENVIRONMENT + environment.PAYMENT_GET_ENDPOINT + transactionID;
        return this.http.get<any>(paymentEndpoint);
    }

    public molliePaymentFailed(payment_status: string): boolean {
        return this.isMolliePaymentCanceled(payment_status) ||
            this.isMolliePaymentExpired(payment_status) ||
            this.isMolliePaymentFailed(payment_status);
    }

    public isMolliePaymentFailed(payment_status: string): boolean {
        return payment_status == mollie_payment_status.FAILED;
    }

    public isMolliePaymentExpired(payment_status: string): boolean {
        return payment_status == mollie_payment_status.EXPIRED;
    }

    public isMolliePaymentCanceled(payment_status: string): boolean {
        return payment_status == mollie_payment_status.CANCELED;
    }

    public isMolliePaymentPaid(payment_status: string): boolean {
        return payment_status == mollie_payment_status.PAID;
    }

    public isAdyenPaymentComplete(payment_status: string): boolean {
        return payment_status == adyen_payment_status.PAYMENT_COMPLETE;
    }

    public isMollieServiceProviderActive(): boolean {
        return environment.PAYMENT_SERVICE_PROVIDER == this.MOLLIE_SERVICE_PAYMENT;
    }

    public isAdyenServiceProviderActive(): boolean {
        return environment.PAYMENT_SERVICE_PROVIDER == this.ADYEN_SERVICE_PAYMENT;
    }

    public isCardPaymentOptionSelected(selectedPaymentOption: string): boolean {
        return selectedPaymentOption == this.PAY_WITH_CARD_PAYMENT;
    }

    public isCashPaymentOptionSelected(selectedPaymentOption: string): boolean {
        return selectedPaymentOption == this.PAY_WITH_CASH_PAYMENT;
    }
}
