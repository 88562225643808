import {AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {AuthenticationService} from "../../services/authentication.service";
import {Agent} from "../../agent";
import { environment } from 'src/environments/environment';
import {Router} from "@angular/router";
import {CustomerService} from "../../services/customer.service";
import {ConfigurationService} from "../../services/configuration.service";


@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit, AfterViewInit {
  @ViewChild('firstNameField') firstNameField: ElementRef;

  public visibleErrors = {
    emptyFirstName: false,
    emptyLastName: false,
    emptyShop: false,

    anyError: function() {
      return this.emptyFirstName
          || this.emptyLastName
          || this.emptyShop
    }
  }
  agent: Agent = new Agent();
  newPassword: string = '';
  terminals: [] = [];
  countries: [] = [];

  printers: [] = [];

  constructor(private http: HttpClient, private authService: AuthenticationService, private router: Router, private customerService: CustomerService, private configurationService: ConfigurationService) {

  }

  ngOnInit(): void {
    this.http.post<any>(environment.APP_ENVIRONMENT+this.authService.endpointGetLogged, {}).subscribe(response => {
        if(!response.result)
          console.dir(response.error);
        else {
          this.agent.cloneFrom(response.agent);
          this.selectedTerminal = {id_terminal: this.configurationService.defaultTerminal.id_terminal}
          this.selectedCountry = {id_country: this.configurationService.getCountry()}
          this.selectedPrinter = {id_receipt_printer: this.configurationService.defaultPrinter.id_receipt_printer}
          this.loadTerminals();
          this.loadPrinters();
          this.customerService.getCountries()
            .subscribe(response => {
              const responseContent: any = response;
              this.countries = responseContent.result.items;
            });
        }
      },
      (err) => {
        console.dir(err);
      });


  }


  public saveSettings() {

    this.visibleErrors.emptyFirstName = !this.agent.firstname;
    this.visibleErrors.emptyLastName = !this.agent.lastname;
    this.visibleErrors.emptyShop = !this.agent.id_shop;
    if(this.visibleErrors.anyError())
      return;


    this.http.post<any>(environment.APP_ENVIRONMENT+environment.USER_ENDPOINT+"/update-profile", this.agent).subscribe(response => {
        if(!response.success)
          console.dir(response.error);
        else {
          this.authService.updateAgentData().then(() => {
            if(this.newPassword)
              this.updatePassword().then(() => {
                this.router.navigate(['/pos']);
              });
            else
              this.router.navigate(['/pos']);
          });
        }
      },
      (err) => {
        console.dir(err);
      });
  }

  updatePassword(): Promise<any>
  {
    return new Promise((resolve, reject) => {
      this.http.post<any>(environment.APP_ENVIRONMENT+environment.USER_ENDPOINT+"/change-password", {password: this.newPassword}).subscribe(response => {
          if(!response.success) {
            console.dir(response.error);
            reject();
          }
          else
            resolve();
        },
        (err) => {
          console.dir(err);
          reject();
        });
    })

  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.firstNameField.nativeElement.focus();
    });
  }

  public loadTerminals() {
    this.http.get<any>(environment.APP_ENVIRONMENT + "station/" + this.configurationService.station.code).subscribe(response => {
          this.terminals = response;
      },
      (err) => {
        console.dir(err);
      });
  }

  public loadPrinters() {
    this.http.get<any>(environment.APP_ENVIRONMENT + environment.RECEIPT_PRINTERS_ENDPOINT+"/list").subscribe(response => {
          this.printers = response.printers;
        },
        (err) => {
          console.dir(err);
        });
  }

  selectedTerminal: any;

  selectedPrinter: any;

  updateTerminal(selectedTerminal: any) {
    this.configurationService.setTerminal(selectedTerminal.code);
  }

  updatePrinter(selectedTerminal: any) {
    this.configurationService.setPrinter(selectedTerminal.id_receipt_printer);
  }

  updateCountry(selectedCountry: any) {
    this.configurationService.setCountry(selectedCountry.id_country);
  }

  terminalCompareFn(terminal1: any, terminal2: any) {
    return terminal1 && terminal2 && terminal1.id_terminal === terminal2.id_terminal;
  }

  printerCompareFn(printer1: any, printer2: any) {
    return printer1 && printer2 && printer1.id_receipt_printer === printer2.id_receipt_printer;
  }

  selectedCountry: any;
  countryCompareFn(country1: any, country2: any) {
    return country1 && country2 && country1.id_country == country2.id_country;
  }

}
