import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PaymentService } from 'src/app/services/payment.service';
import { repeat, delay } from 'rxjs/operators';
import { environment, payment_method_type } from 'src/environments/environment';
import { OrderService } from 'src/app/services/order.service';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { CustomerService } from 'src/app/services/customer.service';
import { AuthenticationService } from 'src/app/services/authentication.service';

export interface DialogData {
    orderReference: string;
    amountToPay: number;
}

@Component({
    selector: 'app-pos-card-pay-dialog',
    templateUrl: './pos-card-pay-dialog.component.html',
    styleUrls: ['./pos-card-pay-dialog.component.scss']
})
export class PosCardPayDialogComponent implements OnInit {

    terminals: any = [];
    selectedTerminal;
    isTerminalSelected = false;
    isPaymentProcessComplete = false;
    paymentFailed = false;
    totalFailedAttempts = 0;
    paymentSubscription;
    paymentStatus;
    transactionID: string;
    paymentCreatedWithSuccess = false;

    constructor(
        public dialogRef: MatDialogRef<PosCardPayDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        private paymentService: PaymentService,
        private OrderServiceInstance: OrderService,
        private ConfigurationServiceInstance: ConfigurationService,
        private CustomerServiceInstance: CustomerService,
        private AuthenticationServiceInstance: AuthenticationService
    ) {
        if (!this.paymentService.isPartialPayment()) {
            this.paymentService.paymentType = payment_method_type.CARD_PAYMENT_TYPE;
        }
        this.selectedTerminal = this.ConfigurationServiceInstance.defaultTerminal;
    }

    ngOnInit(): void {
        this.loadTerminals().then(() => {
            if (this.onlyOneTerminalAvailable()) {
                this.updateSelectedTerminal(this.terminals[0]);
            };

            this.initPaymentProcess();
        });
    }

    async loadTerminals(): Promise<void> {
        let stationCode = this.ConfigurationServiceInstance.station.code;
        this.terminals = await this.paymentService.getStationTerminals(stationCode, environment.PAYMENT_SERVICE_PROVIDER).toPromise();
    }

    onlyOneTerminalAvailable(): boolean {
        return this.terminals.length == 1;
    }

    initPaymentProcess(): void {
        if (this.canStartMolliePaymentProcess()) {
            this.processMolliePayment();
        }

        if (this.canStartAdyenPaymentProcess()) {
            this.processAdyenPayment();
        }
    }

    canStartMolliePaymentProcess(): boolean {
        return this.paymentService.isMollieServiceProviderActive() && this.isTerminalSelected;
    }

    processMolliePayment(): void {
        this.processCreateMolliePayment().then((molliePayment) => {
            if (molliePayment.success) {
                this.transactionID = molliePayment.payment.id;
                this.paymentCreatedWithSuccess = true;
                this.processMolliePaymentInformation();
            }
        });
    }

    async processCreateMolliePayment(): Promise<any> {
        let molliePaymentData = this.getMolliePaymentData();
        return await this.paymentService.createMolliePayment(molliePaymentData).toPromise();
    }

    getMolliePaymentData(): object {
        let customer = this.CustomerServiceInstance.linkedCustomer;
        let molliePaymentData = {
            currency: customer.CurrencyIso,
            amount: this.data.amountToPay,
            customer_code: customer.CustomerCode,
            customer_email: customer.Email,
            terminal_id: this.selectedTerminal.code,
            order_reference: this.data.orderReference,
            agent: this.AuthenticationServiceInstance.agent.username,
            shop: this.ConfigurationServiceInstance.shop.name,
            station: this.ConfigurationServiceInstance.station.name
        };

        return molliePaymentData;
    }

    processMolliePaymentInformation(): object {
        this.paymentSubscription = this.paymentService.getPayment(this.transactionID)
            .pipe(delay(environment.NEXT_REQUEST_ATTEMPT_DELAY), repeat())
            .subscribe(payment => {
                this.setPaymentStatus(payment.status);
                this.processMolliePaymentValidation(payment.status);

                if (this.isPaymentProcessComplete || this.paymentFailed) {
                    this.unsubscribePaymentSubscription();
                }

                return payment;
            });

        return [];
    }

    processMolliePaymentValidation(payment_status: string): void {
        this.isPaymentProcessComplete = this.paymentService.isMolliePaymentPaid(payment_status);
        this.paymentFailed = this.paymentService.molliePaymentFailed(payment_status);
    }

    unsubscribePaymentSubscription(): void {
        this.paymentSubscription.unsubscribe();
    }

    canStartAdyenPaymentProcess(): boolean {
        return this.paymentService.isAdyenServiceProviderActive() && this.isTerminalSelected;
    }

    async processAdyenPayment(): Promise<void> {
        this.processCreateAdyenPayment()
            .then((paymentTransactionData) => {
                this.processAdyenPaymentInformation(paymentTransactionData.info_url);
            });
    }

    async processCreateAdyenPayment(): Promise<any> {
        const orderReference = this.data.orderReference;
        const amountToPay = this.data.amountToPay;
        const terminalCode = this.selectedTerminal.code;

        return await this.paymentService.createAdyenPaymentTransaction(orderReference, amountToPay, terminalCode).toPromise();
    }

    processAdyenPaymentInformation(infoUrl: string): object {
        this.paymentSubscription = this.paymentService.getAdyenPaymentInformation(infoUrl)
            .pipe(delay(environment.NEXT_REQUEST_ATTEMPT_DELAY), repeat(environment.ENDPOINT_REQUEST_MAX_ATTEMPTS))
            .subscribe(paymentInformation => {
                this.setPaymentStatus(paymentInformation.payment_status);
                this.processAdyenPaymentValidation(paymentInformation.payment_status);
                this.deleteOrderIfPaymentFailed();

                return paymentInformation;
            });
        
        return [];
    }

    processAdyenPaymentValidation(paymentStatus: string): void {
        this.isPaymentProcessComplete = this.paymentService.isAdyenPaymentComplete(paymentStatus);
        this.isPaymentProcessComplete ? this.unsubscribePaymentSubscription() : this.totalFailedAttempts++;
        this.paymentFailed = this.isAdyenPaymentFailed();
    }

    isAdyenPaymentFailed(): boolean {
        return !this.isPaymentProcessComplete && this.totalFailedAttempts == environment.ENDPOINT_REQUEST_MAX_ATTEMPTS;
    }

    deleteOrderIfPaymentFailed(): void {
        if (this.paymentFailed) {
            this.deleteOrder();
        }
    }

    closeDialog(cancelPayment = false): void {
        if (this.paymentSubscription) {
            this.unsubscribePaymentSubscription();
        }

        if (cancelPayment) {
            this.paymentService.cancelMolliePayment(this.transactionID).subscribe();
        }

        this.paymentFailed = !this.isPaymentProcessComplete || cancelPayment;

        this.deleteOrderIfPaymentFailed();

        this.dialogRef.close({
            paidWithSuccess: !this.paymentFailed
        });
    }

    deleteOrder(): void {
        this.OrderServiceInstance.requestOrderDelete().subscribe(response => {
            if (response.success) {
                this.OrderServiceInstance.initOrderData();
            }
        });
    }

    onSelectedTerminal(selectedTerminal): void {
        this.updateSelectedTerminal(selectedTerminal);
        this.initPaymentProcess();
    }

    updateSelectedTerminal(selectedTerminal): void {
        this.selectedTerminal = selectedTerminal;
        this.isTerminalSelected = true;
    }

    displayTotalToPay(): boolean {
        return !this.isPaymentProcessComplete && !this.paymentFailed;
    }

    displayTerminals(): boolean {
        return this.terminals.length > 1 && !this.isTerminalSelected;
    }

    displayLoadingSpinner(): boolean {
        return !this.isPaymentProcessComplete && !this.paymentFailed;
    }

    displayPaymentSuccessButton(): boolean {
        return this.isPaymentProcessComplete;
    }

    displayCancelPaymentButton(): boolean {
        return (this.paymentCreatedWithSuccess && !this.isPaymentProcessComplete) || !this.isTerminalSelected;
    }

    displayPaymentFailedButton(): boolean {
        return !this.isTerminalSelected || this.paymentFailed;
    }

    setPaymentStatus(status) {
        this.paymentStatus = status;
    }

    getPaymentStatus() {
        return this.paymentStatus;
    }

    getPaymentFailedMessage(): string {
        return "Payment " + this.getPaymentStatus() + "!";
    }
}
