import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { FormControl } from '@angular/forms';
import { ReportsService } from '../../services/reports.service';
import { CustomerService } from '../../services/customer.service';
import { AuthenticationService } from '../../services/authentication.service';
import { MatDialog } from '@angular/material/dialog';
import { PosEndOfDayPopupComponent } from '../pos-components/pos-end-of-day-popup/pos-end-of-day-popup.component';
import { AgentType } from "../../agent";

export interface PeriodicElement {
    document_type: string;
    crt_no: number;
    user: string;
    order_no: number;
    date: string;
    payment_type: string;
    discounts: string;
    total_order: number;
    shop: string;
    cashier: string;
    orders: any[];
}

@Component({
    selector: 'app-reports',
    templateUrl: './reports.component.html',
    styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {

    displayedColumns: string[] = ['Crt no', 'Document type', 'User', 'Order No', 'Date', 'Payment type', 'Discounts', "Total order", 'Shop', 'Cashier'];
    displayedColumnsSecond: string[] = ['ID', 'Product ID', 'Product Name', 'Quantity', "Price", 'Discount', 'Total'];
    dateStart = new FormControl(new Date(new Date().setDate(new Date().getDate() - 1)));
    dateEnd = new FormControl(new Date());

    dataSource = new MatTableDataSource([]);
    dataSource2 = new MatTableDataSource([]);
    total_amount = 0;
    count_orders = 0;
    totalResultsLength = 0;
    page = 0;
    displayTableExtended = true;
    displayDatePicker = false;
    elementsOnPage = 10;
    dailyReports = "Daily reports";
    salesReports = "Sales Reports";
    endOfDay = "End of day";
    initTypeTable = this.dailyReports;
    filterOptions = {
        "": "None",
        "customer_email": "Email",
        "customer_lastname": "Last Name",
        "customer_company": "Company",
        "customer_vat": "VAT"
    };
    selectedFilterOption = '';
    @Input() renderTemplatePDF = false;

    constructor(
        private reportsService: ReportsService,
        private authenticationService: AuthenticationService,
        public dialog: MatDialog
    ) { }

    ngOnInit(): void {
        this.renderTemplatePDF ? this.initReportsDataForPDF() : this.initReportsData();
    }

    initReportsDataForPDF() {
        this.reportsService.initData();

        this.initTypeTable = this.reportsService.selectedReportType ? this.reportsService.selectedReportType : this.initTypeTable;
        this.selectedFilterOption = this.reportsService.selectedFilterOption ? this.reportsService.selectedFilterOption : this.selectedFilterOption;

        this.initReportsData();
    }

    initReportsData() {
        this.loadReport(this.initTypeTable, 0);
    }

    getServerData(event) {
        this.loadReport(this.initTypeTable, event.pageIndex);
    }

    addEventDateStart(type: string, event: any) {
        if (event.value.getTime() > new Date(this.dateEnd.value).getTime()) {
            let aux = new Date(this.dateEnd.value);
            this.dateEnd = new FormControl(event.value);
            this.dateStart = new FormControl(aux);
        }
        this.resetReportsData();
        this.loadReport(this.salesReports, 0);
    }

    addEventDateEnd(type: string, event: any) {
        if (event.value.getTime() < new Date(this.dateStart.value).getTime()) {
            let aux = new Date(this.dateStart.value);
            this.dateStart = new FormControl(event.value);
            this.dateEnd = new FormControl(aux);
        }
        this.resetReportsData();
        this.loadReport(this.salesReports, 0);
    }

    newRequestAndUpdateContent(field: string, value: string, page: number, isEndOfDayReport = false) {
        if (this.reportsService.notFilteredOrders.length == 0) {
            this.reportsService.getReports(field, value, page).subscribe(response => {
                if (response.success) {
                    this.reportsService.notFilteredOrders = response.result.items[0];
                    this.total_amount = response.result.total_amount;
                    this.count_orders = response.result.total_results;
                    this.totalResultsLength = response.result.total_results;
                    this.renderOrders(page, this.total_amount);

                    if (isEndOfDayReport) {
                        this.openEndOfDayDialog();
                    }
                }
            }, (err) => {
                if (err.status == 500) {
                    this.resetReportsData();
                }
            });
        } else {
            this.renderOrders(page);
        }
    }

    openEndOfDayDialog() {
        this.dialog.open(PosEndOfDayPopupComponent, {
            width: '300px',
            maxWidth: '300px',
            hasBackdrop: true,
            disableClose: true,
            data: {
                reportData: {
                    orders: this.reportsService.notFilteredOrders,
                    count_orders: this.count_orders,
                    total_amount: this.total_amount,
                    agent: this.authenticationService.agent
                },
            }
        });
    }

    renderOrders(page: number, total_amount = 0) {
        let startIndex = page * this.elementsOnPage;
        this.dataSource.data = this.reportsService.notFilteredOrders.slice(startIndex, startIndex + this.elementsOnPage);
        this.dataSource._updateChangeSubscription();

        if (total_amount == 0) {
            this.reportsService.notFilteredOrders.forEach(order => {
                total_amount += Number(order.total);
            });
        }

        this.total_amount = total_amount;
        this.count_orders = this.reportsService.notFilteredOrders.length;
        this.totalResultsLength = this.reportsService.notFilteredOrders.length;

        if (this.renderTemplatePDF) {
            this.processFilterOrders();
        }
    }

    loadReport(reportType, page) {
        this.initTypeTable = reportType;

        this.reportsService.loadSelectedFilterOptionFromLocalStorage();
        if ((!this.reportsService.selectedFilterOption || reportType != this.reportsService.selectedReportType) && this.page == page) {
            this.reportsService.updateSelectedReportTypeInLocalStorage(reportType);
            this.reportsService.loadSelectedFilterOptionFromLocalStorage();
            this.resetReportsData();
        }

        this.page = page;

        if (this.renderTemplatePDF) {
            this.initTypeTable = this.reportsService.selectedReportType;
            reportType = this.reportsService.selectedReportType;
        }

        switch (reportType) {
            case this.dailyReports:
                this.loadDailyReport();
                break;

            case this.salesReports:
                this.loadSalesReport();
                break;

            case this.endOfDay:
                this.loadEndOfDayReport();
                break;
        }
    }

    loadDailyReport() {
        let agent = this.authenticationService.agent;
        let userType = this.isAdmin() ? 'admin' : 'user';
        let agentValue = this.isAdmin() ? agent.id_agent : agent.username;
        this.newRequestAndUpdateContent(userType, agentValue, this.page);
        this.displayTableExtended = true;
        this.displayDatePicker = false;
    }

    loadSalesReport() {
        let startTime = new Date(this.dateStart.value);
        let stringStartTime = String(startTime.getFullYear()) + '-' + String(startTime.getMonth() + 1) + '-' + String(startTime.getDate());
        let endTime = new Date(this.dateEnd.value);
        let stringEndTime = String(endTime.getFullYear()) + '-' + String(endTime.getMonth() + 1) + '-' + String(endTime.getDate());
        let selectedReportPeriod = stringStartTime + ' 00:00:00' + '_' + stringEndTime + ' 23:59:59';

        if (this.renderTemplatePDF) {
            selectedReportPeriod = this.reportsService.selectedReportPeriod;
        } else {
            this.reportsService.updateSelectedReportPeriodInLocalStorage(selectedReportPeriod);
        }

        this.newRequestAndUpdateContent("time", selectedReportPeriod, this.page);

        this.displayTableExtended = true;
        this.displayDatePicker = true;
    }

    loadEndOfDayReport() {
        let agent = this.authenticationService.agent;
        let userType = this.isAdmin() ? 'admin' : 'user';
        let agentValue = this.isAdmin() ? agent.id_agent : agent.username;
        this.newRequestAndUpdateContent(userType, agentValue, this.page, true);
        this.displayTableExtended = false;
        this.displayDatePicker = false;
    }

    public isAdmin() {
        return this.authenticationService.agent.type == AgentType.Admin;
    }

    editContact(event: any) {
        this.reportsService.getProducts(event.crt_no).subscribe((response) => {
            if (response.success) {
                this.dataSource2.data = response.result.products;
                this.dataSource2._updateChangeSubscription();
            }
        });
    };

    public processFilterOrders($event = null): void {
        this.resetProductsTable();

        this.refreshFilterByText($event);

        if ($event != null && !this.reportsService.filterByText) {
            this.loadReport(this.initTypeTable, 0);
            return;
        }

        this.dataSourceUpdateProcess();

        if (!this.reportsService.filterByText) {
            this.reportsService.deleteFilterByTextFromLocalStorage();
        }
    }

    public refreshFilterByText($event = null): void {
        if ($event != null) {
            this.reportsService.updateFilterByTextInLocalStorage($event.target.value);
        }
        this.reportsService.loadFilterByTextFromLocalStorage();
    }

    public getFilteredOrders(): any {
        let filteredOrders = [];

        this.reportsService.notFilteredOrders.forEach(order => {
            let selectedFilterValue = order[this.reportsService.selectedFilterOption];
            if (selectedFilterValue && selectedFilterValue.includes(this.reportsService.filterByText)) {
                filteredOrders.push(order);
            }
        });

        return filteredOrders;
    }

    public getOrdersTotalPaid(orders) {
        let totalToPay = 0;

        orders.forEach(order => {
            totalToPay += Number(order.total);
        });

        return totalToPay;
    }

    public updateDataSourceOrders(orders): void {
        this.dataSource.data = this.elementsOnPage > 0 ? orders.slice(0, this.elementsOnPage) : orders.slice(this.elementsOnPage);
    }

    public dataSourceUpdateProcess() {
        if (!this.reportsService.filterByText && this.renderTemplatePDF) {
            this.dataSource.data = this.reportsService.notFilteredOrders;
            this.elementsOnPage = this.dataSource.data.length;
            return;
        }

        let filteredOrders = this.getFilteredOrders();

        if (this.renderTemplatePDF) {
            this.dataSource.data = filteredOrders;
            this.elementsOnPage = filteredOrders.length;
        }

        if (!this.renderTemplatePDF) {
            this.updateDataSourceOrders(filteredOrders);
        }

        this.dataSource._updateChangeSubscription();
        this.total_amount = this.getOrdersTotalPaid(filteredOrders);
        this.count_orders = filteredOrders.length;
        this.totalResultsLength = filteredOrders.length;
    }

    public resetProductsTable(): void {
        this.dataSource2.data.length = 0;
        this.dataSource2._updateChangeSubscription();
    }

    public processSelectedFilterOption(): void {
        this.selectedFilterOptionProcess();
        this.loadReportsOnFilterChange();
    }

    public selectedFilterOptionProcess(): void {
        this.reportsService.updateSelectedFilterOptionInLocalStorage(this.selectedFilterOption);
        this.reportsService.loadSelectedFilterOptionFromLocalStorage();
        if (!this.selectedFilterOption) {
            this.reportsService.deleteSelectedFilterOptionFromLocalStorage();
        }
    }

    public loadReportsOnFilterChange(): void {
        this.reportsService.loadFilterByTextFromLocalStorage();
        if (this.reportsService.filterByText) {
            this.loadReport(this.initTypeTable, 0);
        }
    }

    printReport() {
        window.open(window.location.origin + '/report-print-preview');
    }

    resetReportsData() {
        this.reportsService.notFilteredOrders.length = 0;
        this.reportsService.selectedFilterOption = '';
        this.reportsService.filterByText = '';
        this.reportsService.deleteFilterByTextFromLocalStorage();
        this.reportsService.deleteSelectedFilterOptionFromLocalStorage();
        this.selectedFilterOption = '';
        this.total_amount = 0;
        this.count_orders = 0;
        this.totalResultsLength = 0;
        this.dataSource.data.length = 0;
        this.dataSource._updateChangeSubscription();
        this.resetProductsTable();
    }

    public get isEndOfDaySelected() {
        this.reportsService.loadSelectedReportTypeFromLocalStorage();
        return this.reportsService.selectedReportType == this.endOfDay;
    }
}
