import { BillingAddress } from './billing-address';

export class Customer{
  ID: string;
  Barcode: number;
  Email: string;
  FirstName: string;
  LastName: string;
  Phone: string;
  CompanyName: string;
  BillingAddress: BillingAddress;
  VatNumber: string;
  CurrencyIso: string;
  CurrencyID: number;
  RequestNewPassword: boolean;
  CustomerCode: string;
}
