export interface VatTotal {
  Name: string;
  Value: number;
}

export class VatInfo {
  Name: string = '';
  Percentage: number = 0;
  Amount: number = 0;

  public constructor(name = '', percentage = 0, amount = 0) {
    this.Name = name;
    this.Percentage = percentage;
    this.Amount = amount;
  }
}

export class CartProduct {
  Image: string = '';
  Name: object;
  Currency: string = '';
  Reference: string = null;
  quantity: number = 0;
  PriceWithTax: number = 0;
  PriceWithoutTax: number = 0;
  position: number = 0;
  totalprice: number = 0;
  calculatedVat: number = 0;

  TaxInfo: VatInfo = new VatInfo();

  public constructor(image: string, name: object, currency: string, reference: string, quantity: number, priceWithTax: number, priceWithoutTax: number) {
    this.Image = image;
    this.Name = name;
    this.Currency = currency;
    this.Reference = reference;
    this.quantity = quantity;
    this.PriceWithTax = priceWithTax;
    this.PriceWithoutTax = priceWithoutTax;
  }
}
