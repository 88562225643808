<h2>Add a voucher code here</h2>
<mat-dialog-content>
    <div class="alert alert-danger" *ngIf="visibleErrors.alreadyAdded">This voucher has already been added.</div>
    <div class="alert alert-danger" *ngIf="visibleErrors.exceededMax">Voucher value cannot be higher then the Subtotal!</div>
    <div class="alert alert-danger" *ngIf="visibleErrors.invalidVoucher">This voucher is invalid.</div>
    <p>
        <mat-form-field appearance="fill">
            <mat-label>Voucher Code</mat-label>
            <input (keydown.enter)="voucherValidationProcess()" #voucherCodeInputField [(ngModel)]="this.code" matInput type="text">
        </mat-form-field>
    </p>

</mat-dialog-content>
<mat-dialog-actions style="margin-bottom: 10px;">
    <button mat-raised-button color="primary" (click)="voucherValidationProcess()">Add code</button>
    <button mat-raised-button [mat-dialog-close]="true">Cancel</button>
</mat-dialog-actions>
