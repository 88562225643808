import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-pos-price-format',
  templateUrl: './pos-price-format.component.html',
  styleUrls: ['./pos-price-format.component.scss']
})
export class PosPriceFormatComponent implements OnInit, OnChanges {

  @Input() price = 0;
  @Input() currencyIsoCode = 'EUR';
  formatted_price: string;

  constructor() { }

  ngOnInit(): void {
    this.formatPriceByCurrency();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.price = this.formatPrice(changes.price.currentValue);
    this.currencyIsoCode = changes.currencyIsoCode ? changes.currencyIsoCode.currentValue : this.currencyIsoCode;
    this.formatPriceByCurrency();
  }

  formatPriceByCurrency() {
    if (this.currencyIsoCode == 'EUR') {
      this.formatToEuro();
    } else if (this.currencyIsoCode == 'USD') {
      this.formatToDollar();
    }
  }

  formatToEuro() {
    this.formatted_price = this.price + " €";
  }

  formatToDollar() {
    this.formatted_price = "$" + this.price;
  }

  formatPrice(price) {
    return parseFloat(Number(price).toFixed(2));
  }
}
