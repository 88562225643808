import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { CartService } from 'src/app/services/cart.service';
import { CustomerService } from 'src/app/services/customer.service';
import { OrderService } from 'src/app/services/order.service';
import { ReportsService } from 'src/app/services/reports.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-pos-end-of-day-popup',
  templateUrl: './pos-end-of-day-popup.component.html',
  styleUrls: ['./pos-end-of-day-popup.component.scss']
})
export class PosEndOfDayPopupComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<PosEndOfDayPopupComponent>, 
    @Inject(MAT_DIALOG_DATA) public data,
    private authenticationService: AuthenticationService,
    private cartService: CartService,
    private customerService: CustomerService,
    private reportsService: ReportsService,
    private router: Router,
    private orderService: OrderService
  ) { }

  ngOnInit(): void {
  }

  endOfDay() {
    this.closeDialog();
    this.reportsService.getEndOfDayReport(this.data.reportData).subscribe(response => {
      if (response.success) {
        let pdf_url = document.createElement('a');
        pdf_url.href = environment.PDF_REPORTS_ENDPOINT + response.pdf_name;
        pdf_url.download = response.pdf_name;
        pdf_url.click();


        this.cartService.emptyCart();
        this.orderService.emptyOrderData();
        this.customerService.resetLinkedCustomer();
        this.reportsService.emptyReportsData();
        this.authenticationService.logout();
        this.router.navigate(['/']);
      }
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
