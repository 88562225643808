import { PosNewCustomerDialogComponent } from '../pos-new-customer-dialog/pos-new-customer-dialog.component';
import { CustomerService } from '../../../services/customer.service';
import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-pos-linked-customer-to-order',
  templateUrl: './pos-linked-customer-to-order.component.html',
  styleUrls: ['./pos-linked-customer-to-order.component.scss']
})
export class PosLinkedCustomerToOrderComponent implements OnInit {
  customer = this.customerService.createEmptyCustomer();
  constructor(
    public customerService: CustomerService,
    public editCustomerDialog: MatDialog,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit(): void {
    this.customerService.customerChangedEvent.subscribe(customer => {
      this.customer = customer;
      //this.customerService.initDefaultLinkedCustomer();
    })
  }

  public get selectedCustomer(){
    this.customer = this.customerService.linkedCustomer;
    return this.customer;
  }

  editCustomer(event: any, customer?: any): void {
    if (!customer) {
      customer = this.customer;
    }
    this.customerService.changeLinkedCustomer(customer);
    this.editCustomerDialog.closeAll();
    this.editCustomerDialog.open(PosNewCustomerDialogComponent, {
      hasBackdrop: true,
      width: '500px',
    });
    event.stopPropagation();
  }

  unlinkCustomer(event: any): void {
    this.customerService.resetLinkedCustomer();
    event.stopPropagation();
  }

  displayActionButtons() {
    return this.authenticationService.agent && this.customerService.linkedCustomer && this.authenticationService.agent.username !== this.customerService.linkedCustomer.Email;
  }
}
