import { Component, OnInit } from '@angular/core';
import { CartService } from 'src/app/services/cart.service';
import { MatDialog } from '@angular/material/dialog';
import { PosDiscountDialogComponent } from '../pos-components/pos-discount-dialog/pos-discount-dialog.component';
import { PosVoucherDialogComponent } from '../pos-components/pos-voucher-dialog/pos-voucher-dialog.component';

@Component({
    selector: 'app-pos',
    templateUrl: './pos.component.html',
    styleUrls: ['./pos.component.scss'],
})
export class PosComponent implements OnInit {
    constructor(
        private cartService: CartService,
        public dialog: MatDialog
    ) { }

    ngOnInit(): void { }

    public openDiscountDialog() {
        const dialogRef = this.dialog.open(PosDiscountDialogComponent, {
            hasBackdrop: true,
            data: this.cartService.discount
        });

        dialogRef.componentInstance.maxValue = this.cartService.total + this.cartService.discount.totalValue;
        dialogRef.afterClosed().subscribe(result => {
            this.cartService.refreshTotals();
            this.cartService.discountUpdateEvent.emit();
        });
    }

    public openVoucherDialog() {
        const dialogRef = this.dialog.open(PosVoucherDialogComponent, {
            hasBackdrop: true,
            data: this.cartService.vouchers
        });

        dialogRef.componentInstance.maxValue = this.cartService.total;
        dialogRef.afterClosed().subscribe(result => {
            this.cartService.refreshTotals();
            this.cartService.voucherUpdateEvent.emit();
        });
    }
}
