import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth.guard';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { PosComponent } from './pages/pos/pos.component';
import { ReportsComponent } from './pages/reports/reports.component';
import { ShopsComponent } from './pages/shops/shops.component';
import { TerminalsComponent } from './pages/terminals/terminals.component';
import { PosPrintOrderQuoteComponent } from './pages/pos-components/pos-print-order-quote/pos-print-order-quote.component';
import { PosReportPrintComponent } from './pages/pos-components/pos-report-print/pos-report-print.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { ConfigurationComponent } from './pages/configuration/configuration.component';
import {AgentsComponent} from "./pages/agents/agents.component";
import {StationsComponent} from "./pages/stations/stations.component";
import {ReceiptPrintersComponent} from "./pages/receipt-printers/receipt-printers.component";
import {PrinterTestComponent} from "./pages/printer-test/printer-test.component";

const routes: Routes = [
  {
    path: '',
    component: DashboardComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'pos',
    component: PosComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'reports',
    component: ReportsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'shops',
    component: ShopsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'settings',
    component: SettingsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'printer-test',
    component: PrinterTestComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'terminals',
    component: TerminalsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'agents',
    component: AgentsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'stations',
    component: StationsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'order-quote-preview',
    component: PosPrintOrderQuoteComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'report-print-preview',
    component: PosReportPrintComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'configuration',
    component: ConfigurationComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'receipt-printers',
    component: ReceiptPrintersComponent,
    canActivate: [AuthGuard]
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
