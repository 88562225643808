export class Configuration {
  name: string = '';
  value: string = '';
  frontend: boolean = false;
  public cloneFrom(oldConfiguration: Configuration) {
    this.name = oldConfiguration.name;
    this.value = oldConfiguration.value;
    this.frontend = oldConfiguration.frontend;
  }
}
