import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CustomerService } from 'src/app/services/customer.service';
import { OrderService } from 'src/app/services/order.service';
import { PaymentService } from 'src/app/services/payment.service';

export interface CartResetDialogData {
  resetCallback();
}

@Component({
  selector: 'app-pos-reset-cart-popup',
  templateUrl: './pos-reset-cart-popup.component.html',
  styleUrls: ['./pos-reset-cart-popup.component.scss']
})
export class PosResetCartPopupComponent implements OnInit {

  constructor(
    private customerService: CustomerService,
    public dialogRef: MatDialogRef<PosResetCartPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CartResetDialogData,
    private paymentService: PaymentService,
    private orderService: OrderService) { }

  ngOnInit(): void {
  }

  resetCart() {
    this.data.resetCallback();
    this.customerService.resetLinkedCustomer();
    this.paymentService.initPaymentData();
    this.orderService.initOrderData();
    this.orderService.removeOrderReferenceFromLocalStorage();
    this.closeDialog();
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
