import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthenticationService} from './services/authentication.service';
import {ConfigurationService} from "./services/configuration.service";
import {PopupService} from "./services/popup.service";
import {AgentType} from "./agent";
import {environment} from "../environments/environment";

@Injectable({
    providedIn: 'root',
})
export class AuthGuard implements CanActivate {
    constructor(private authenticationService: AuthenticationService, private configurationService: ConfigurationService, private popupService: PopupService) {
    }

    async canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):  Promise<boolean> {
        const isHomePage = state.url === '/';
        try {
            const loginSuccess = await this.authenticationService.checklogin();

            if(!loginSuccess) {
                this.showLogin(state);
                return isHomePage;
            }

            /**
             * If we are on homepage, just return true and redirect to POS.
             * The other validations will be done on the POS page,
             * and if they fail, the user will be redirected back here.
             * This prevents an infinite redirect loop or more complex
             * code for handling validations and exceptions when on the home page.
             */
            if(isHomePage) {
                this.authenticationService.redirectToCart();
                return true;
            }

            if(this.authenticationService.agent.type == AgentType.Agent) {
              if(!environment.AGENT_ACCESS_PAGES.includes(state.url)) {
                this.authenticationService.redirectToHomePage();
                return false;
              }
            }

            const refreshStationResult = await this.configurationService.refreshStationData();
            if(!refreshStationResult.success) {
                this.authenticationService.logout();
                await this.popupService.showError(refreshStationResult.error);
                this.showLogin(state);
                return false;
            }
            if(this.configurationService.station.id_shop != this.authenticationService.agent.id_shop) {
                this.authenticationService.logout();
                await this.popupService.showError('The user does not have access to this station.');
                this.showLogin(state);
                return false;
            }
            await this.configurationService.refreshConfiguration();
            return true;
        }
        catch (error) {
            /**
             * If any of the API calls fail, we show the login popup.
             */
            this.showLogin(state);
            return isHomePage;
        }
    }

    private showLogin(state: RouterStateSnapshot) {
        if(state.url !== '/')
            this.authenticationService.redirectToHomePage();
        this.authenticationService.displayLogin();
    }
}
