<h1 mat-dialog-title *ngIf="displayTotalToPay()" style="text-align: center;">
  Total to pay: <app-pos-price-format [price]="data.amountToPay"></app-pos-price-format>
</h1>
<h1 mat-dialog-title *ngIf="isPaymentProcessComplete" style="text-align: center;">
  <app-pos-price-format [price]="data.amountToPay"></app-pos-price-format> paid
</h1>
<h1 mat-dialog-title *ngIf="paymentFailed" style="text-align: center;">{{ getPaymentFailedMessage() }}</h1>

<mat-form-field appearance="fill" *ngIf="displayTerminals()">
  <mat-label>Select a terminal</mat-label>
  <mat-select [(value)]="selectedTerminal.code">
    <mat-option *ngFor="let terminal of terminals" [value]="terminal.code" (click)="onSelectedTerminal(terminal)">{{ terminal.name }}</mat-option>
  </mat-select>
</mat-form-field>

<div mat-dialog-content style="overflow: hidden;" *ngIf="isTerminalSelected">
  <mat-spinner diameter="80" *ngIf="displayLoadingSpinner()" style="margin: 0 auto 15px auto;"></mat-spinner>
  <div class="alert" role="alert" style="text-align: center;" *ngIf="displayPaymentSuccessButton()">
    <mat-icon class="payment-success-icon">check_circle</mat-icon>
  </div>
  <div class="alert" role="alert" style="text-align: center;" *ngIf="displayPaymentFailedButton()">
    <mat-icon class="payment-failed-icon">cancel</mat-icon>
  </div>
</div>

<div mat-dialog-actions class="d-flex justify-content-center mb-2">
  <button mat-flat-button (click)="closeDialog()" *ngIf="displayPaymentSuccessButton()" class="payment-success-button">Confirm</button>
  <button mat-raised-button class="w-25" (click)="closeDialog(true)" *ngIf="displayCancelPaymentButton()">Cancel</button>
</div>
