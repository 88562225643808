export const TERMINAL_TYPES = {
    ADYEN: 'adyen',
    MOLLIE: 'mollie'
}

export class Terminal {
    id_terminal: number = 0;
    name: string = '';
    code: string = '';
    id_shop: string = '';
    shop_name: string = '';
    type: string = TERMINAL_TYPES.MOLLIE; //ADYEN

    public cloneFrom(oldTerminal: Terminal) {
        this.id_terminal = oldTerminal.id_terminal;
        this.name = oldTerminal.name;
        this.code = oldTerminal.code;
        this.id_shop = oldTerminal.id_shop;
        this.shop_name = oldTerminal.shop_name;
        this.type = oldTerminal.type;
    }
}