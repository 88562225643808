<h1 mat-dialog-title>Total to pay: <app-pos-price-format [price]="data.totalToPay"></app-pos-price-format></h1>
<div mat-dialog-content>
  <p>Options to receive from customer:</p>
  <div class="cash_paymet_options">
    <button mat-raised-button color="accent" (click)="updateAmounts(data.totalToPayFirstOption)"><app-pos-price-format [price]="data.totalToPayFirstOption"></app-pos-price-format></button>
    <button mat-raised-button color="accent" (click)="updateAmounts(data.totalToPaySecondOption)"><app-pos-price-format [price]="data.totalToPaySecondOption"></app-pos-price-format></button>
    <button mat-raised-button color="accent" (click)="updateAmounts(data.totalToPayThirdOption)"><app-pos-price-format [price]="data.totalToPayThirdOption"></app-pos-price-format></button>
  </div>
  <br /><br />
  <mat-form-field appearance="fill" class="mat-form-field-invalid" [ngClass]="{'mat-form-field-invalid': lowerAmountSelected}">
    <mat-label>Amount received:</mat-label>
    <input matInput [(ngModel)]="data.amountReceived" (input)="updateAmounts(data.amountReceived, $event)" [maxlength]="inputFieldMaxLength">
  </mat-form-field>
  <p>Change: <app-pos-price-format [price]="data.change"></app-pos-price-format></p>
</div>

<div mat-dialog-actions class="mb-2 justify-content-space-between">
  <button mat-button mat-raised-button (click)="onNoClick()">No Thanks</button>
  <button mat-button mat-raised-button color="primary" cdkFocusInitial *ngIf="!lowerAmountSelected" (click)="createPayment()">Ok</button>
  <button mat-button mat-raised-button color="primary" cdkFocusInitial *ngIf="lowerAmountSelected">Ok</button>
</div>

