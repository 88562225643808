import {AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ReceiptPrinter} from "../../../receipt-printer";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";


@Component({
  selector: 'app-receipt-printer-dialog',
  templateUrl: './receipt-printer-dialog.component.html',
  styleUrls: ['./receipt-printer-dialog.component.scss'],
})
export class ReceiptPrinterDialogComponent implements OnInit, AfterViewInit {
  @ViewChild('receiptPrinterUrlField') receiptPrinterUrlField: ElementRef;

  errorMessage: string = "";
  printer: ReceiptPrinter = new ReceiptPrinter();
  shops: [] = [];

  constructor(public dialogRef: MatDialogRef<ReceiptPrinterDialogComponent>, @Inject(MAT_DIALOG_DATA) public originalPrinter: ReceiptPrinter, private http: HttpClient) {
    this.printer.cloneFrom(originalPrinter);
    this.selectedShop = {id_shop: this.printer.id_shop, shop_name: this.printer.shop_name}
    this.loadShops();
  }

  ngOnInit(): void {
  }

  public visibleErrors = {
    emptyPrinterUrl: false,
    emptyLogicalName: false,
    emptyShop: false,
    emptyPaperSize: false,

    anyError: function() {
      return this.emptyPrinterUrl
          || this.emptyLogicalName
          || this.emptyShop
          || this.emptyPaperSize
    }
  }

  public savePrinter() {
    this.visibleErrors.emptyPrinterUrl = !this.printer.url;
    this.visibleErrors.emptyLogicalName = !this.printer.logical_name;
    this.visibleErrors.emptyShop =!this.printer.id_shop;
    this.visibleErrors.emptyPaperSize = !this.printer.paper_size;
    if(this.visibleErrors.anyError())
      return;

    this.http.post<any>(environment.APP_ENVIRONMENT + environment.RECEIPT_PRINTERS_ENDPOINT + "/save", this.printer).subscribe(response => {
        if (!response.success)
          console.dir(response.error);
        else {
          this.originalPrinter.cloneFrom(this.printer);
          this.dialogRef.close();
        }
      },
      (err) => {
        console.dir(err);
      });
  }

  public loadShops() {
    this.http.get<any>(environment.APP_ENVIRONMENT + environment.SHOPS_ENDPOINT + "/list").subscribe(response => {
        if (!response.success)
          console.dir(response.error);
        else {
          this.shops = response.shops;
        }
      },
      (err) => {
        console.dir(err);
      });
  }

  selectedShop: any;

  updateShop(selectedShop: any) {
    this.printer.id_shop = selectedShop.id_shop;
    this.printer.shop_name = selectedShop.shop_name;
  }

  shopCompareFn(shop1: any, shop2: any) {
    return shop1 && shop2 && shop1.id_shop === shop2.id_shop;
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.receiptPrinterUrlField.nativeElement.focus();
    });
  }

}
