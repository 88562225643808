import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {environment} from "../../../environments/environment"
import {LaravelPaginationLink} from "../../laravel-pagination-link";
import {Terminal} from "../../terminal";
import {TerminalDialogComponent} from "./terminal-dialog/terminal-dialog.component";


@Component({
    selector: 'app-terminals',
    templateUrl: './terminals.component.html',
    styleUrls: ['./terminals.component.scss']
})
export class TerminalsComponent implements OnInit {

    paginationLinks: LaravelPaginationLink[] = [];
    currentPage;
    totalResults;
    totalPages;
    currentLink = environment.APP_ENVIRONMENT + environment.TERMINALS_ENDPOINT;
    rows: Terminal[] = [];
    displayedColumns: string[] = ['id', 'name', 'code', 'shop', 'type', 'actions'];

    constructor(
        public dialog: MatDialog,
        private http: HttpClient
    ) { }

    ngOnInit(): void {
        this.reloadTable();
    }

    createTerminalFromJson(json: any): Terminal
    {
        let result = new  Terminal();
        result.cloneFrom(json);
        return result;
    }

    reloadTable() {
        this.http.get<any>(this.currentLink).subscribe(response => {
                this.rows = response.data.map(this.createTerminalFromJson);
                this.currentPage = response.current_page;
                this.paginationLinks = response.links;
                this.totalPages = response.last_page;
                this.totalResults = response.total;
            },
            (err) => {
                console.dir(err);
            });
    }

    editTerminal(terminal: Terminal, callback: CallableFunction = () => {}) {
        const dialogRef = this.dialog.open(TerminalDialogComponent, {
                hasBackdrop: true,
                data: terminal
            }
        );
        dialogRef.afterClosed().subscribe(result => {
            callback(result)
        })
    }

    changePage(link) {
        this.currentLink = link;
        this.reloadTable();
    }

    createTerminal() {
        let terminal = new  Terminal();
        this.editTerminal(terminal, (result) => {
            this.reloadTable();
        })
    }

    deleteTerminal(terminal: Terminal) {
        this.http.post<any>(environment.APP_ENVIRONMENT+environment.TERMINALS_ENDPOINT+"/delete/"+terminal.id_terminal, {}).subscribe(response => {
                if(!response.success)
                    console.dir(response.error);
                else {
                    this.reloadTable();
                }
            },
            (err) => {
                console.dir(err);
            });
    }
}
