import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PosCardPayDialogComponent } from '../pos-card-pay-dialog/pos-card-pay-dialog.component';
import { PosCashPayDialogComponent } from '../pos-cash-pay-dialog/pos-cash-pay-dialog.component';
import { PosPartialPayDialogComponent } from '../pos-partial-pay-dialog/pos-partial-pay-dialog.component';
import { CartService } from 'src/app/services/cart.service';
import { OrderService } from 'src/app/services/order.service';
import { PaymentService } from 'src/app/services/payment.service';
import { ToolsService } from 'src/app/services/tools.service';

@Component({
    selector: 'app-pos-payments',
    templateUrl: './pos-payments.component.html',
    styleUrls: ['./pos-payments.component.scss']
})
export class PosPaymentsComponent implements OnInit {

    euroBanknotes = [5, 10, 20, 50, 100, 200, 500];
    FIRST_PRESET_OPTION: number = 1;
    SECOND_PRESET_OPTION: number = 2;
    THIRD_PRESET_OPTION: number = 3;
    existsProductsInCart: boolean = true;
    private dialogData;

    constructor(
        public dialog: MatDialog,
        private cartService: CartService,
        private orderService: OrderService,
        public paymentService: PaymentService,
        private toolsService: ToolsService
    ) { }

    ngOnInit(): void {
        this.cartService.cartUpdateEvent.subscribe(() => {
            this.paymentService.setTotalToPay(this.toolsService.formatNumber(this.cartService.total));
        });
    }

    public startPaymentProcess(paymentOptionSelected: string, updatePartialPaymentFlag: boolean = true): void {
        if (!this.existsProductsInCartValidation()) {
            return;
        }
        
        this.updatePaymentData();
        this.dialog.closeAll();

        if (updatePartialPaymentFlag) {
            this.paymentService.setAsPartialPayment(paymentOptionSelected == this.paymentService.SPLIT_PAYMENT);
            this.paymentService.paymentType = paymentOptionSelected;
        }

        if (this.paymentService.isCardPaymentOptionSelected(paymentOptionSelected)) {
            this.startPayWithCardPaymentProcess();
        }

        if (this.paymentService.isCashPaymentOptionSelected(paymentOptionSelected)) {
            this.startPayWithCashPaymentProcess();
        }

        if (paymentOptionSelected == this.paymentService.SPLIT_PAYMENT) {
            this.startPartialPaymentProcess();
        }
    }

    public existsProductsInCartValidation(): boolean {
        this.updateExistsProductsInCartFlag();

        return this.existsProductsInCart;
    }

    public updateExistsProductsInCartFlag(): void {
        this.existsProductsInCart = this.cartService.existsProductsInCart();
    }

    public updatePaymentData(): void {
        if (this.shouldSetPaymentTotalToPay()) {
            this.paymentService.setTotalToPay(this.toolsService.formatNumber(this.cartService.total));
        }

        this.paymentService.setIsPaidWithSuccess(false);
    }

    public shouldSetPaymentTotalToPay(): boolean {
        return !this.paymentService.getTotalLeftToPay() && !this.paymentService.getTotalToPay();
    }

    public startPayWithCardPaymentProcess(): void {
        if (!this.orderService.getOrderReference()) {
            this.processOrderSave();
        }

        if (this.orderService.getOrderReference()) {
            const dialogRef = this.dialog.open(PosCardPayDialogComponent, {
                width: '500px',
                maxWidth: '90%',
                data: {
                    orderReference: this.orderService.getOrderReference(),
                    amountToPay: this.getSelectedAmountToPay(),
                },
            });
    
            dialogRef.afterClosed().subscribe(result => {
                this.openPartialPaymentDialogIfNeed(result.paidWithSuccess);
                this.completePaymentProcess(result.paidWithSuccess);
                this.orderService.emptyOrderData();
            });
        }
    }
    
    public completePaymentProcess(paidWithSuccess: boolean): void {
        if (!paidWithSuccess) {
            return;
        }

        if (this.isPaymentProcessComplete(paidWithSuccess)) {
            this.paymentService.setAsPartialPayment(false);
            this.cartService.generateQuote(true);
            this.cartService.printReceipt();
            this.orderService.requestOrderConfirmationEmail().subscribe();
        }
    }

    public isPaymentProcessComplete(paidWithSuccess: boolean): boolean {
        return !this.paymentService.isPartialPayment() || this.getTotalLeftToPayAfterPaymentProcess(paidWithSuccess) <= 0;
    }

    public startPayWithCashPaymentProcess(): void {
        const dialogRef = this.dialog.open(PosCashPayDialogComponent, {
            width: '350px',
            maxWidth: '90%',
            data: this.getPayCashDialogData(),
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result.paidWithSuccess) {
                let totalToPay = this.dialogData ? this.dialogData.totalToPay - this.dialogData.amountReceived : 0;
                this.paymentService.setTotalToPay(this.toolsService.formatNumber(totalToPay));
            }

            if (this.paymentService.getTotalToPay() <= 0 && result.paidWithSuccess) {
                this.orderService.requestOrderSave(this.orderService.getOrderData()).subscribe(response => {
                    if (response.success) {
                        this.orderService.setOrderID(parseInt(response.result.OrderID));
                        this.orderService.setOrderReference(response.result.OrderReference);
                        this.paymentService.initPaymentData();
                        this.completePaymentProcess(result.paidWithSuccess);
                    }
                });
            }

            this.paymentService.setIsPaidWithSuccess(result.paidWithSuccess);
            this.openPartialPaymentDialogIfNeed(result.paidWithSuccess);
        });
    }

    public startPartialPaymentProcess(): void {
        const totalToPay = this.paymentService.getTotalToPay();
        const dialogRef = this.dialog.open(PosPartialPayDialogComponent, {
            width: '500px',
            maxWidth: '90%',
            data: {
                totalToPay: totalToPay,
                totalToPayUsingCard: 0,
                totalToPayUsingCash: 0,
                totalLeftToPay: totalToPay,
                amountReceived: 0,
            },
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result.paymentOptionSelected == "") {
                return;
            }
            
            if (this.paymentService.isPartialPayment()) {
                this.paymentService.setTotalToPay(this.toolsService.formatNumber(result.totalToPay));
                this.paymentService.setTotalLeftToPay(this.toolsService.formatNumber(result.totalLeftToPay));
            }

            this.dialogData = result;
            this.startPaymentProcess(result.paymentOptionSelected, false);
        });
    }

    public processOrderSave(): void {
        let orderData = this.orderService.getOrderData();
        this.orderService.requestOrderSave(orderData).subscribe(response => {
            if (response.success) {
                this.setOrderServiceData(response.result);
                this.startPaymentProcess(this.paymentService.PAY_WITH_CARD_PAYMENT, false);
            }
        });
    }

    public setOrderServiceData(order): void {
        this.orderService.setOrderID(parseInt(order.OrderID));
        this.orderService.setOrderReference(order.OrderReference);
    }

    public getPayCashDialogData(): object {
        const totalToPay = this.paymentService.getTotalToPay();
        var totalToPayFirstOption = this.generatePresetTotalToPay(totalToPay, this.FIRST_PRESET_OPTION);
        var totalToPaySecondOption = this.generatePresetTotalToPay(totalToPayFirstOption, this.SECOND_PRESET_OPTION);
        var totalToPayThirdOption = this.generatePresetTotalToPay(totalToPaySecondOption, this.THIRD_PRESET_OPTION);
        
        return {
            totalToPay: totalToPay,
            totalToPayFirstOption: totalToPayFirstOption,
            totalToPaySecondOption: totalToPaySecondOption,
            totalToPayThirdOption: totalToPayThirdOption,
            amountReceived: this.getAmountReceivedToPay(),
            change: this.getChange(),
        };
    }

    public getAmountReceivedToPay(): number {
        let amountReceived = this.paymentService.getTotalToPay() - this.paymentService.getTotalLeftToPay();
        if (this.dialogData && this.dialogData.amountReceived) {
            amountReceived = this.dialogData.amountReceived;
        }

        return this.toolsService.formatNumber(amountReceived);
    }

    public getChange(): number {
        var change = this.getAmountReceivedToPay() - this.paymentService.getTotalToPay();

        if (change < 0) {
            change = 0;
        }

        return this.toolsService.formatNumber(change);
    }

    public getSelectedAmountToPay(): number {
        var selectedAmountToPay = this.paymentService.getTotalToPay();
        if (this.paymentService.isPartialPayment()) {
            selectedAmountToPay = this.toolsService.formatNumber(this.dialogData.amountReceived);
        }

        return selectedAmountToPay;
    }

    public openPartialPaymentDialogIfNeed(paidWithSuccess: boolean): boolean {
        if (!this.paymentService.isPartialPayment()) {
            return false;
        }

        this.updateTotalToPayAfterPaymentProcess(paidWithSuccess);

        if (this.isPaymentProcessComplete(paidWithSuccess)) {
            this.paymentService.setAsPartialPayment(false);
        }

        if (this.paymentService.isPartialPayment()) {
            this.startPartialPaymentProcess();
        }
    }

    public updateTotalToPayAfterPaymentProcess(paidWithSuccess: boolean): void {
        const totalLeftToPay = this.getTotalLeftToPayAfterPaymentProcess(paidWithSuccess);
        this.paymentService.setTotalToPay(totalLeftToPay);
    }

    public getTotalLeftToPayAfterPaymentProcess(paidWithSuccess: boolean): number {
        var totalLeftToPay =  this.dialogData.totalToPay;
        if (paidWithSuccess) {
            totalLeftToPay = this.dialogData.totalToPay - this.dialogData.amountReceived;
        }

        return this.toolsService.formatNumber(totalLeftToPay);
    }

    public generatePresetTotalToPay(totalToPay, presetOption): number {
        var presetTotal = Math.ceil(totalToPay);
        if (presetTotal > totalToPay) {
            return presetTotal;
        }

        if (presetOption == this.FIRST_PRESET_OPTION) {
            return presetTotal % 5 == 0 ? presetTotal + 5 : (totalToPay + (5 - totalToPay % 5));
        }

        if (presetTotal % 5 == 0) {
            for (const euroBanknote of this.euroBanknotes) {
                if (euroBanknote > totalToPay % 100) {
                    return totalToPay + (euroBanknote - totalToPay % 100);
                }
            }
        }

        if (presetOption == this.THIRD_PRESET_OPTION) {
            for (const euroBanknote of this.euroBanknotes) {
                if (euroBanknote > presetTotal) {
                    return euroBanknote;
                }
            }
        }

        return Math.ceil(totalToPay / 5) * 5;
    }
}
