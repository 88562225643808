import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CartService } from '../../../services/cart.service';

@Component({
    selector: 'app-pos-price-calculation',
    templateUrl: './pos-price-calculation.component.html',
    styleUrls: ['./pos-price-calculation.component.scss']
})
export class PosPriceCalculationComponent implements OnInit {
    constructor(public dialog: MatDialog, public cartService: CartService) {
    }

    ngOnInit(): void {
    }

    resetDiscount() {
        this.cartService.discount.value = 0;
        this.cartService.refreshTotals();
        this.cartService.discountUpdateEvent.emit();
    }

    deleteVoucher(voucherIndex) {
        this.cartService.vouchers.splice(voucherIndex, 1);
        this.cartService.refreshTotals();
        this.cartService.voucherUpdateEvent.emit();
    }
}
