import { Injectable } from '@angular/core';
import { general_configuration } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ValidationService {

  constructor() { }

  isAmountToPayInvalid(amountToPay): boolean {
    return amountToPay < 0 
      || isNaN(amountToPay) 
      || amountToPay == undefined 
      || amountToPay == '' 
      || amountToPay == null 
      || (amountToPay[0] == 0 && !amountToPay.includes('.'));
  }

  getInputFieldMaxLength() {
    return general_configuration.INPUT_FIELD_MAX_LENGTH;
  }
}
