import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { MatDialog } from '@angular/material/dialog';
import {environment} from "../../../environments/environment"
import {Configuration} from "../../configuration";
import {ConfigurationDialogComponent} from "./configuration-dialog/configuration-dialog.component";
import {ConfigurationService} from "../../services/configuration.service";
@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.scss']
})
export class ConfigurationComponent implements OnInit {
  rows: Configuration[] = [];
  displayedColumns: string[] = ['name', 'value', 'frontend', 'actions'];
  constructor(
    private authenticationService: AuthenticationService,
    public dialog: MatDialog,
    private http: HttpClient,
    private configurationService: ConfigurationService
  ) { }
  ngOnInit(): void {
    this.reloadTable();
  }
  createConfigurationFromJson(json: any): Configuration
  {
    let result = new  Configuration();
    result.cloneFrom(json);
    return result;
  }
  reloadTable() {
    this.http.get<any>(environment.APP_ENVIRONMENT + environment.CONFIGURATION_ENDPOINT).subscribe(response => {
        this.rows = response.map(this.createConfigurationFromJson);
      },
      (err) => {
        console.dir(err);
      });
  }
  editConfiguration(configuration: Configuration, callback: CallableFunction = () => {}) {
    const dialogRef = this.dialog.open(ConfigurationDialogComponent, {
        hasBackdrop: true,
        data: configuration
      }
    );
    dialogRef.afterClosed().subscribe(result => {
      callback(result)
    })
  }
  createConfiguration() {
    let configuration = new  Configuration();
    this.editConfiguration(configuration, result => {
      this.refreshConfiguration();
    })
  }
  deleteConfiguration(configuration: Configuration) {
    this.http.post<any>(environment.APP_ENVIRONMENT+environment.CONFIGURATION_ENDPOINT+"/delete/"+configuration.name, {}).subscribe(response => {
        if(!response.success)
          console.dir(response.error);
        else {
          this.refreshConfiguration();
        }
      },
      (err) => {
        console.dir(err);
      });
  }

  private refreshConfiguration() {
    this.configurationService.refreshConfiguration().then(() => {
      this.reloadTable();
    })
      .catch((err) => {
        console.dir(err);
      })
  }
}
