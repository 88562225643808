import {PosNewCustomerDialogComponent} from '../pos-new-customer-dialog/pos-new-customer-dialog.component';
import { CustomerService } from '../../../services/customer.service';
import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';
import { EMPTY } from 'rxjs';
import {Customer} from '../../../customer';

@Component({
  selector: 'app-pos-search-customer',
  templateUrl: './pos-search-customer.component.html',
  styleUrls: ['./pos-search-customer.component.scss']
})
export class PosSearchCustomerComponent implements OnInit {

  optionStatus = false;
  autoSuggestCustomers = [];

  constructor( public customerService: CustomerService, public editCustomerDialog: MatDialog) {
  }
  customer = this.customerService.createEmptyCustomer();

  ngOnInit(): void {
    this.customerService.customerChangedEvent.subscribe(customer => this.customer = customer);
  }

  customerSearch(cashierInput: any) {
    const searchKeyword = cashierInput.target.value;
    if (searchKeyword.length > 2) {
      this.customerService.searchCustomer(searchKeyword).subscribe((customer) => {
        if (customer.result.items.length > 0) {
          this.autoSuggestCustomers = customer.result.items;
        }

        // note: if only one customer is found or barcode is scanned
        if (customer.length === 1) {
          this.selectedCustomerFromAutoSuggest(customer.result[0]);
        }
      });
    }
  }

  public changeStatus(option: any, isDisabled: boolean){
    option.disabled = isDisabled;
  }

  editCustomer(event: any, customer: any): void{
    this.customerService.changeLinkedCustomer(customer);
    this.editCustomerDialog.closeAll();
    this.editCustomerDialog.open(PosNewCustomerDialogComponent, {
      hasBackdrop: true,
      width: '500px',
    });
    event.stopPropagation();
  }

  selectedCustomerFromAutoSuggest(customer) {
    this.customerService.changeLinkedCustomer(customer);
    this.resetAutoSuggestCustomers(EMPTY);
  }

  resetAutoSuggestCustomers(cashierInput?: any): void{
    cashierInput.value = '';
    this.autoSuggestCustomers.length = 0;
  }

}
