<h2>Terminal Details</h2>
<mat-dialog-content class="fullWidthWrapper">
  <div class="alert alert-danger" *ngIf="visibleErrors.emptyName">Please enter the terminal name</div>
  <div class="alert alert-danger" *ngIf="visibleErrors.emptyCode">Please enter the terminal code</div>
  <div class="alert alert-danger" *ngIf="visibleErrors.emptyShop">Please select a shop</div>
  <div class="alert alert-danger" *ngIf="visibleErrors.emptyType">Please select a type</div>
  <div class="row">
    <div class="col-xs-12 col-lg-5">
      <mat-form-field appearance="fill">
        <mat-label>Name</mat-label>
        <input #terminalNameField [(ngModel)]="terminal.name" matInput type="text">
      </mat-form-field>
    </div>
    <div class="col-xs-12 col-lg-7">
      <mat-form-field appearance="fill">
        <mat-label>Code</mat-label>
        <input [(ngModel)]="terminal.code" matInput type="text">
      </mat-form-field>
    </div>
  </div>
  <div>
    <mat-form-field appearance="fill">
      <mat-label>Shop</mat-label>
      <mat-select [(ngModel)]="selectedShop" (selectionChange)="updateShop(selectedShop)" [compareWith]="shopCompareFn">
        <mat-option *ngFor="let shop of shops" [value]="{ id_shop: shop.id_shop, shop_name: shop.name }">
          {{ shop.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div>
    <mat-form-field appearance="fill">
      <mat-label>Type</mat-label>
      <mat-select [(ngModel)]="terminal.type">
          <mat-option *ngFor="let terminalType of terminal_types" [value]="terminalType">
              {{ terminalType }}
          </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</mat-dialog-content>

<mat-dialog-actions style="margin-bottom: 10px;">
    <button mat-raised-button color="primary" (click)="saveTerminal()">Save</button>
    <button mat-raised-button [mat-dialog-close]="true">Cancel</button>
</mat-dialog-actions>
