import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PaymentService } from 'src/app/services/payment.service';
import { ToolsService } from 'src/app/services/tools.service';
import { ValidationService } from 'src/app/services/validation.service';
import { payment_method_type } from 'src/environments/environment';

export interface DialogData {
  totalToPay: number;
  totalToPayUsingCard: number;
  totalToPayUsingCash: number;
  totalLeftToPay: number;
  amountReceived: number;
}

@Component({
  selector: 'app-pos-partial-pay-dialog',
  templateUrl: './pos-partial-pay-dialog.component.html',
  styleUrls: ['./pos-partial-pay-dialog.component.scss']
})
export class PosPartialPayDialogComponent {

  inputCardHigherAmountAdded = false;

  constructor(
    public dialogRef: MatDialogRef<PosPartialPayDialogComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private validationService: ValidationService,
    private paymentService: PaymentService,
    private toolsService: ToolsService
  ) {
    this.data.totalToPayUsingCard = null;
    this.data.totalToPayUsingCash = null;
    if (!this.paymentService.isPartialPayment()) {
      this.paymentService.paymentType = payment_method_type.SPLIT_PAYMENT_TYPE;
    }
  }

  public onNoClick(paymentOptionSelected = ""): void {
    this.inputCardHigherAmountAdded = this.data.totalToPayUsingCard == null && this.data.totalToPayUsingCash == null;
    if (this.inputCardHigherAmountAdded) {
      return;
    }
    
    this.closeDialog(paymentOptionSelected);
  }

  public closeDialog(paymentOptionSelected = ""): void {
    this.data.totalToPay = this.toolsService.formatNumber(this.data.totalToPay);
    this.updateTotalLeftToPay();
    
    this.dialogRef.close({
      paymentOptionSelected: paymentOptionSelected,
      totalToPay: this.toolsService.formatNumber(this.data.totalToPay),
      amountReceived: this.getAmountReceivedToPay(paymentOptionSelected),
      totalLeftToPay: this.data.totalLeftToPay,
    });
  }

  public getAmountReceivedToPay(paymentOptionSelected): number {
    const amountReceivedToPay = this.getAmountReceivedToPayForPaymentOption(paymentOptionSelected);
    return this.toolsService.formatNumber(amountReceivedToPay);
  }

  public getAmountReceivedToPayForPaymentOption(paymentOptionSelected): number {
    let amountReceived = 0;

    if (paymentOptionSelected == "card") {
        amountReceived = this.data.totalToPayUsingCard;
    }

    if (paymentOptionSelected == "cash") {
        amountReceived = this.data.totalToPayUsingCash;
    }

    return amountReceived;
  }

  public updateTotalToPayUsingCard($event): void {
    this.data.totalToPay = this.toolsService.formatNumber(this.data.totalToPay);
    // if amount is 0 and we add a number after that 0, we ignore that 0 (eg: 01 will become 1)
    if (this.ignoreFirstDigit($event)) {
      $event.target.value = $event.data;
    }

    if (this.validationService.isAmountToPayInvalid($event.target.value)) {
      $event.target.value = 0;
    }

    this.inputCardHigherAmountAdded = this.data.totalToPayUsingCard > this.data.totalToPay;
    if (this.inputCardHigherAmountAdded) {
      $event.target.value = this.data.totalToPay;
    }

    this.data.totalToPayUsingCard = parseFloat($event.target.value);
    this.data.totalToPayUsingCash = 0;

    if (this.data.totalToPayUsingCard < this.data.totalToPay) {
        this.data.totalToPayUsingCash = this.toolsService.formatNumber(this.data.totalToPay - this.data.totalToPayUsingCard);
    }

    this.updateTotalLeftToPay();
  }

  public ignoreFirstDigit($event): boolean {
    return this.isFirstDigitZero($event) && !this.includeDot($event);
  }

  public isFirstDigitZero($event): boolean {
    return $event.target.value[0] == 0;
  }

  public includeDot($event): boolean {
    return $event.target.value.includes('.');
  }

  public updateTotalToPayUsingCash($event): void {
    this.data.totalToPay = this.toolsService.formatNumber(this.data.totalToPay);
    // if amount is 0 and we add a number after that 0, we ignore that 0 (eg: 01 will become 1)
    if (this.ignoreFirstDigit($event)) {
      $event.target.value = $event.data;
    }

    if (this.validationService.isAmountToPayInvalid($event.target.value)) {
      $event.target.value = 0;
    }
    
    this.data.totalToPayUsingCash = parseFloat($event.target.value);
    this.data.totalToPayUsingCard = 0;

    if (this.data.totalToPayUsingCash < this.data.totalToPay) {
      this.data.totalToPayUsingCard = this.toolsService.formatNumber(this.data.totalToPay - this.data.totalToPayUsingCash);
    }

    this.updateTotalLeftToPay();
  }

  public updateTotalLeftToPay(): void {
    this.data.totalLeftToPay = this.data.totalToPay - (this.data.totalToPayUsingCard + this.data.totalToPayUsingCash);
    
    if (this.data.totalLeftToPay < 0) {
      this.data.totalLeftToPay = 0;
    }

    this.data.totalLeftToPay = this.toolsService.formatNumber(this.data.totalLeftToPay);
  }

  public get inputFieldMaxLength() {
    return this.validationService.getInputFieldMaxLength();
  }
}
