import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {ReceiptPrinter} from "../receipt-printer";
import {VoucherDetails} from "../../voucher-details";
import {ConfigurationService} from "./configuration.service";
import {AuthenticationService} from "./authentication.service";
import {CustomerService} from "./customer.service";
import {Shop} from "../shop";
import {Customer} from "../customer";
import {VatTotal} from "../cart-product";

@Injectable({
    providedIn: 'root',
})
export class ReceiptService {
    constructor(private router: Router, private configurationService: ConfigurationService, private authService: AuthenticationService, private customerService: CustomerService) {
    }

    printer: ReceiptPrinter = this.configurationService.defaultPrinter;
    shop:Shop = this.configurationService.shop;
    agent = this.authService.agent;
    private outputToConsole: boolean = false;

    private get IssueID(): number {
        let result = localStorage.getItem('BixolonIssueId');
        if(!result)
            return 1;
        return Number(result);
    }

    private set IssueID(issueId: number) {
        localStorage.setItem('BixolonIssueId', String(issueId));
    }

    private formatTotalLine(maxWidth: number, text: string, value: number, dashes: boolean = false): string {
        const TOTAL_VALUE_WIDTH = 10;
        const TOTAL_TEXT_WIDTH = 10;
        const TOTAL_EMPTY_WIDTH = maxWidth - TOTAL_TEXT_WIDTH - TOTAL_VALUE_WIDTH;

        const empty = ''.padEnd(TOTAL_EMPTY_WIDTH, ' ');

        if(dashes)
            return empty.padEnd(maxWidth, '-')+"\n";

        const formattedText = text.substring(0, TOTAL_TEXT_WIDTH).padEnd(TOTAL_TEXT_WIDTH, ' ');
        const formattedValue = (Math.round(value * 100) / 100).toFixed(2).padStart(TOTAL_VALUE_WIDTH, ' ');
        return empty+formattedText+formattedValue+'\n';
    }


    PrintReceipt(cartLines: any[], subtotal:number, vatLines: VatTotal[], vouchers: VoucherDetails[], discount: number, total: number, header: string = null, footer: string  = null, callback: CallableFunction = (result) => {console.dir(result)}) {

        this.outputToConsole = this.configurationService.getConfig('RECEIPT_PRINTER_ENABLE_CONSOLE', false);

        return new Promise((resolve, reject) => {
            let maxWidth = 32;
            if(this.printer.paper_size === '3in')
                maxWidth = 42;
            if(this.printer.paper_size === '4in')
                maxWidth = 62;

            const PRICE_COLUMN_WIDTH = 13;
            const QUANTITY_COLUMN_WIDTH = 4;
            const NAME_COLUMN_WIDTH = maxWidth - PRICE_COLUMN_WIDTH - QUANTITY_COLUMN_WIDTH;




            this.setPosId(this.IssueID);
            this.checkPrinterStatus();

            const date = new Date();
            if(!header)
                header = this.shop.receipt_header;
            header = header
                .replace(/%date%/g, date.toLocaleDateString())
                .replace(/%time%/g, date.toLocaleTimeString())
                .replace(/%shop_name%/g, this.shop.name)
                .replace(/%agent_name%/g, this.agent.firstname+" "+this.agent.lastname)
                .replace(/%agent_id%/g, this.agent.id_agent)
                .replace(/%customer_name%/g, this.customerService.linkedCustomer.FirstName+" "+this.customerService.linkedCustomer.LastName)
                .replace(/%customer_email%/g, this.customerService.linkedCustomer.Email)
                .replace(/%customer_id%/g, this.customerService.linkedCustomer.ID);

            if(!footer)
                footer = this.shop.receipt_footer;
            footer = footer
                .replace(/%date%/g, date.toLocaleDateString())
                .replace(/%time%/g, date.toLocaleTimeString())
                .replace(/%shop_name%/g, this.shop.name)
                .replace(/%agent_name%/g, this.agent.firstname+" "+this.agent.lastname)
                .replace(/%agent_id%/g, this.agent.id_agent)
                .replace(/%customer_name%/g, this.customerService.linkedCustomer.FirstName+" "+this.customerService.linkedCustomer.LastName)
                .replace(/%customer_email%/g, this.customerService.linkedCustomer.Email)
                .replace(/%customer_id%/g, this.customerService.linkedCustomer.ID);


            this.printText(header+"\n\n", 0, 0, false, false, false, 0, 0);
            //print initial dashes
            this.printText(''.padEnd(maxWidth, '-')+"\n", 0, 0, false, false, false, 0, 0);

            //print table header
            let line = 'Item Name'.padEnd(NAME_COLUMN_WIDTH, ' ') + ' Qty       Price ';
            this.printText(line+"\n", 0, 0, false, false, false, 0, 0);

            //print each product
            for(const cartLine of cartLines) {
                const name = cartLine.Name.en.substring(0, NAME_COLUMN_WIDTH).padEnd(NAME_COLUMN_WIDTH, ' ');
                const quantity = cartLine.quantity.toString().padStart(4, ' ');
                const price = (Math.round(cartLine.totalprice * 100) / 100).toFixed(2).padStart(PRICE_COLUMN_WIDTH, ' ');
                this.printText(name+quantity+price+"\n", 0, 0, false, false, false, 0, 0);
            }

            this.printText(''.padEnd(maxWidth, '-')+"\n", 0, 0, false, false, false, 0, 0);

            this.printText(this.formatTotalLine(maxWidth, 'Subtotal:', subtotal), 0, 0, false, false, false, 0, 0);
            for(const voucher of vouchers) {
                this.printText(this.formatTotalLine(maxWidth, voucher.code, voucher.totalValue), 0, 0, false, false, false, 0, 0);
            }
            for(const vat of vatLines) {
              this.printText(this.formatTotalLine(maxWidth, vat.Name, vat.Value), 0, 0, false, false, false, 0, 0);
            }
            if(discount != 0)
                this.printText(this.formatTotalLine(maxWidth, 'Discount:', discount), 0, 0, false, false, false, 0, 0);
            this.printText(this.formatTotalLine(maxWidth, '', 0, true), 0, 0, false, false, false, 0, 0);
            this.printText(this.formatTotalLine(maxWidth, 'Total:', total), 0, 0, false, false, false, 0, 0);


            this.printText("\n\n"+footer+"\n", 0, 0, false, false, false, 0, 0);


            this.cutPaper(1);

            const strSubmit = this.getPosData();

            this.IssueID++;
            try {
                this.requestPrint(this.printer.logical_name, strSubmit, callback);
            }
            catch (e) {
                reject(e);
                return;
            }
            resolve();
            return;
        });
    }

    toHexBinary(s){
        let l=s.length,r=new Array(l),i;
        for(i=0;i<l;i++){
            r[i]=("0"+s.charCodeAt(i).toString(16)).slice(-2)
        }
        return r.join("")
    }

    makeResultInquiryData(requestId, responseId, timeout){
        return "{\"RequestID\":"+requestId+",\"ResponseID\":\""+responseId+"\",\"Timeout\":"+timeout+"}";
    }

    requestMSRData(strPrinterName, timeout, _callback) {
        let serverURL = this.getServerURL().url;
        let inquiryData = "{\"Timeout\":"+timeout+"}";

        let requestURL = serverURL + strPrinterName +"/requestMSRData";
        let xmlHttpCheck = new XMLHttpRequest();

        xmlHttpCheck.open('POST', requestURL, true);
        xmlHttpCheck.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        xmlHttpCheck.send(inquiryData);
        xmlHttpCheck.onreadystatechange = function() {
            if (xmlHttpCheck.readyState == 4 && xmlHttpCheck.status == 200) {
                let res = JSON.parse(xmlHttpCheck.responseText);

                let track1 = res.Track1;
                let track2 = res.Track2;
                let track3 = res.Track3;

                _callback(res.Result, track1, track2, track3);
            }
            else if (xmlHttpCheck.readyState == 4 && xmlHttpCheck.status == 404) {
                _callback("No printers");
            }
            else if(xmlHttpCheck.readyState == 4) {
                _callback("Cannot connect to server");
            }
        }
    }

    checkResult(method, strPrinterName, requestId, responseId, _callback) {
        let serverURL = this.getServerURL().url;
        let requestURL = serverURL + strPrinterName +"/checkStatus";
        let inquiryData = this.makeResultInquiryData(requestId, responseId, 30);

        let xmlHttpCheck = new XMLHttpRequest();

        xmlHttpCheck.open(method, requestURL, true);
        xmlHttpCheck.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        xmlHttpCheck.send(inquiryData);
        xmlHttpCheck.onreadystatechange = () => {
            if (xmlHttpCheck.readyState == 4 && xmlHttpCheck.status == 200) {
                let res = JSON.parse(xmlHttpCheck.responseText);
                let ret = res.Result;

                if(ret.search("ready") >= 0 || ret.search("progress") >= 0)	{
                    this.checkResult(method, strPrinterName, requestId, responseId, _callback);
                }
                else {
                    _callback(res.ResponseID + ":"+ ret);
                }
            }
            else if (xmlHttpCheck.readyState == 4 && xmlHttpCheck.status == 404) {
                _callback("No printers");
            }
            else if(xmlHttpCheck.readyState == 4) {
                _callback("Cannot connect to server");
            }
        }
    }

    requestPrint(strPrinterName, strSubmit, _callback) {
        _callback("");
        let serverURL = this.getServerURL().url;
        let requestURL = serverURL + strPrinterName;
        let xmlHttpReq = new XMLHttpRequest();

        xmlHttpReq.open('POST', requestURL, true);
        xmlHttpReq.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        xmlHttpReq.send(strSubmit);

        xmlHttpReq.onreadystatechange = () => {
            if (xmlHttpReq.readyState == 4 && xmlHttpReq.status == 200) {
                let res = JSON.parse(xmlHttpReq.responseText);
                let ret = res.Result;
                if(ret.search("ready") >= 0 || ret.search("progress") >= 0)	{
                    this.checkResult('POST', strPrinterName, res.RequestID, res.ResponseID, _callback);
                }
                else if(ret.search("duplicated") >= 0) {
                    _callback(res.Result);
                }
            }
            else if (xmlHttpReq.readyState == 4 && xmlHttpReq.status == 404) {
                _callback("No printers");
            }
            else if(xmlHttpReq.readyState == 4) {
                _callback("Cannot connect to server");
            }
        }
    }

    getServerURL() {
        let serverURL = this.printer.url;
        return {
            url: serverURL
        };
    }


    getBrowser() {
        let ua = navigator.userAgent, tem, M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
        if (/trident/i.test(M[1])) {
            tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
            return { name: 'IE', version: (tem[1] || '') };
        }
        if (M[1] === 'Chrome') {
            tem = ua.match(/\bOPR|Edge\/(\d+)/)
            if (tem != null) { return { name: 'Opera', version: tem[1] }; }
        }
        M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
        if ((tem = ua.match(/version\/(\d+)/i)) != null) { M.splice(1, 1, tem[1]); }
        return {
            name: M[0],
            version: M[1]
        };
    }

    isEmpty(data) {
        if (typeof data == "undefined"
            || data == null
            || data == "")
            return true;

        else 	return false;
    }


    pos_data = {id:0, functions:{}};
    pos_func = {};
    incPosNum = 0;

    getPosData() {
        this.pos_data.functions = this.pos_func;
        this.pos_func = {};
        this.incPosNum = 0;

        return JSON.stringify(this.pos_data);
    }

    setPosId(setId) {
        this.pos_data.id = setId;
    }

    checkPrinterStatus() {
        (this.pos_func)["func"+this.incPosNum] = {checkPrinterStatus: []};
        this.incPosNum++;
    }

    directPrintText(text) {
        (this.pos_func)["func"+this.incPosNum] = {directPrintText: [text]};
        this.incPosNum++;
    }

    directPrintHex(hexString) {
        (this.pos_func)["func"+this.incPosNum] = {directPrintHex: [hexString]};
        this.incPosNum++;
    }

    cutPaper(bFeedCut = 0) {
        (this.pos_func)["func"+this.incPosNum] = {cutPaper: [bFeedCut]};
        this.incPosNum++;
    }

    setInternationalCharset(ics) {
        (this.pos_func)["func"+this.incPosNum] = {setInternationalCharset: [ics]};
        this.incPosNum++;
    }

    setCharacterset(charset) {
        (this.pos_func)["func"+this.incPosNum] = {setCharacterset: [charset]};
        this.incPosNum++;
    }

    printText(text, horizontal, vertical, bold, invert, underline, fonttype, alignment) {
        if(this.outputToConsole)
            console.log(text);
        (this.pos_func)["func"+this.incPosNum] = {printText: [text, horizontal, vertical, bold, invert, underline, fonttype, alignment]};
        this.incPosNum++;
    }

    print1DBarcode(data, symbol, barWidth, height, hriPosition, alignment) {
        (this.pos_func)["func"+this.incPosNum] = {print1DBarcode: [data, symbol, barWidth, height, hriPosition, alignment]};
        this.incPosNum++;
    }

    printPDF417(data, symbol, alignment, columnNumber, rowNumber, moduleWidth, moduleHeight, eccLevel) {
        this.pos_func["func"+this.incPosNum] = {printPDF417: [data, symbol, alignment, columnNumber, rowNumber, moduleWidth, moduleHeight, eccLevel]};
        this.incPosNum++;
    }

    printQRCode(data, model, alignment, moduleSize, eccLevel) {
        this.pos_func["func"+this.incPosNum] = {printQRCode: [data, model, alignment, moduleSize, eccLevel]};
        this.incPosNum++;
    }

    printGS1Databar(data, symbol, alignment, moduleSize) {
        this.pos_func["func"+this.incPosNum] = {printGS1Databar: [data, symbol, alignment, moduleSize]};
        this.incPosNum++;
    }

    printDataMatrix(data, alignment, moduleSize) {
        this.pos_func["func"+this.incPosNum] = {printDataMatrix: [data, alignment, moduleSize]};
        this.incPosNum++;
    }

    printCompositeBarcode(data, symbol, alignment, moduleSize) {
        this.pos_func["func"+this.incPosNum] = {printCompositeBarcode: [data, symbol, alignment, moduleSize]};
        this.incPosNum++;
    }

    printBitmap(imagedata, width, alignment, dither) {
        this.pos_func["func"+this.incPosNum] = {printBitmap: [imagedata, width, alignment, dither]};
        this.incPosNum++;
    }

    printBitmapFile(filepath, width, alignment, dither) {
        this.pos_func["func"+this.incPosNum] = {printBitmapFile: [filepath, width, alignment, dither]};
        this.incPosNum++;
    }

    printPDFFile(filepath, pageNumber, width, alignment, dither) {
        this.pos_func["func"+this.incPosNum] = {printPDFFile: [filepath, pageNumber, width, alignment, dither]};
        this.incPosNum++;
    }

    pagemodeBegin() {
        this.pos_func["func"+this.incPosNum] = {pagemodeBegin: []};
        this.incPosNum++;
    }

    pagemodePrintArea(width, height) {
        this.pos_func["func"+this.incPosNum] = {pagemodePrintArea: [width, height]};
        this.incPosNum++;
    }

    pagemodePrintPosition(x, y) {
        this.pos_func["func"+this.incPosNum] = {pagemodePrintPosition: [x, y]};
        this.incPosNum++;
    }

    pagemodePrintDirection(direction) {
        this.pos_func["func"+this.incPosNum] = {pagemodePrintDirection: [direction]};
        this.incPosNum++;
    }

    pagemodeEnd() {
        this.pos_func["func"+this.incPosNum] = {pagemodeEnd: []};
        this.incPosNum++;
    }

    openDrawer(pinNumber) {
        this.pos_func["func"+this.incPosNum] = {openDrawer: [pinNumber]};
        this.incPosNum++;
    }
}
