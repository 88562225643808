<app-pos-header title="Settings"></app-pos-header>

    <mat-card>
      <mat-card-header>
        <mat-card-title class="mb-3">Agent Settings</mat-card-title>
        <mat-card-subtitle>
          <span class="settings_agent"><mat-icon class="user-icon mr-2">account_circle</mat-icon>ID: {{agent.id_agent}}, {{agent.username}}</span>
        </mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
          <div class="col-xs-12 col-sm-8 col-md-6 col-lg-4 col-xl-3">
            <div class="alert alert-danger" *ngIf="visibleErrors.emptyFirstName">Please enter the first name</div>
            <div class="alert alert-danger" *ngIf="visibleErrors.emptyLastName">Please enter the last name</div>
            <div class="alert alert-danger" *ngIf="visibleErrors.emptyShop">Please select a shop</div>

            <input disabled [(ngModel)]="agent.id_agent" matInput type="text" hidden="true">
            <input disabled [(ngModel)]="agent.username" matInput type="text" hidden="true">

            <mat-form-field appearance="fill">
              <mat-label>First Name</mat-label>
              <input #firstNameField [(ngModel)]="agent.firstname" matInput type="text">
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>Last Name</mat-label>
              <input [(ngModel)]="agent.lastname" matInput type="text">
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>Default Terminal</mat-label>
              <mat-select [(ngModel)]="selectedTerminal" (selectionChange)="updateTerminal(selectedTerminal)" [compareWith]="terminalCompareFn">
                <mat-option *ngFor="let terminal of terminals" [value]="{ id_terminal: terminal.id_terminal, terminal_name: terminal.name, code: terminal.code }">
                  {{ terminal.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>Receipt Printer</mat-label>
              <mat-select [(ngModel)]="selectedPrinter" (selectionChange)="updatePrinter(selectedPrinter)" [compareWith]="printerCompareFn">
                <mat-option *ngFor="let printer of printers" [value]="{ id_receipt_printer: printer.id_receipt_printer, name: printer.logical_name }">
                  {{ printer.logical_name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>Default Country</mat-label>
              <mat-select [(ngModel)]="selectedCountry" (selectionChange)="updateCountry(selectedCountry)" [compareWith]="countryCompareFn">
                <mat-option *ngFor="let country of countries" [value]="{ id_country: country.id_country, country_name: country.name }">
                  {{ country.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>Change Password</mat-label>
              <input [(ngModel)]="newPassword" matInput type="password" autocomplete="new-password">
            </mat-form-field>
          </div>

      </mat-card-content>
      <mat-card-actions>
        <button mat-raised-button color="primary" (click)="saveSettings()" style="min-width: 200px; margin-left: 15px;margin-bottom: 15px;">Save</button>
      </mat-card-actions>
    </mat-card>
