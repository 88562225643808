<app-pos-header title="Cart"></app-pos-header>

<app-pos-product-search></app-pos-product-search>

<div class="row">
  <div class="col-lg-8">
    <mat-card class="mb-3">
      <app-pos-cart-list></app-pos-cart-list>
      <app-pos-price-calculation></app-pos-price-calculation>
    </mat-card>
  </div>
  <div class="col-lg-4">
    <mat-card>
      <div class="user_search mb-1">
        <div class="user_search__search">
          <app-pos-search-customer></app-pos-search-customer>
        </div>
        <div class="user_search__btn">
          <app-pos-new-customer></app-pos-new-customer>
        </div>
      </div>
      <div class="mb-4">
          <app-pos-linked-customer-to-order></app-pos-linked-customer-to-order>
      </div>

      <div class="row">
        <div class="col-12 col-md-6 mb-4">
          <button (click)="openDiscountDialog()" mat-raised-button class="w-100">Discount</button>
        </div>
        <div class="col-12 col-md-6 mb-4">
          <button (click)="openVoucherDialog()" mat-raised-button class="w-100" >Voucher</button>
        </div>
      </div>
      <app-pos-payments></app-pos-payments>
    </mat-card>
  </div>
</div>
