import {HttpClient} from '@angular/common/http';
import {EventEmitter, Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {LoginComponent} from '../core/login/login.component';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  constructor(private http: HttpClient, public dialog: MatDialog, private router: Router) {
    setInterval(() => {
      if(this.agent && this.token)
        this.extendToken().catch(() => {});
    }, 15 * 60000);
  }

  endpointLogin = 'user/login';
  endpointGetLogged = 'user/getLoggedAgent';
  endpointExtendToken = 'user/extendToken';

  public agent;
  public dialogRef;

  public get token(): string {
    return localStorage.getItem('AuthenticationServiceToken');
  }

  public set token(token: string) {
    localStorage.setItem('AuthenticationServiceToken', token);
  }

  /**
   * Subscribe to this event to receive notifications when the logged in state has changed.
   * This event is fired in these situations:
   *  - when the user successfully logs in through the login dialog.
   *  - when the token is successfully validated and the agent data is being stored
   *  - when logout() is called
   *  - when an attempt to extend the token has failed
   *  - when the HTTP interceptor receives a 401 response.
   * As per the specifications, please do NOT use this event to clear the cart or customer data.
   */
  public loggedInStateChanged: EventEmitter<boolean> = new EventEmitter<boolean>();


  logout() {
    this.token = null;
    this.agent = null;
    this.loggedInStateChanged.emit(false);
  }

  /**
   * Check if there is a user logged in. Mainly to be used by AuthGuard.
   * If there is an agent in memory, the token will *NOT* be validated.
   * If there is no agent, but a token is present, it will be validated and extended.
   * No other changes made to localStorage. No dialogs shown or redirects.
   */
  async checklogin(): Promise<boolean> {
    if(this.agent)
      return true;
    if(!this.token)
      return false;
    return await this.validateToken();
  }

  /**
   * Display the login popup. If logged in successfully,
   * the agent and token will be stored. Otherwise, no changes are made to localStorage.
   * This method does not do any redirects.
   */
  async displayLogin() {
    if(this.dialogRef)
      return false;
    this.dialogRef = this.dialog.open(LoginComponent, {
      width: '300px',
      hasBackdrop: true,
      disableClose: true,
      data: { user: '', password: '', loginCallback: (username, password) => {return this.login(username, password)}},
    });
    let response = await this.dialogRef.afterClosed().toPromise();
    if(!response.result)
      return false;
    this.agent = response.agent;
    this.token = response.data.accessToken;
    this.dialogRef = null;
    this.loggedInStateChanged.emit(true);
    if(this.router.url === '/')
      this.redirectToCart();
    return true;
  }

  /**
   * Update the agent info using the existing authentication token.
   */
  public updateAgentData() {
    return this.validateToken();
  }

  /**
   * Make a request to validate the existing token in LocalStorage.
   * If the token is valid, it will be extended (replaced) and the agent will be stored.
   * No other LocalStorage changes or redirects are made.
   */
  private async validateToken() {
    return new Promise<boolean>((resolve, reject) => {
      this.http.post<any>(
        environment.APP_ENVIRONMENT + this.endpointGetLogged,
        {}
      ).subscribe(
        (response) => {
          if (response.result) {
            this.agent = response.agent;
            this.loggedInStateChanged.emit(true);
            this.extendToken().then(resolve, reject);
          } else {
            resolve(false);
          }
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  /**
   * Replace the existing token in LocalStorage with a newer one.
   * Can be called on an interval to keep the user logged in.
   * This method assumes that there is a valid token in LocalStorage.
   * @private
   */
  private async extendToken() {
    let response = await this.http.post<any>(environment.APP_ENVIRONMENT + this.endpointExtendToken, {}).toPromise();
    if (response.result) {
      this.token = response.data.accessToken;
      return true;
    }
    else {
      this.loggedInStateChanged.emit(false);
      return false;
    }
  }

  /**
   * Validate the username and password through an HTTP request.
   * No values are stored, no redirects are made.
   * This method returns a promise for the entire HTTP response, not just a boolean.
   * @param user the username (email address)
   * @param password the user's password
   */
  private async login(user: string, password: string) {
    return await this.http.post<any>(
        environment.APP_ENVIRONMENT + this.endpointLogin,
        {
          user: user,
          pass: password,
        }
    ).toPromise();
  }

  redirectToCart() {
    this.router.navigate(['pos']);
  }

  redirectToHomePage() {
    this.router.navigate(['/']);
  }
}
