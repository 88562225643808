import {AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {Station} from "../../../station";


@Component({
    selector: 'app-station-dialog',
    templateUrl: './station-dialog.component.html',
    styleUrls: ['./station-dialog.component.scss'],
})
export class StationDialogComponent implements OnInit, AfterViewInit {
    @ViewChild('stationNameField') stationNameField: ElementRef;

    public visibleErrors = {
        emptyName: false,
        emptyCode: false,
        emptyShop: false,

        anyError: function() {
            return this.emptyName
                || this.emptyCode
                || this.emptyShop
        }
    }

    station: Station = new Station();
    shops: [] = [];

    constructor(public dialogRef: MatDialogRef<StationDialogComponent>, @Inject(MAT_DIALOG_DATA) public originalStation: Station, private http: HttpClient) {
        this.station.cloneFrom(originalStation);
        this.selectedShop = {id_shop: this.station.id_shop, shop_name: this.station.shop_name}
        this.loadShops();
    }

    ngOnInit(): void {
    }


    public saveStation() {

        this.visibleErrors.emptyName = !this.station.name;
        this.visibleErrors.emptyCode = !this.station.code;
        this.visibleErrors.emptyShop = !this.station.id_shop;
        if(this.visibleErrors.anyError())
            return;

        this.http.post<any>(environment.APP_ENVIRONMENT + environment.STATIONS_ENDPOINT + "/save", this.station).subscribe(response => {
                if (!response.success)
                    console.dir(response.error);
                else {
                    this.originalStation.cloneFrom(this.station);
                    this.dialogRef.close();
                }
            },
            (err) => {
                console.dir(err);
            });
    }

    public loadShops() {
        this.http.get<any>(environment.APP_ENVIRONMENT + environment.SHOPS_ENDPOINT + "/list").subscribe(response => {
                if (!response.success)
                    console.dir(response.error);
                else {
                    this.shops = response.shops;
                }
            },
            (err) => {
                console.dir(err);
            });
    }

    selectedShop: any;

    updateShop(selectedShop: any) {
        this.station.id_shop = selectedShop.id_shop;
        this.station.shop_name = selectedShop.shop_name;
    }

    shopCompareFn(shop1: any, shop2: any) {
        return shop1 && shop2 && shop1.id_shop === shop2.id_shop;
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.stationNameField.nativeElement.focus();
        });
    }

}
