import {AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Terminal, TERMINAL_TYPES} from "../../../terminal";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";


@Component({
  selector: 'app-terminal-dialog',
  templateUrl: './terminal-dialog.component.html',
  styleUrls: ['./terminal-dialog.component.scss'],
})
export class TerminalDialogComponent implements OnInit, AfterViewInit {
  @ViewChild('terminalNameField') terminalNameField: ElementRef;

  public visibleErrors = {
    emptyName: false,
    emptyCode: false,
    emptyShop: false,
    emptyType: false,

    anyError: function() {
      return this.emptyName
          || this.emptyCode
          || this.emptyShop
          || this.emptyType
    }
  }
  terminal: Terminal = new Terminal();
  shops: [] = [];
  terminal_types = Object.values(TERMINAL_TYPES);

  constructor(public dialogRef: MatDialogRef<TerminalDialogComponent>, @Inject(MAT_DIALOG_DATA) public originalTerminal: Terminal, private http: HttpClient) {
    this.terminal.cloneFrom(originalTerminal);
    this.selectedShop = {id_shop: this.terminal.id_shop, shop_name: this.terminal.shop_name}
    this.loadShops();
  }

  ngOnInit(): void {
  }


  public saveTerminal() {
    this.visibleErrors.emptyName = !this.terminal.name;
    this.visibleErrors.emptyCode = !this.terminal.code;
    this.visibleErrors.emptyShop = !this.terminal.id_shop;
    this.visibleErrors.emptyType = !this.terminal.type;
    if(this.visibleErrors.anyError())
      return;

    this.http.post<any>(environment.APP_ENVIRONMENT + environment.TERMINALS_ENDPOINT + "/save", this.terminal).subscribe(response => {
        if (!response.success)
          console.dir(response.error);
        else {
          this.originalTerminal.cloneFrom(this.terminal);
          this.dialogRef.close();
        }
      },
      (err) => {
        console.dir(err);
      });
  }

  public loadShops() {
    this.http.get<any>(environment.APP_ENVIRONMENT + environment.SHOPS_ENDPOINT + "/list").subscribe(response => {
        if (!response.success)
          console.dir(response.error);
        else {
          this.shops = response.shops;
        }
      },
      (err) => {
        console.dir(err);
      });
  }

  selectedShop: any;

  updateShop(selectedShop: any) {
    this.terminal.id_shop = selectedShop.id_shop;
    this.terminal.shop_name = selectedShop.shop_name;
  }

  shopCompareFn(shop1: any, shop2: any) {
    return shop1 && shop2 && shop1.id_shop === shop2.id_shop;
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.terminalNameField.nativeElement.focus();
    });
  }

}
