import {Component, Input, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { CartService } from 'src/app/services/cart.service';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { CustomerService } from 'src/app/services/customer.service';
import { OrderService } from 'src/app/services/order.service';
import {AgentType} from "../../../agent";

@Component({
  selector: 'app-pos-header',
  templateUrl: './pos-header.component.html',
  styleUrls: ['./pos-header.component.scss']
})
export class PosHeaderComponent implements OnInit {

  @Input() title = '';
  @Input() tagline = ''
  routerUrl: string;
  currentDate = this.getCurrentDate();
  agentName: string;
  logoUrl: string = "";

  isAdmin = this.authService.agent.type == AgentType.Admin;

  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private cartService: CartService,
    private customerService: CustomerService,
    public configurationService: ConfigurationService,
    private orderService: OrderService
    )
  {
    this.routerUrl = this.router.url;
    this.agentName = this.authService.agent.firstname + " " + this.authService.agent.lastname;
    this.loadLogo();
  }

  ngOnInit(): void {
    this.initRealTimeDateUpdate();
  }

  logout() {
    this.cartService.emptyCart();
    this.orderService.emptyOrderData();
    this.customerService.resetLinkedCustomer();
    this.authService.logout();
    this.router.navigate(['/']);
  }

  public redirectToCart(){
    this.authService.redirectToCart();
  }

  initRealTimeDateUpdate() {
    setInterval(() => {
      this.currentDate = this.getCurrentDate();
    }, 1000);
  }

  getCurrentDate() {
    return (new Date()).toLocaleString();
  }

  loadLogo() {
    this.logoUrl = this.configurationService.getLogoUrl();
    if (!this.logoUrl) {
      this.configurationService.loadWebshopConfigurations().then(() => {
        this.logoUrl = this.configurationService.getLogoUrl();
      })
      .catch((err) => {
          console.dir(err);
      });
    }
  }
}
