<app-pos-header title="Reports" *ngIf="!renderTemplatePDF"></app-pos-header>

<!-- Button-toggle-group -->
<div class="row row-flex" *ngIf="!renderTemplatePDF">
  <div class="col-sm-8 col-md-9 col-lg-9 mb-3">
    <mat-button-toggle-group name="fontStyle" aria-label="Font Style" value="{{ dailyReports }}" class="reports_options mb-3">
      <mat-button-toggle value="{{ dailyReports }}" (change)="loadReport($event.value, 0)">
        {{ dailyReports }}
      </mat-button-toggle>
      <mat-button-toggle value="{{ salesReports }}" (change)="loadReport($event.value, 0)">
        {{ salesReports }}
      </mat-button-toggle>
      <mat-button-toggle value="{{ endOfDay }}" (change)="loadReport($event.value, 0)">
        {{ endOfDay }}
      </mat-button-toggle>
    </mat-button-toggle-group>

    <div class="filter_search" *ngIf="!renderTemplatePDF && !isEndOfDaySelected">
      <div class="row row-flex">
        <div class="col-sm-6 col-lg-4 pr-0">
          <mat-form-field appearance="fill">
            <mat-label>Filter by:</mat-label>
            <mat-select [(value)]="selectedFilterOption" (selectionChange)="processSelectedFilterOption()">
              <mat-option *ngFor="let filterOption of filterOptions | keyvalue" value="{{ filterOption.key }}">
                {{ filterOption.value }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-6 col-lg-4 pr-0">
          <mat-form-field appearance="fill" *ngIf="selectedFilterOption">
            <mat-label>Search</mat-label>
            <input matInput placeholder="search..." (input)="processFilterOrders($event)">
          </mat-form-field>
        </div>
      </div>
    </div>

  </div>
  <div class="col-sm-4 col-md-3 col-lg-3 ml-auto text-right mb-3" *ngIf="!isEndOfDaySelected">
    <button mat-raised-button class="mr-2" (click)="printReport()"><mat-icon>print</mat-icon> <span class="hidden-md-down">Print</span></button>
    <button mat-raised-button><mat-icon>redo</mat-icon> <span class="hidden-md-down">Refund</span></button>
  </div>
</div>



<!-- Date range picker -->
<div class="row" *ngIf="displayDatePicker &&!renderTemplatePDF">
    <div class="col-md-3">
        <p class="input-group">
            <mat-form-field>
              <mat-label>Start Date</mat-label>
              <input matInput [matDatepicker]="picker1" [formControl]="dateStart" (dateChange)="addEventDateStart('change', $event)"/>
              <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
              <mat-datepicker #picker1></mat-datepicker>
            </mat-form-field>
        </p>
    </div>
    <div class="col-md-3">
        <p class="input-group">
          <mat-form-field>
            <mat-label>End Date</mat-label>
            <input matInput [matDatepicker]="picker2" [formControl]="dateEnd" (dateChange)="addEventDateEnd('change', $event)"/>
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
          </mat-form-field>
        </p>
    </div>
</div>

<!-- Table -->
<div class="example-table-container">
  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

    <!-- ID Column -->
    <ng-container matColumnDef="Crt no">
      <th mat-header-cell *matHeaderCellDef>Crt no</th>
      <td mat-cell *matCellDef="let element"> {{ element.crt_no }} </td>
    </ng-container>

    <!-- Document type Column -->
    <ng-container matColumnDef="Document type">
      <th mat-header-cell *matHeaderCellDef>Document type</th>
      <td mat-cell *matCellDef="let element"> {{ element.document_type }} </td>
    </ng-container>

    <!-- User Column -->
    <ng-container matColumnDef="User">
      <th mat-header-cell *matHeaderCellDef>User</th>
      <td mat-cell *matCellDef="let element"> {{ element.user }} </td>
    </ng-container>

    <!-- Date Column -->
    <ng-container matColumnDef="Date">
      <th mat-header-cell *matHeaderCellDef>Date</th>
      <td mat-cell *matCellDef="let element"> {{ element.date }} </td>
    </ng-container>

    <!-- Order No Column -->
    <ng-container matColumnDef="Order No">
      <th mat-header-cell *matHeaderCellDef>Order No</th>
      <td mat-cell *matCellDef="let element"> {{ element.order_no }} </td>
    </ng-container>

    <!-- Payment type Column -->
    <ng-container matColumnDef="Payment type">
      <th mat-header-cell *matHeaderCellDef>Payment type</th>
      <td mat-cell *matCellDef="let element"> {{ element.payment_type }} </td>
    </ng-container>

    <!-- Discounts Column -->
    <ng-container matColumnDef="Discounts">
      <th mat-header-cell *matHeaderCellDef>Discounts</th>
      <td mat-cell *matCellDef="let element">
        <app-pos-price-format [price]="element.discounts"></app-pos-price-format>
      </td>
    </ng-container>

    <!-- Total order Column -->
    <ng-container matColumnDef="Total order">
      <th mat-header-cell *matHeaderCellDef>Total order</th>
      <td mat-cell *matCellDef="let element">
        <app-pos-price-format [price]="element.total"></app-pos-price-format>
      </td>
    </ng-container>

    <!-- Shop Column -->
    <ng-container matColumnDef="Shop">
      <th mat-header-cell *matHeaderCellDef>Shop</th>
      <td mat-cell *matCellDef="let element"> {{ element.shop_name }} </td>
    </ng-container>

    <!-- Cashier Column -->
    <ng-container matColumnDef="Cashier">
      <th mat-header-cell *matHeaderCellDef>Cashier</th>
      <td mat-cell *matCellDef="let element"> {{ element.cashier }} </td>
      <mat-text-column name="score"></mat-text-column>
    </ng-container>

      <!-- Footer column -->
    <ng-container matColumnDef="total-amount">
      <td mat-footer-cell *matFooterCellDef colspan="2"><b>Total amount: <app-pos-price-format [price]="total_amount"></app-pos-price-format></b></td>
    </ng-container>

    <ng-container matColumnDef="count-of-orders">
      <td mat-footer-cell *matFooterCellDef colspan="2"><b>Count of orders: {{ count_orders }}</b></td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row ="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="editContact(row)"></tr>

    <tr mat-footer-row *matFooterRowDef="['count-of-orders']" ></tr>
    <tr mat-footer-row *matFooterRowDef="['total-amount']" ></tr>

  </table>
    <mat-paginator [pageSizeOptions]="[elementsOnPage]" [length]='totalResultsLength' [pageIndex]="0"
      [pageSizeOptions]="[elementsOnPage]" (page)="getServerData($event)" showFirstLastButtons *ngIf="!renderTemplatePDF">
    </mat-paginator>
</div>

<div *ngIf="displayTableExtended && !renderTemplatePDF" class="example-table-container">
  <table mat-table [dataSource]="dataSource2" class="mat-elevation-z8">

    <!-- ID Column -->
    <ng-container matColumnDef="ID">
      <th mat-header-cell *matHeaderCellDef>ID</th>
      <td mat-cell *matCellDef="let element"> {{ element.ID }} </td>
    </ng-container>

    <!-- Product ID Column -->
    <ng-container matColumnDef="Product ID">
      <th mat-header-cell *matHeaderCellDef>Product ID</th>
      <td mat-cell *matCellDef="let element"> {{ element.ProductID }} </td>
    </ng-container>

    <!-- Product Name Column -->
    <ng-container matColumnDef="Product Name">
      <th mat-header-cell *matHeaderCellDef>Product Name</th>
      <td mat-cell *matCellDef="let element"> {{ element.ProductName }} </td>
    </ng-container>

    <!-- Quantity No Column -->
    <ng-container matColumnDef="Quantity">
      <th mat-header-cell *matHeaderCellDef>Quantity</th>
      <td mat-cell *matCellDef="let element"> {{ element.Quantity }} </td>
    </ng-container>

    <!-- Price Column -->
    <ng-container matColumnDef="Price">
      <th mat-header-cell *matHeaderCellDef>Price</th>
      <td mat-cell *matCellDef="let element">
        <app-pos-price-format [price]="element.PriceWithTax"></app-pos-price-format>
      </td>
    </ng-container>

    <!-- Discount Column -->
    <ng-container matColumnDef="Discount">
      <th mat-header-cell *matHeaderCellDef>Discount</th>
      <td mat-cell *matCellDef="let element">
        <app-pos-price-format [price]="element.Discount"></app-pos-price-format>
      </td>
    </ng-container>

    <!-- Total Column -->
    <ng-container matColumnDef="Total">
      <th mat-header-cell *matHeaderCellDef>Total</th>
      <td mat-cell *matCellDef="let element">
        <app-pos-price-format [price]="element.total"></app-pos-price-format>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumnsSecond"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumnsSecond;"></tr>
  </table>
</div>
