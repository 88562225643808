import {AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Shop} from "../../../shop";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";


@Component({
    selector: 'app-shop-dialog',
    templateUrl: './shop-dialog.component.html',
    styleUrls: ['./shop-dialog.component.scss'],
})
export class ShopDialogComponent implements OnInit, AfterViewInit {
    @ViewChild('shopNameField') shopNameField: ElementRef;

    public visibleErrors = {
        emptyName: false,
        emptyLocation: false,

        anyError: function() {
            return this.emptyName
                || this.emptyLocation
        }
    }
    shop: Shop = new Shop();

    constructor(public dialogRef: MatDialogRef<ShopDialogComponent>, @Inject(MAT_DIALOG_DATA) public originalShop: Shop, private http: HttpClient) {
        this.shop.cloneFrom(originalShop);
    }

    ngOnInit(): void {

    }

    public saveShop() {
        this.visibleErrors.emptyName = !this.shop.name;
        this.visibleErrors.emptyLocation = !this.shop.location;
        if(this.visibleErrors.anyError())
            return;

        this.http.post<any>(environment.APP_ENVIRONMENT+environment.SHOPS_ENDPOINT+"/save", this.shop).subscribe(response => {
                if(!response.success)
                    console.dir(response.error);
                else {
                    this.originalShop.cloneFrom(this.shop);
                    this.dialogRef.close();
                }
            },
            (err) => {
                console.dir(err);
            });
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.shopNameField.nativeElement.focus();
        });
    }

}
