<h2>Receipt Printer Details</h2>
<mat-dialog-content class="fullWidthWrapper mb-2">
    <div style="width: 340px">
      <div class="alert alert-danger" *ngIf="visibleErrors.emptyPrinterUrl">Please enter the printer url</div>
      <div class="alert alert-danger" *ngIf="visibleErrors.emptyLogicalName">Please enter the printer logical name</div>
      <div class="alert alert-danger" *ngIf="visibleErrors.emptyShop">Please select a shop</div>
      <div class="alert alert-danger" *ngIf="visibleErrors.emptyPaperSize">Please select a paper size</div>
      <mat-form-field appearance="fill">
        <mat-label>URL</mat-label>
        <input #receiptPrinterUrlField [(ngModel)]="printer.url" matInput type="text">
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Logical Name</mat-label>
        <input [(ngModel)]="printer.logical_name" matInput type="text">
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Paper Size</mat-label>
        <mat-select [(ngModel)]="printer.paper_size">
          <mat-option value="2in">2 inch</mat-option>
          <mat-option value="3in">3 inch</mat-option>
          <mat-option value="4in">4 inch</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Description</mat-label>
        <textarea [(ngModel)]="printer.description" matInput></textarea>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Shop</mat-label>
        <mat-select [(ngModel)]="selectedShop" (selectionChange)="updateShop(selectedShop)" [compareWith]="shopCompareFn">
          <mat-option *ngFor="let shop of shops" [value]="{ id_shop: shop.id_shop, shop_name: shop.name }">
            {{ shop.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
</mat-dialog-content>

<mat-dialog-actions style="margin-bottom: 10px;">
  <button mat-raised-button color="primary" (click)="savePrinter()">Save</button>
  <button mat-raised-button [mat-dialog-close]="true">Cancel</button>
</mat-dialog-actions>
