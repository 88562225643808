import {Component, HostListener, OnDestroy} from '@angular/core';
import {ReceiptService} from "../../services/receipt.service";
import {ReceiptPrinter} from "../../receipt-printer";
import {CartProduct, VatTotal} from "../../cart-product";
import {VoucherDetails} from "../../../voucher-details";
import {ConfigurationService} from "../../services/configuration.service";


@Component({
    selector: 'app-printer-test',
    templateUrl: './printer-test.component.html',
    styleUrls: ['./printer-test.component.scss'],
})
export class PrinterTestComponent implements OnDestroy{

    public printer: ReceiptPrinter = new ReceiptPrinter();

    constructor(private receiptService: ReceiptService, private configurationService: ConfigurationService) {

    }

    printerResponse: string = '';

    @HostListener('window:beforeunload') //<-- Do NOT put a semicolon here
    ngOnDestroy() {
        this.receiptService.printer = this.configurationService.defaultPrinter;
    }

    dummyProducts: CartProduct[] = [
        new CartProduct('', {en: 'Lorem ipsum'}, 'EUR', '', 2, 40.1234, 0),
        new CartProduct('', {en: 'Aliquam elementum nulla vitae risus commodo, in consequat augue sagittis.'}, 'EUR', '', 0, 5, 0),
        new CartProduct('', {en: 'Maecenas consequat massa nulla'}, 'EUR', '', 1, 200, 0),
        new CartProduct('', {en: 'Integer\ntellus enim, placerat quis mi eu, ornare consequat tortor'}, 'EUR', '', 1, 0, 0),
    ];

    dummyVouchers: VoucherDetails[] = [
        new VoucherDetails('voucher1', 0.3, 20),
        new VoucherDetails('really long voucher', 0, 20),
        new VoucherDetails('15percent', 0.15, 0),
        new VoucherDetails('10eur', 0, 10),
    ]

    dummyDiscount: number = 10;

    dummyHeader = "%date% = Current Date\n" +
        "%time% = Current Time\n" +
        "%shop_name% = Shop Name\n" +
        "%agent_name% = Agent Name\n" +
        "%agent_id% = Agent ID\n" +
        "%customer_name% = Customer Name\n" +
        "%customer_email% = Customer Email\n" +
        "%customer_id% = Customer ID\n\nLorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam elementum nulla vitae risus commodo, in consequat augue sagittis. Morbi hendrerit lectus sed leo blandit fermentum";

    dummyFooter = "THIS WAS A TEST RECEIPT";

    runPrint() {
        this.receiptService.printer = this.printer;
        let subtotal = 0;
        for(let product of this.dummyProducts) {
            product.totalprice = product.quantity * product.PriceWithTax;
            subtotal += product.totalprice;
        }
        let voucherTotal = 0;
        for(let voucher of this.dummyVouchers) {
            voucher.updateValue(subtotal);
            voucherTotal += voucher.totalValue;
        }
        let total = subtotal - voucherTotal - this.dummyDiscount;
        console.log("Ready to print test receipt");

        let vatLines: VatTotal[] = [
          {Name: 'VAT 23%', Value:13},
          {Name: 'VAT 9%', Value: 7}
        ]

        this.receiptService.PrintReceipt(this.dummyProducts, subtotal, vatLines, this.dummyVouchers, this.dummyDiscount, total, this.dummyHeader, this.dummyFooter, (result) => { this.printerResponse = result});
    }

}
