import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { MatDialog } from '@angular/material/dialog';
import {environment} from "../../../environments/environment"
import {LaravelPaginationLink} from "../../laravel-pagination-link";
import {Shop} from "../../shop";
import {ShopDialogComponent} from "./shop-dialog/shop-dialog.component";



@Component({
    selector: 'app-shops',
    templateUrl: './shops.component.html',
    styleUrls: ['./shops.component.scss']
})
export class ShopsComponent implements OnInit {

    paginationLinks: LaravelPaginationLink[] = [];
    currentPage;
    totalResults;
    totalPages;
    currentLink = environment.APP_ENVIRONMENT + environment.SHOPS_ENDPOINT;
    rows: Shop[] = [];
    displayedColumns: string[] = ['id', 'name', 'location', 'actions'];

    constructor(
        private authenticationService: AuthenticationService,
        public dialog: MatDialog,
        private http: HttpClient
    ) { }

    ngOnInit(): void {
        this.reloadTable();
    }

    createShopFromJson(json: any): Shop
    {
        let result = new  Shop();
      result.cloneFrom(json);
        return result;
    }

    reloadTable() {
        this.http.get<any>(this.currentLink).subscribe(response => {
                this.rows = response.data.map(this.createShopFromJson);
                this.currentPage = response.current_page;
                this.paginationLinks = response.links;
                this.totalPages = response.last_page;
                this.totalResults = response.total;
            },
            (err) => {
                console.dir(err);
            });
    }

    editShop(shop: Shop, callback: CallableFunction = () => {}) {
        const dialogRef = this.dialog.open(ShopDialogComponent, {
                hasBackdrop: true,
                data: shop
            }
        );
        dialogRef.afterClosed().subscribe(result => {
            callback(result)
        })
    }

    changePage(link) {
        this.currentLink = link;
        this.reloadTable();
    }

    createShop() {
        let shop = new  Shop();
        this.editShop(shop, result => {
            this.reloadTable();
        })
    }

    deleteShop(shop: Shop) {
        this.http.post<any>(environment.APP_ENVIRONMENT+environment.SHOPS_ENDPOINT+"/delete/"+shop.id_shop, {}).subscribe(response => {
                if(!response.success)
                    console.dir(response.error);
                else {
                    this.reloadTable();
                }
            },
            (err) => {
                console.dir(err);
            });
    }
}
