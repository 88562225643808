import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-pos-error-dialog',
  templateUrl: './pos-error-dialog.component.html',
  styleUrls: ['./pos-error-dialog.component.scss'],
})

export class PosErrorDialogComponent implements OnInit {


  customActions: any[] = [];

  constructor(public dialogRef: MatDialogRef<PosErrorDialogComponent>, @Inject(MAT_DIALOG_DATA) public message: string) {
  }

  ngOnInit(): void {
  }

}
