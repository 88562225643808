import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { VoucherDetails } from "../../../../voucher-details";
import { PopupService } from "../../../services/popup.service";
import { VoucherService } from 'src/app/services/voucher.service';
import { CartService } from 'src/app/services/cart.service';

@Component({
    selector: 'app-pos-voucher-dialog',
    templateUrl: './pos-voucher-dialog.component.html',
    styleUrls: ['./pos-voucher-dialog.component.scss'],
})
export class PosVoucherDialogComponent implements OnInit, AfterViewInit {
    @ViewChild('voucherCodeInputField') voucherCodeInputField: ElementRef;
    public code: string;

    public maxValue: number = 0;
    public visibleErrors = {
        alreadyAdded: false,
        exceededMax: false,
        invalidVoucher: false,

        anyError: function () {
            return this.alreadyAdded || this.exceededMax || this.invalidVoucher;
        }
    }

    constructor(
        private voucherService: VoucherService,
        public dialogRef: MatDialogRef<PosVoucherDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public usedVouchers: VoucherDetails[],
        private popupService: PopupService) { }

    ngOnInit(): void {
    }

    public voucherValidationProcess(): void {
        if (!this.validateVoucher()) {
            return;
        }

        this.voucherService.requestVoucher(this.code).subscribe(
            (response) => {
                this.processSuccessHttpResponse(response);
            },
            (error) => {
                this.visibleErrors.invalidVoucher = true;
            }
        );
    }

    public validateVoucher(): boolean {
        this.initVisibleErrorsObject();

        for (const voucher of this.usedVouchers) {
            if (voucher.code === this.code) {
                this.visibleErrors.alreadyAdded = true;
                return false;
            }
        }

        return true;
    }

    public initVisibleErrorsObject() {
        this.visibleErrors.alreadyAdded = false;
        this.visibleErrors.exceededMax = false;
        this.visibleErrors.invalidVoucher = false;
    }

    public processSuccessHttpResponse(response): void {
        if (!this.validateSuccessHttpResponse(response)) {
            return;
        }

        let percentage = parseFloat(response.result.reduction_percent) / 100;
        let fixed = parseFloat(response.result.reduction_amount);
        this.usedVouchers.push(new VoucherDetails(this.code, percentage, fixed));
        this.dialogRef.close();
    }

    public validateSuccessHttpResponse(response): boolean {
        if (!response.success) {
            this.popupService.showError(response.error);
            return false;
        }

        if (parseFloat(response.result.reduction_amount) > this.maxValue) {
            this.visibleErrors.exceededMax = true;
            return false;
        }

        return true;
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.voucherCodeInputField.nativeElement.focus();
        });
    }
}
