<h1 mat-dialog-title>End of day</h1>

<div mat-dialog-content class="mt-3 mb-2">
    <p>Do you want to end the day now?</p>
    <p>The daily report will be generated and you will be logged out.</p>
</div>

<div mat-dialog-actions>
    <button mat-button mat-raised-button color="primary" class="mb-3" cdkFocusInitial (click)="endOfDay()">Confirm</button>
    <button mat-button mat-raised-button color="basic" class="mb-3" cdkFocusInitial (click)="closeDialog()">Cancel</button>
</div>
